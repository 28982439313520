import React from 'react'

interface IProps {
  countryIsoCode: string
  countryNumber: number
  countryName: string
}

const UICountryCode = ({ countryNumber, countryName }: IProps) => {
  return (
    <div title={`+${countryNumber} ${countryName}`}>
      <span>+{countryNumber}</span>
      <span className="country-name"> {countryName}</span>
    </div>
  )
}

export default UICountryCode
