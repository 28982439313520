import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogoHp } from '@veneer/core/dist/esm/scripts/logos/hp'
import { PAGE_SESSION_INFO, IS_PRISTINE, PAGE_USERNAME, PAGE_SIGN_UP, PARAM_HIDE_CREATE } from '../../constants'
import * as S from './styles'
import UILink from '../UILink'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import udlEvents from '../../common/udlEvents'
import { useTheme } from '@veneer/theme'
import tokens from '@veneer/tokens/dist/tokens'
import { State } from '../../common/types'

const { color } = tokens
const { white } = color

interface HeaderProps {
  title?: string
  hasSignInLink?: boolean
  preventLineBreak?: boolean
  hasSignUpLink?: boolean
  isRTL?: boolean
}

function DefaultHeader({ title, hasSignInLink, preventLineBreak, hasSignUpLink, isRTL }: HeaderProps) {
  const [clickCount, setClickCount] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.user) 

  // User action handlers
  const goToUsernamePage = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT39'))
    dispatch.user.update({ [IS_PRISTINE]: false })
    navigate(PAGE_USERNAME)
  }

  const goToSignUpPage = () => {
    dispatch.user.update({ [IS_PRISTINE]: false })
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT67'))
    navigate(PAGE_SIGN_UP)
  }

  useEffect(() => {
    if (clickCount > 6) navigate(PAGE_SESSION_INFO)
  }, [clickCount, navigate])

  const handleClick = () => {
    setClickCount((prevCount) => prevCount + 1)
  }
  const { mode } = useTheme()
  const additionalProps = {
    ...(mode !== 'light' && { color: white }),
    'data-testid': `branding-image-${mode}-theme`
  }
  const displaySignupLink = hasSignUpLink && !hasSignInLink && !user[PARAM_HIDE_CREATE]

  return (
    <S.DefaultHeader>
      <S.Logo>
        <LogoHp {...additionalProps} title="LogoHP" onClick={handleClick} size={36} appearance="singlecolor" />
      </S.Logo>
      <S.HeaderTextDiv isRTL={isRTL} preventLineBreak={preventLineBreak}>
        <S.HeaderTitle preventLineBreak={preventLineBreak}>{title || ''}</S.HeaderTitle>
        {(hasSignInLink && !hasSignUpLink) && (
          <UILink id="sign-in" onClick={goToUsernamePage}>
            {t('label.sign_in')}
          </UILink>
        )}
        {(displaySignupLink) && (
          <UILink id="sign-up" onClick={goToSignUpPage}>
            {t('label.create_account')}
          </UILink>
        )}
      </S.HeaderTextDiv>
    </S.DefaultHeader>
  )
}

export default DefaultHeader
