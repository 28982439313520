import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import udlEvents from '../../common/udlEvents'
import { DefaultHeader, MainLayout, UILink, ChangePasswordForm, AccountRecoveryMessage } from '../../component'
import { PAGE_DESCRIPTIONS, PAGE_LEARN_MORE } from '../../constants'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const LeakedCredsChangePassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRTL } = useDirection()

  return (
    <MainLayout>
      <DefaultHeader preventLineBreak={isRTL} isRTL={isRTL} title={t('label.change_password')} />
      <S.PageWrapper>
        <S.TextWrapper
          id={PAGE_DESCRIPTIONS.LEAKED_CREDENTIALS_CHANGE_PASSWORD.LEARN_MORE_DESC}
          aria-label={PAGE_DESCRIPTIONS.LEAKED_CREDENTIALS_CHANGE_PASSWORD.LEARN_MORE_DESC}
        >
          <Trans i18nKey="label.pass_change_learn_more">
            For security reasons you must change your password.
            <UILink
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT182'))
                navigate(PAGE_LEARN_MORE)
              }}
              ariaLabel="learn-more-link"
              id="learn-more-link"
              error
            >
              Learn more
            </UILink>
          </Trans>
        </S.TextWrapper>
        <S.TextWrapper
          id={PAGE_DESCRIPTIONS.LEAKED_CREDENTIALS_CHANGE_PASSWORD.CHECK_EMAIL_PHONE_DESC}
          aria-label={PAGE_DESCRIPTIONS.LEAKED_CREDENTIALS_CHANGE_PASSWORD.CHECK_EMAIL_PHONE_DESC}
        >
          <AccountRecoveryMessage />
        </S.TextWrapper>
        <ChangePasswordForm />
      </S.PageWrapper>
    </MainLayout>
  )
}

export default LeakedCredsChangePassword
