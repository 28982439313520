import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import config from '../../config'
import { isPathnameOutsideSession } from '../../util'
import { State } from '../../common/types'
import { PARAM_OTP } from './../../constants'
import udlEvents from '../../common/udlEvents'

const PARAM_APP_INSTANCE_ID_LEGACY = 'appInstanceId'
const PARAM_APP_INSTANCE_ID = 'appInstanceID'

const UDL = ({ children }) => {
  const { search, pathname } = useLocation()
  const dispatch = useDispatch()
  const { clientID } = useSelector((state: State) => state.udl)

  useEffect(() => {
    TagManager.initialize({ gtmId: config.gtmId })
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const appInstanceID =
      (query.has(PARAM_APP_INSTANCE_ID) && query.get(PARAM_APP_INSTANCE_ID)) ||
      (query.has(PARAM_APP_INSTANCE_ID_LEGACY) && query.get(PARAM_APP_INSTANCE_ID_LEGACY))
    if (appInstanceID) dispatch.udl.update({ appInstanceID })
  }, [search, dispatch.udl])

  useEffect(() => {
    // Tracks page view when not in session or session is loaded
    if (isPathnameOutsideSession(pathname) || clientID) {
      dispatch.udl.trackPageView({ pathname, clientID })
    }
  }, [pathname, clientID, dispatch.udl])

  useEffect(() => {
    // Tracks the OTP query parameter after UDL is loaded
    const query = new URLSearchParams(search)
    if (query.has(PARAM_OTP)) dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT141'))
  }, [dispatch.udl, search])

  return children
}

export default UDL
