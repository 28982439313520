import UILink from '../../../UILink'
import { TncLink } from '../../../../common/types'

const TnCFieldLink = ({ id, onClick, onKeyDown, linkText, leftText, rightText }: TncLink) => {
  return (
    <>
      {leftText}
      <UILink id={id} onClick={onClick as any} onKeyDown={onKeyDown as any}>
        {linkText}
      </UILink>
      {rightText}
    </>
  )
}


export default TnCFieldLink
