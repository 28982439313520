import styled from '@emotion/styled'

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`

export const TextWrapper = styled.p`
  text-align: center;
`

export const SkipWrapper = styled.div`
  margin-top: 20px;
  padding-bottom: 14px;
  padding-top: 14px;
  text-align: center;
`

export const ProgressWrapper = styled.div`
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
`
