import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@veneer/core/dist/esm/scripts/button'
import { PAGE_REDIRECT } from '../../constants'
import { DefaultHeader, MainLayout } from '../../component'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import * as S from './styles'

const VerificationSuccess = () => {
  const location = useLocation() as any
  const navigate = useNavigate() as any
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleRedirect = () => {
      if (location.state) {
        if (!loading) dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT37'))
        navigate(PAGE_REDIRECT, { state: { nextUrl: location.state.redirectURL } })
      }
    }
    const timer = window.setTimeout(() => handleRedirect(), 2000)
    return () => {
      timer && clearTimeout(timer)
    }
  }, [dispatch.udl, loading, location.state, navigate])

  const handleClick = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT38'))
    setLoading(true)
  }

  return (
    <MainLayout>
      <DefaultHeader />
      <S.Wrapper>
        <p>{t('label.email_confirm_success')}</p>
        <Button loading={loading} onClick={handleClick} expanded appearance="ghost">
          {t('link.select_here')}
        </Button>
      </S.Wrapper>
    </MainLayout>
  )
}

export default VerificationSuccess
