import { css } from '@emotion/react'
import styled from '@emotion/styled'

import tokens from '@veneer/tokens/dist/tokens'

const { color } = tokens
const { gray7 } = color

export const ClipboardCardWrapper = styled.div`
  margin-top: 16px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding: 14px;
  ${(props) =>
    props.theme !== 'contrast' &&
    css`
      color: ${gray7};
    `}
`

export const ClipboardLink = styled.div`
  margin: 14px 0 10px 14px;
`
