import styled from '@emotion/styled'

export const Description = styled.p`
  margin-bottom: 20px;
`
export const InputWrapper = styled.div`
  margin-bottom: 40px;
`
export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`