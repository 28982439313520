import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DefaultHeader, MainLayout } from '../../../component'
import Button from '@veneer/core/dist/esm/scripts/button'
import { useDispatch } from 'react-redux'
import udlEvents from '../../../common/udlEvents'
import * as S from './styles'

const Unverified = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <MainLayout>
      <DefaultHeader title={t('label.recover_account')} />
      <S.TextLabel>{t('form.err_not_verified_mobile_email')}</S.TextLabel>
      <S.TextLabel>{t('form.err_contact_support')}</S.TextLabel>
      <S.ButtonWrapper>
        <Button
          tabIndex={0}
          id="back-link"
          appearance="ghost"
          onClick={() => {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT166'))
            navigate(-1)
          }}
          expanded
        >
          {t('link.back_link')}
        </Button>
      </S.ButtonWrapper>
    </MainLayout>
  )
}

export default Unverified
