import Checkbox from '@veneer/core/dist/esm/scripts/checkbox'
import { Control, Controller } from 'react-hook-form'

interface UICheckBoxProps {
  id: string
  control: Control
  rules: Object
  disableCheckBox?: boolean
}

const UICheckbox = (props: UICheckBoxProps) => {
  const disableCheckBox = (cProps) => {
    return props.disableCheckBox ? false : !!cProps.field.value
  }
  const { id, control, rules } = props
  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={(cProps) => (
        <Checkbox
          {...cProps}
          {...props}
          onChange={(e) => cProps.field.onChange(e.target.checked)}
          checked={disableCheckBox(cProps)}
          disabled={props.disableCheckBox}
        />
      )}
    />
  )
}

export default UICheckbox
