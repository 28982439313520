import styled from '@emotion/styled'

export const otpButtonsContainer = styled.div`
  margin-top: 20px;
`

export const PasscodeResentContainer = styled.div`
  margin-top: 20px;
`

export const SeparatorOrString = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: #A3A3A3;
`