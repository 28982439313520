import { history } from '../../../store'
import {
  PARAM_TARGET_CREATE,
  PARAM_TARGET_PASSWORD,
  PARAM_TARGET_FORGET_PWD,
  PARAM_LOGIN_HINT,
  PARAM_TARGET,
  PAGE_SIGN_UP,
  PAGE_EMAIL_OR_PHONE_ASSOCIATED,
  BFF_STATUS,
  PARAM_FEDERATION,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME
} from '../../../constants'
import udlEvents from '../../../common/udlEvents'

const evalTarget = async ({ data, query, dispatch }) => {
  let hasNativeLogin
  if (data && data.client && data.client.supportHPIDNativeLogin) {
    hasNativeLogin = data.client.supportHPIDNativeLogin
  }
  const target = query.get(PARAM_TARGET)
  switch (target) {
    case PARAM_TARGET_PASSWORD:
      if (query.has(PARAM_LOGIN_HINT)) {
        const username = query.get(PARAM_LOGIN_HINT)
        const givenName = query.get(PARAM_FIRSTNAME)
        const familyName = query.get(PARAM_LASTNAME)
        await dispatch.user.loginWithHint({ options: { username, givenName, familyName } })
      }
      break
    case PARAM_TARGET_CREATE:
      hasNativeLogin && history.replace(PAGE_SIGN_UP)
      break
    case PARAM_TARGET_FORGET_PWD:
      hasNativeLogin && history.push(PAGE_EMAIL_OR_PHONE_ASSOCIATED)
      break
    case PARAM_FEDERATION:
      !hasNativeLogin && (await handleAutoRedirectSocialLogin({ data, query, dispatch }))
      break
    default:
  }
}
const handleAutoRedirectSocialLogin = async ({ data, query, dispatch }) => {
  const { idp } = data
  if (idp.length === 1 && query.get(PARAM_TARGET) === PARAM_FEDERATION) {
    try {
      dispatch.udl.trackEvent(
        udlEvents.getFederatedEvent('EVENT61', 'federated-sign-in', `continue-with-${idp[0].name}-button-click`)
      )
      await dispatch.user.initFederated(idp[0].name)
    } catch (error) {
      console.error(error)
    }
  }
}

export const handleTarget = async ({ data, query, dispatch }) => {
  const validStatus = [
    BFF_STATUS.EMAIL_VERIFICATION,
    BFF_STATUS.PHONE_VERIFICATION,
    BFF_STATUS.EMAIL_OR_PHONE_VERIFICATION,
    BFF_STATUS.TNC_VALIDATION_REQUIRED,
    BFF_STATUS.MFA_REQUIRED,
    BFF_STATUS.CONSENT_MANAGEMENT
  ]

  if (!validStatus.includes(data?.status)) await evalTarget({ data, query, dispatch })
}
