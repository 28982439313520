import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import {
  ARKOSE_SELECTORS,
  LINK_SELECTORS,
  PAGE_EDIT_EMAIL,
  BFF_IDENTITY_PROVIDER,
  IDENTITY_PROVIDER
} from '../../constants'
import { DefaultHeader, MainLayout } from '../../component'
import { State } from '../../common/types'
import { editEmailForWechat } from '../../features'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'
import { Trans } from 'react-i18next'

const ID = ARKOSE_SELECTORS.SEND_CODE.SEND_EMAIL

const SendVerificationCode = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, codeVerification, session, loading } = useSelector((state: State) => state)
  const APPNAME = session.client && session.client.displayName
  const editEmailSelector = LINK_SELECTORS.EDIT_EMAIL
  const retryDelay = session.retryDelay

  const { isLoadingSendEmail } = codeVerification

  const handleClick = async () => {
    const data = {
      submitSelector: ID,
      type: 'email'
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT35'))
    await dispatch.codeVerification.sendCode(data)
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.verify_email_address')} />

      <S.TextWrapper>
        <p>{t('label.requires_email', { APPNAME: APPNAME })}</p>
        <p>
          <Trans i18nKey="label.email_send_verif_desc" components={{bold: <strong />}} values={{EMAIL: `<bold>${user.email}</bold>`}} />
        </p>
      </S.TextWrapper>
      <div>
        <Button
          id={ID}
          name={ID}
          onClick={handleClick}
          loading={isLoadingSendEmail || loading.effects.codeVerification.sendCodeCallback}
          disabled={retryDelay}
          expanded
        >
          {t('button_capitalize.send_email')}
        </Button>
      </div>
      {(!user.identityProvider ||
        user.identityProvider === BFF_IDENTITY_PROVIDER.HPID ||
        (user.identityProvider &&
          user.identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT &&
          editEmailForWechat)) && (
        <S.ButtonWrapper>
          <Button
            appearance="ghost"
            aria-label={editEmailSelector}
            id={editEmailSelector}
            name={editEmailSelector}
            onClick={() => {
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT36'))
              navigate(PAGE_EDIT_EMAIL)
            }}
            disabled={retryDelay}
            expanded
          >
            {t('link.edit_email')}
          </Button>
        </S.ButtonWrapper>
      )}
      {codeVerification.error && (
        <InlineNotification closeButton={false} title={t(codeVerification.error)} type="negative" />
      )}
      {retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.code_in_moment_email')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default SendVerificationCode
