import {
  checkLocalStorage,
  getRememberedUsername,
  setCSRFToken,
  shouldBackToApp,
  isValidEmail,
  isValidMobileNumber,
  parseLocaleString,
  setRememberedUsername,
  removeRememberedUsername,
  setAutoRedirectToken,
  getAutoRedirectToken,
  isPhoneString,
  isHpEmail,
  getUsernameType,
  setUsernameType,
  getFailedPasswordRequirements,
  checkValidCountry
} from '../util'
import {
  PARAM_LOCALE,
  PARAM_CSRF_TOKEN,
  PARAM_LOGIN_HINT,
  PARAM_REMEMBER_ME,
  PARAM_EMAIL,
  PAGE_SOCIAL_LOGIN,
  PAGE_CHECK_USERNAME,
  PAGE_REDIRECT_TO,
  PAGE_PASSWORD,
  PAGE_CONSENT_MANAGEMENT,
  PAGE_CODE_SEND,
  PAGE_USERNAME_RECOVERY_SUCCESS,
  PAGE_AUTH_RECOVERY_VERIFICATION,
  PAGE_SIGN_UP,
  PAGE_REDIRECT,
  PAGE_CHANGE_PASSWORD,
  ARKOSE_KEYS,
  IS_PRISTINE,
  ROUTE_ACTION_REPLACE,
  ROUTE_ACTION_PUSH,
  BFF_STATUS,
  PHONE_VERIFICATION,
  SESSION_DATA,
  PAGE_AUTH,
  PAGE_VERIFICATION_CONFIRMED,
  PAGE_VERIFICATION_ERROR,
  USERNAME_TYPES,
  PARAM_USERNAME_TYPE,
  PAGE_ADD_EMAIL,
  PAGE_PHONE_VERIFICATION,
  PARAM_AUTOREDIRECT,
  PAGE_VERIFICATION_SUCCESS,
  PAGE_EMAIL_VERIFICATION,
  PARAM_COUNTRY,
  PARAM_USERNAME,
  PARAM_PHONE_NUMBER_CONFLICT,
  PAGE_PASSWORD_RESET,
  IDENTITY_PROVIDER,
  PAGE_MOBILE_CODE_SEND,
  PAGE_COMPLETE_ACCOUNT,
  VALIDATION_TYPES,
  USERNAME_TYPES_ALIAS,
  PAGE_PASSWORD_RESET_SUCCESS,
  PASSWORD_REQUIREMENT_TYPE,
  PAGE_OOBE_SIGN_UP,
  PAGE_TNC_MANAGEMENT,
  PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD,
  BFF_ENDPOINTS,
  PAGE_OTP_SIGN_IN,
  OTP_BUTTON_LABEL
} from '../constants'
import { apiExternalUrl } from '../services/axiosConfig'
import config from '../config'
import i18n from '../i18n'
import store, { history } from '../store'
import bff from '../services'
import udlEvents from '../common/udlEvents'
import {
  blockSignInNativeHp,
  simplifiedAccount,
  displayLearnMorePage,
  enablePostFederatedSignUpErrorHandling
} from '../features'

const user = {
  state: {
    /**
     * The order of username:
     * 1. get from what user types
     * 2. get from remember_me
     * 3. get from login_hint
     */
    username: getRememberedUsername(),
    usernameType: getUsernameType(),
    defaultUsernameType: getUsernameType(),
    availableMfaMethods: [],
    expiredInteraction: true,
    loginCaptchaCredit: false,
    loginHint: '',
    password: '',
    locale: '',
    country: 'US',
    queryLocaleParam: 'none',
    queryCountryParam: 'none',
    identityProvider: '',
    market: null,
    identities: [],
    passwordRequirements: [],
    discoveryIDP: '',
    rememberMe: getRememberedUsername() !== '',
    showRememberMe: true,
    phoneNumber: '',
    email: '',
    [IS_PRISTINE]: true,
    autoredirect: getAutoRedirectToken(),
    isExternalIdentity: false,
    lastOTPStatus: ''
  },
  reducers: {
    update: (state, payload) => {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    updateParams(params = {}, { user }) {
      if (Object.keys(params).length) {
        if (params[PARAM_CSRF_TOKEN] !== undefined) {
          setCSRFToken(params[PARAM_CSRF_TOKEN])
        }

        if (params[PARAM_AUTOREDIRECT] !== undefined) {
          setAutoRedirectToken(params[PARAM_AUTOREDIRECT])
        } else {
          setAutoRedirectToken(true)
        }

        if (params[PARAM_LOGIN_HINT] !== undefined) {
          if (!user.username) {
            params.username = params[PARAM_LOGIN_HINT]
          }
          params.loginHint = params[PARAM_LOGIN_HINT]
          delete params[PARAM_LOGIN_HINT]
        }

        if (params[PARAM_REMEMBER_ME] !== undefined) {
          params.showRememberMe = params[PARAM_REMEMBER_ME]
          if (params[PARAM_REMEMBER_ME] === false && params.loginHint === undefined) {
            params.username = ''
          }
          delete params[PARAM_REMEMBER_ME]
        }

        if (params[PARAM_USERNAME_TYPE] === 'mobile') {
          setUsernameType(USERNAME_TYPES.USERNAME_MOBILE)
          params.usernameType = USERNAME_TYPES.USERNAME_MOBILE
          params.defaultUsernameType = USERNAME_TYPES.USERNAME_MOBILE
        } else {
          setUsernameType(USERNAME_TYPES.USERNAME_EMAIL)
          params.usernameType = USERNAME_TYPES.USERNAME_EMAIL
          params.defaultUsernameType = USERNAME_TYPES.USERNAME_EMAIL
        }

        if (params[PARAM_LOCALE] !== undefined && /[A-Z]{2}/.test(params[PARAM_LOCALE])) {
          params.queryLocaleParam = params[PARAM_LOCALE]
        }

        if (params[PARAM_COUNTRY] !== undefined && checkValidCountry(params[PARAM_COUNTRY])) {
          checkLocalStorage() && window.localStorage.setItem(PARAM_COUNTRY, params[PARAM_COUNTRY])
          params.queryCountryParam = params[PARAM_COUNTRY]
        }
        this.update && this.update(params)
      }
    },

    async changeLanguage() {
      const languageCode = await dispatch.user._getLocale()
      i18n.changeLanguage(languageCode)

      dispatch.user.update({ locale: languageCode })
    },

    async mfaVerifyCode(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, code } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = { endpoint: BFF_ENDPOINTS.SESSION_MFA_VERIFY_CODE }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { captcha, code }
      try {
        const { data } = await bff.postVerifyCodeMfa(body)
        const redirectUser = () => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT03'))
          history.push(PAGE_REDIRECT, { nextUrl: data.nextUrl })
        }
        data.nextUrl ? redirectUser() : await dispatch.user.userCheckFlowURI(data)
        setLoading(false)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT171'))
            setError('code', { type: errorData.error, message: 'form.err_verification_code' })
            setLoading(false)
        }
      }
    },

    async verifyEmailLink(data) {
      const { options, token } = data
      const { otp, arkoseParams } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = { endpoint: BFF_ENDPOINTS.EMAIL_VERIFY_LINK }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        await bff.postVerifyEmailLink({ otp, captcha })
        history.push(PAGE_VERIFICATION_CONFIRMED)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.INVALID_CODE:
            history.push(PAGE_VERIFICATION_ERROR, { context: BFF_STATUS.INVALID_CODE })
            break
          case BFF_STATUS.NO_TARGET:
            history.push(PAGE_VERIFICATION_ERROR)
            break
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            dispatch.error.goError(errorData)
            break
        }
      }
    },

    async _getLocale() {
      const query = new URLSearchParams(window.location.search)
      let locale =
        query?.get(PARAM_LOCALE) ||
        store?.getState().user.locale ||
        navigator.language ||
        navigator.browserLanguage ||
        navigator.userLanguage

      if (!locale) {
        return config.defaultLocale
      }

      locale = parseLocaleString(locale)

      if (!config.supportedLocales.includes(locale.toLowerCase())) {
        const [lang] = locale.split('_')
        locale = config.defaultLocale
        if (lang !== 'en') {
          const localesWithLang = config.supportedLocales.filter((lng) => lng.includes(lang))

          if (localesWithLang.length > 0) {
            locale = parseLocaleString(localesWithLang[0])
          }
        }
      }
      return locale
    },

    processIdps(res, rootState) {
      const { supportedIDP } = rootState.session
      const { identities = [], discoveryIDP } = res.data
      const discoveryIdentity = supportedIDP.find((idp) => idp.identityProvider.toLowerCase() === discoveryIDP)

      const filteredIdentities = identities
        .map((identity) => {
          const idpProvider = identity.idpProvider.toLowerCase()
          if (idpProvider === config.hpidProvider.identityProvider.toLowerCase()) {
            return config.hpidProvider
          }
          return supportedIDP.find((idp) => idpProvider.toLowerCase() === idp.identityProvider.toLowerCase())
        })
        .filter((id) => id !== undefined)

      if (
        discoveryIdentity &&
        !filteredIdentities.find((id) => id.identityProvider.toLowerCase() === discoveryIDP.toLowerCase())
      ) {
        filteredIdentities.push(discoveryIdentity)
      }

      const sortedIdentities = filteredIdentities.sort((a, b) => {
        return (
          config.providerSortOrder.indexOf(a.identityProvider) -
          config.providerSortOrder.indexOf(b.identityProvider)
        )
      })

      const idps = { identities: sortedIdentities, discoveryIDP }
      dispatch.user.update(idps)
      return idps
    },

    async checkUsername(body) {
      dispatch.user.update({ username: body.username })
      if (isValidEmail(body.username)) {
        dispatch.user.update({ email: body.username })
      }
      if (isValidMobileNumber(body.username)) {
        dispatch.user.update({ email: '', phoneNumber: body.username })
      }
      const res = await bff.postCheckUsername(body)
        if (res?.data?.loginCaptchaCredit) {
        dispatch.user.update({ loginCaptchaCredit: res.data.loginCaptchaCredit })
      }
      return dispatch.user.processIdps(res)
    },

    async splitSignUpFormSubmit({ options, token }, { session }) {
      const {
        arkoseParams,
        data,
        isExternalIdentity,
        provider,
        setConflictError,
        setWeakPasswordError,
        setError,
        setLoading,
        defaultValues,
        isOobeSignUp
      } = options
      const handleStatus = async (res) => {
        switch (res.status) {
          case BFF_STATUS.COMPLETION_REQUIRED:
            history.push(PAGE_COMPLETE_ACCOUNT)
            break
          case BFF_STATUS.EMAIL_VERIFICATION:
            dispatch.user.update({ email: res.email })
            dispatch.session.update({ retryDelay: true })
            history.push(PAGE_EMAIL_VERIFICATION)
            break
          case BFF_STATUS.PHONE_VERIFICATION:
            dispatch.user.update({ phoneNumber: res.phoneNumber })
            dispatch.session.update({ retryDelay: true, phoneVerificationSendCode: res.sendCode })
            history.push(PAGE_PHONE_VERIFICATION)
            break
          // Added to cover scenario when a federated user inform a phoneNumber during the sign-up
          case BFF_STATUS.EMAIL_OR_PHONE_VERIFICATION:
            dispatch.user.update({ phoneNumber: res.phoneNumber, email: res.email })
            dispatch.session.update({ retryDelay: true })
            history.push(PAGE_PHONE_VERIFICATION)
            break
          case BFF_STATUS.SUCCESS:
            history.push(PAGE_REDIRECT, { nextUrl: res.nextUrl })
            break
          case BFF_STATUS.CONSENT_MANAGEMENT:
            dispatch.session.updateUserSession(res.sessionIdentityResource)
            history.push(PAGE_CONSENT_MANAGEMENT)
            break
          default:
            break
        }
      }

      const trackNameChangeUDLEvents = () => {
        if (defaultValues.firstName !== data.firstName && defaultValues.lastName !== data.lastName) {
          dispatch.udl.trackEvent(
            udlEvents.getEventWithObject('EVENT175', {
              formName: `user-${provider}-changed-first-last-name`.toLowerCase()
            })
          )
        } else {
          if (defaultValues.firstName !== data.firstName) {
            dispatch.udl.trackEvent(
              udlEvents.getEventWithObject('EVENT175', {
                formName: `user-${provider}-changed-first-name`.toLowerCase()
              })
            )
          }
          if (defaultValues.lastName !== data.lastName) {
            dispatch.udl.trackEvent(
              udlEvents.getEventWithObject('EVENT175', {
                formName: `user-${provider}-changed-last-name`.toLowerCase()
              })
            )
          }
        }
      }

      const captcha = token && { data: token, type: ARKOSE_KEYS.SIGN_UP }
      const endpoint = isExternalIdentity ? BFF_ENDPOINTS.SESSION_FEDERATED_SIGN_UP : BFF_ENDPOINTS.SESSION_SIGN_UP

      // This is required to avoid 'prepare-arkose' call issues.
      // Username is not sent to the backend during federated account creation, except for WeChat.
      const isUsernameRequired = !(isExternalIdentity && provider.toLowerCase() !== IDENTITY_PROVIDER.WE_CHAT)
      // SendCode param as true should only be sent if the client requires some validation
      const isSendCodeRequired =
        session.client.emailValidation !== VALIDATION_TYPES.NOT_REQUIRED ||
        session.client.phoneValidation !== VALIDATION_TYPES.NOT_REQUIRED

      const prepareArkoseBody = {
        endpoint,
        phoneNumber: data.phoneNumber,
        ...(isSendCodeRequired && { sendCode: true }),
        ...(isUsernameRequired && { username: data.email })
      }

      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        let res
        const body = { captcha, isExternalIdentity, provider, ...data }
        // Updating the store with the username state according to the backend response
        if (data.email) {
          dispatch.user.update({ username: data.email, usernameType: USERNAME_TYPES.USERNAME_EMAIL })
        }
        if (data.phoneNumber) {
          dispatch.user.update({ username: data.phoneNumber, usernameType: USERNAME_TYPES.USERNAME_MOBILE })
        }
        if (isExternalIdentity) {
          trackNameChangeUDLEvents()
          res = await dispatch.user.submitSplitSignUp(body)
        } else {
          // This is required to avoid issues with a user switching sign-up methods
          dispatch.user.update({ isExternalIdentity: false })
          res = await dispatch.user.submitSplitSignUp(body)
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT04'))
        }
        if (res && res[SESSION_DATA]) {
          dispatch.session.updateUserSession(res[SESSION_DATA])
        }
        await handleStatus(res)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.CONFLICTING_RESOURCE:
            const isWeChat = isExternalIdentity && provider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT
            const isFacebook = isExternalIdentity && provider.toLowerCase() === IDENTITY_PROVIDER.FACEBOOK
            if (errorData.conflicts.some((e) => e === PARAM_USERNAME)) {
              // Workaround for weChat and Facebook
              if ((isWeChat || isFacebook) && data.phoneNumber) {
                dispatch.user.update({ username: data.phoneNumber })
                setError('phoneNumber', { type: 'manual', message: setConflictError('mobile') })
              } else {
                dispatch.user.update({ username: data.email })
                setError('email', { type: 'manual', message: setConflictError('username') })
                if (isOobeSignUp) {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT145'))
                } else {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT80'))
                }
              }
            }
            if (errorData.conflicts.some((e) => e === PARAM_PHONE_NUMBER_CONFLICT)) {
              dispatch.user.update({ username: data.phoneNumber })
              setError('phoneNumber', { type: 'manual', message: setConflictError('mobile') })
            }
            setLoading(false)
            break
          case BFF_STATUS.INVALID_PASSWORD:
            setError('password', { type: 'invalidPassword', message: 'form.password_check_requirements' })
            setLoading(false)
            break
          case BFF_STATUS.WEAK_PASSWORD:
            displayLearnMorePage
              ? setError('password', { type: 'weakPassword', message: setWeakPasswordError() })
              : setError('password', { type: 'weakPassword', message: 'label.password_leak' })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT128'))
            setLoading(false)
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async submitSplitSignUp(data, { user, session }) {
      const { firstName, lastName, email, password, phoneNumber, market, captcha, isExternalIdentity, provider } =
        data
      const { showPhoneField } = session
      const payload = {
        givenName: firstName,
        familyName: lastName,
        captcha: captcha,
        countryResidence: user.country,
        locale: user.locale,
        termsAndConditions: 'accepted'
      }

      if (typeof market === 'boolean') {
        if (market === true) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT125'))
          payload.marketingConsents = 'accepted'
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT126'))
        }
      }

      // Check if client requires any validation
      if (
        session.client.emailValidation !== VALIDATION_TYPES.NOT_REQUIRED ||
        session.client.phoneValidation !== VALIDATION_TYPES.NOT_REQUIRED
      )
        payload.sendCode = true

      if (!showPhoneField) {
        // adds username or phoneNumber in payload
        email ? (payload.username = email) : (payload.phoneNumber = phoneNumber)
      } else {
        payload.username = email
        payload.phoneNumber = phoneNumber
      }

      let res
      if (isExternalIdentity) {
        // Check if email exists then update the store and delete from payload
        if (email) {
          if (provider.toLowerCase() !== IDENTITY_PROVIDER.WE_CHAT) delete payload.username
          dispatch.user.update({ username: email })
        }

        if (enablePostFederatedSignUpErrorHandling) {
          try {
            res = await bff.postFederatedSignup(payload)
            dispatch.udl.trackEvent(
              udlEvents.getEventWithObject('EVENT175', { formName: `sign-up-success-${provider}`.toLowerCase() })
            )
          } catch (errorData) {
            dispatch.error.goError(errorData)
          }
        } else {
          res = await bff.postFederatedSignup(payload)
        }
      } else {
        // Adds password and update the store
        payload.password = password
        dispatch.user.update({ isExternalIdentity: false })
        res = await bff.postSessionSignUp(payload)
      }
      return res.data
    },

    async completeAccountFinalize(_, rootState) {
      const { data } = rootState.user.isExternalIdentity
        ? await bff.postFederatedFinalize()
        : await bff.postFinalize()
      history.push(PAGE_REDIRECT, { nextUrl: data.nextUrl })
    },

    async submitCompleteAccount(data, rootState) {
      const { email, phoneNumber, countryResidence, market } = data
      const payload = {}

      if (email && email.trim().length !== 0) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT48'))
        payload.email = email
      }
      if (phoneNumber) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT49'))
        payload.phoneNumber = phoneNumber
      }
      if (countryResidence) {
        payload.countryResidence = countryResidence
      }
      if (typeof market === 'boolean') {
        if (market === true) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT50'))
          payload.marketingConsents = 'accepted'
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT51'))
        }
      }
      const res = rootState.user.isExternalIdentity
        ? await bff.postFederatedAddInfo(payload)
        : await bff.postAddInfo(payload)

      return res.data
    },

    async completeAccount({ options }) {
      const { setError, data, isDirty, setLoading } = options
      try {
        if (!isDirty) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT47'))
          return dispatch.user.completeAccountFinalize()
        }
        const res = await dispatch.user.submitCompleteAccount(data)
        if (res && res[SESSION_DATA]) {
          dispatch.session.updateUserSession(res[SESSION_DATA])
        }
        return dispatch.user.completeAccountFinalize()
      } catch (errorData) {
        setLoading(false)
        switch (errorData.error) {
          case BFF_STATUS.EDIT_UNIQUENESS:
            if (data.phoneNumber) {
              setError('phoneNumber', { type: 'manual', message: 'form.err_phone_in_use' })
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT88'))
            } else {
              setError('email', { type: 'manual', message: 'form.err_unique_username' })
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT87'))
            }
            break
          case BFF_STATUS.INVALID_PHONE_NUMBER: {
            setError('invalidPhoneNumber', {
              type: BFF_STATUS.INVALID_PHONE_NUMBER,
              message: 'form.err_invalid_phone_number'
            })
            break
          }
          case BFF_STATUS.UNSUPPORTED_COUNTRY: {
            setError('unsupportedCountry', {
              type: BFF_STATUS.UNSUPPORTED_COUNTRY,
              message: 'form.err_unsupported_country'
            })
            break
          }
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async emailVerificationFormVerifyEmail(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, code } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = {
        endpoint: BFF_ENDPOINTS.SESSION_EMAIL_VERIFY_CODE
      }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { captcha, code }
      try {
        const result = await dispatch.user.verifyCode(body)
        if (result.nextUrl) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT15'))
          history.push(PAGE_VERIFICATION_SUCCESS, { redirectURL: result.nextUrl })
        } else if (result.status === BFF_STATUS.COMPLETION_REQUIRED) {
          history.push(PAGE_COMPLETE_ACCOUNT)
        } else {
          await dispatch.user.userCheckFlowURI(result)
        }
        setLoading(false)
      } catch (errorData) {
        setLoading(false)

        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            setLoading(true)
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.INVALID_CODE:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT18'))
            setError('code', { type: 'invalidCode', message: 'form.err_invalid_email_code' })
            break
          case BFF_STATUS.EXPIRED_CODE:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT18'))
            setError('code', { type: 'expiredCode ', message: 'form.err_invalid_email_code' })
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async phoneVerificationFormVerifyEmail(data) {
      const { options, token } = data
      const { setLoadingEmail, arkoseParams } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const updateArkoseParams = {
        ...arkoseParams,
        prepareArkoseBody: { endpoint: BFF_ENDPOINTS.SESSION_PHONE_SEND_CODE }
      }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        await dispatch.user.requestCode(captcha)
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT11'))
        dispatch.session.update({ retryDelay: true })
        history.push(PAGE_EMAIL_VERIFICATION)
      } catch (errorData) {
        setLoadingEmail(false)
        const dataError = errorData.response?.data?.error
        switch (dataError) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            setLoadingEmail(true)
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.TOO_MANY_REQUESTS:
            dispatch.session.update({ retryDelay: true })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT157'))
            dispatch.error.goError(errorData)
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async phoneVerificationFormVerifySMSCode(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, code } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const updateArkoseParams = {
        ...arkoseParams,
        prepareArkoseBody: { endpoint: BFF_ENDPOINTS.SESSION_PHONE_VERIFY_CODE }
      }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { captcha, code }
      try {
        const result = await dispatch.user.verifySMSCode(body)
        setLoading(false)
        if (result.nextUrl) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT17'))
          history.push(PAGE_VERIFICATION_SUCCESS, { redirectURL: result.nextUrl, context: PHONE_VERIFICATION })
        } else if (result.status === BFF_STATUS.COMPLETION_REQUIRED) {
          history.push(PAGE_COMPLETE_ACCOUNT)
        } else {
          await dispatch.user.userCheckFlowURI(result)
        }
      } catch (errorData) {
        setLoading(false)
        switch (errorData.error) {
          case BFF_STATUS.INVALID_CODE:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT19'))
            setError('code', { type: 'invalidCode', message: 'form.err_invalid_email_code' })
            break
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async usernameFormCheckUsername({ options, token }, { session }) {
      const { setLoading, setError, arkoseParams, username } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const body = { captcha, username }
      const prepareArkoseBody = {
        endpoint: BFF_ENDPOINTS.SESSION_CHECK_USERNAME,
        username
      }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (blockSignInNativeHp) {
        // check if the username is an email hp and OneHP exists in the client
        const oneHpIdp =
          isHpEmail(username) &&
          session.supportedIDP.find((idp) => idp.name.toLowerCase() === IDENTITY_PROVIDER.ONE_HP)
        if (oneHpIdp) {
          // leads to OneHP federated sign in
          return dispatch.user.initFederated(oneHpIdp.name)
        }
      }

      if (!isValidEmail(username) && !isValidMobileNumber(username)) {
        await dispatch.user.update({ username })
        return history.push(PAGE_PASSWORD)
      }

      try {
        const checkUsernameResult = await dispatch.user.checkUsername(body)
        checkUsernameResult?.identities?.length === 0
          ? setError('username', { type: 'notMatch' })
          : await dispatch.user.decideNextAction({ data: checkUsernameResult, routeAction: ROUTE_ACTION_PUSH })
        setLoading(false)
      } catch (errorData) {
        setLoading(false)
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.NOT_FOUND:
            setError('username', { type: 'notMatch' })
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async loginWithHint({ options, token }) {
      const { username, givenName, familyName } = options

      if (isHpEmail(username) && blockSignInNativeHp) return

      const redirectToSignUp = () => {
        if (isValidEmail(username)) dispatch.user.update({ [PARAM_EMAIL]: username })
        const shouldRedirectToOOBE = givenName && familyName && simplifiedAccount
        history.push(shouldRedirectToOOBE ? PAGE_OOBE_SIGN_UP : PAGE_SIGN_UP)
      }

      if (!isValidEmail(username) && !isValidMobileNumber(username)) {
        await dispatch.user.update({ username: username })
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT142'))
        return history.push(PAGE_PASSWORD)
      }

      try {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT142'))
        const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
        const checkUsernameResult = await dispatch.user.checkUsername({ captcha, username })
        const { identities = [] } = checkUsernameResult
        identities.length === 0
          ? redirectToSignUp()
          : await dispatch.user.decideNextAction({ data: checkUsernameResult, routeAction: ROUTE_ACTION_REPLACE })
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            const arkoseParams = {
              options,
              callback: dispatch.user.loginWithHint
            }

            const prepareArkoseBody = {
              endpoint: BFF_ENDPOINTS.SESSION_CHECK_USERNAME,
              username: username
            }
            const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.NOT_FOUND:
            redirectToSignUp()
            break
          default:
            dispatch.error.goError(errorData)
            break
        }
      }
    },

    async userCheckFlowURI(result) {
      if (result.status === BFF_STATUS.PHONE_VERIFICATION) {
        dispatch.user.update({ phoneNumber: result.phoneNumber })
        history.push(PAGE_MOBILE_CODE_SEND)
      }

      if (result.status === BFF_STATUS.EMAIL_VERIFICATION) {
        dispatch.user.update({ email: result.email, username: result.email })
        history.push(PAGE_CODE_SEND)
      }

      if (result.status === BFF_STATUS.EMAIL_OR_PHONE_VERIFICATION) {
        dispatch.user.update({ phoneNumber: result.phoneNumber, email: result.email })
        history.push(PAGE_MOBILE_CODE_SEND)
      }

      if (result.status === BFF_STATUS.TNC_VALIDATION_REQUIRED) {
        history.push(PAGE_TNC_MANAGEMENT)
      }

      if (result.status === BFF_STATUS.CONSENT_MANAGEMENT) {
        history.push(PAGE_CONSENT_MANAGEMENT)
      }

      if (result.status === BFF_STATUS.EMAIL_REQUIRED) {
        history.push(PAGE_ADD_EMAIL)
      }

      if (result.status === BFF_STATUS.MFA_REQUIRED) {
        dispatch.user.update({ availableMfaMethods: result.availableMfaMethods })
        history.push(PAGE_AUTH)
      }

      return result
    },

    async routeChange(routeName, routeAction) {
      if (routeAction === ROUTE_ACTION_REPLACE) {
        history.replace(routeName)
      } else {
        history.push(routeName)
      }
    },

    async decideNextAction({ data, routeAction = ROUTE_ACTION_PUSH }, rootState) {
      const { identities = [], discoveryIDP } = data
      const discoveryIdentity = config.identityProviders.find(
        (idp) => idp.identityProvider.toLowerCase() === discoveryIDP
      )
      const { supportedIDP } = rootState.session

      // go to check username page when there are more than 2 accounts
      if (identities.length > 1) {
        return dispatch.user.routeChange(PAGE_CHECK_USERNAME, routeAction)
      } else if (identities.length === 1) {
        const identity = identities[0]
        const isSupportedIDP =
          supportedIDP &&
          supportedIDP.length > 0 &&
          supportedIDP.find(
            (idp) => identity.identityProvider.toLowerCase() === idp.identityProvider.toLowerCase()
          )
        if (identity.identityProvider === discoveryIDP) {
          //  redirect to federated website
          await dispatch.user.initFederated(discoveryIdentity.name)
        } else if (isSupportedIDP) {
          // if the only account is one of the following federated account, go to redirect help page
          return dispatch.user.routeChange(PAGE_REDIRECT_TO, routeAction)
        } else {
          // update the username in redux state and go to password page
          await dispatch.user.update(data)
          return dispatch.user.routeChange(PAGE_PASSWORD, routeAction)
        }
      }
      return data
    },

    async signOut(postRedirectUrl) {
      try {
        const redirect = `${config.directoryUrl}/directory/v1/oauth/logout?post_logout_redirect_uri=${postRedirectUrl}`
        window.location.assign(redirect)
      } catch (error) {
        console.error('redirection failed')
        dispatch.error.goError()
      }
    },

    async redirectToAPP() {
      try {
        const res = await bff.postBackToApp()
        if (res.data && res.data.nextUrl) {
          window.location.assign(res.data.nextUrl)
        } else {
          dispatch.error.goError()
        }
      } catch (error) {
        console.error('redirection failed')
        dispatch.error.goError()
      }
    },

    async handleBackLink(data, rootState) {
      const { user } = rootState
      if (shouldBackToApp(data, user)) {
        dispatch.user.redirectToAPP()
      } else {
        history.back()
      }
    },

    async login(data, rootState) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, password, setWeakPasswordError } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const { username, rememberMe, loginCaptchaCredit, usernameType } = rootState.user
      let loginMode = usernameType

      if (usernameType === USERNAME_TYPES.USERNAME_EMAIL && !isValidEmail(username)) {
        loginMode = USERNAME_TYPES.USERNAME
      }

      const formatUsername = `${username}@hpid`
      const prepareArkoseBody = { endpoint: BFF_ENDPOINTS.SESSION_USERNAME_PASSWORD, username: formatUsername }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      dispatch.user.update({ password })

      if (!loginCaptchaCredit && !captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        const res = await bff.postUsernamePassword({ username: formatUsername, password, captcha })
        const resData = res.data
        resData[SESSION_DATA] && dispatch.session.updateUserSession(resData[SESSION_DATA])
        rememberMe ? setRememberedUsername(username) : removeRememberedUsername()
        const isMfaRequired = resData.status === BFF_STATUS.MFA_REQUIRED
        if (isMfaRequired) {
          dispatch.user.update({ availableMfaMethods: resData.availableMfaMethods })
          history.push(PAGE_AUTH, { allowBack: true })
        } else {
          const redirectUser = () => {
            dispatch.udl.trackEvent(
              udlEvents.getEventWithObject('EVENT03', {
                v151: `usernameType:${USERNAME_TYPES_ALIAS[loginMode]}`,
                v152: `remember-me:${rememberMe}`
              })
            )
            history.push(PAGE_REDIRECT, { nextUrl: resData.nextUrl })
          }
          resData.nextUrl ? redirectUser() : await dispatch.user.userCheckFlowURI(resData)
        }
        setLoading(false)
      } catch (errorData) {
        setLoading(false)
        switch (errorData.error) {
          case BFF_STATUS.INVALID_CREDENTIALS:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT75'))
            setError('password', { type: 'notMatch', message: 'form.err_invalid_credential' })
            break
          case BFF_STATUS.CAPTCHA_REQUIRED:
            setLoading(true)
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.MUST_CHANGE_PASSWORD:
            // TODO: REMOVE DEAD CODE!
            dispatch.user.update({ passwordRequirements: errorData.passwordRequirements })
            history.push(PAGE_CHANGE_PASSWORD)
            break
          case BFF_STATUS.ACCOUNT_LOCKED:
            setError('password', { type: 'manual', message: 'form.err_account_locked' })
            break
          case BFF_STATUS.WEAK_PASSWORD:
            setError('password', { type: 'weakPassword', message: setWeakPasswordError() })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT131'))
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async initFederated(provider) {
      try {
        const socialCallbackURL = `${window.location.protocol}//${window.location.host}${PAGE_SOCIAL_LOGIN}`
        const body = {
          provider: provider,
          callbackUrl: socialCallbackURL
        }
        const res = await bff.postFederatedInitialize(body)
        if (res.data && res.data.providerRedirectUrl) {
          window.location.assign(res.data.providerRedirectUrl)
        }
        return res.data
      } catch (errorData) {
        dispatch.error.goError(errorData)
      }
    },

    async authorizeFederated(data) {
      let body = {}
      if (config.SAMLResponse && config.RelayState) {
        body = {
          SAMLResponse: config.SAMLResponse,
          callbackState: config.RelayState
        }
      } else {
        body = {
          callbackCode: data?.code || data?.SAMLart,
          callbackState: data?.state || data?.RelayState
        }
      }
      const res = await bff.postAuthorizeFederated(body)
      return res.data
    },

    async setCountry() {
      try {
        const storedCountry = checkLocalStorage() && window.localStorage.getItem(PARAM_COUNTRY)
        if (storedCountry) {
          checkValidCountry(storedCountry) && dispatch.user.update({ country: storedCountry })
        } else {
          const res = await apiExternalUrl.get(config.geoURL)
          checkValidCountry(res.data.country) && dispatch.user.update({ country: res.data.country })
        }
      } catch (err) {
        dispatch.user.update({ country: 'US' })
      }
    },

    async requestCode(captcha) {
      const payload = {}
      if (captcha) {
        payload.captcha = captcha
      }
      const res = await bff.postEmailOrPhoneSendCode(payload)
      return res.data
    },

    async checkVerified() {
      return await bff.getCheckVerified()
    },

    async verifyCode({ captcha, code }) {
      const body = {
        code: code.toUpperCase().trim(),
        captcha
      }
      const res = await bff.postVerifyCodeEmail(body)
      return res.data
    },

    async verifySMSCode(data) {
      const { captcha } = data
      const body = {
        code: data.code.toUpperCase().trim(),
        captcha
      }
      const res = await bff.postVerifyCodeMobile(body)
      return res.data
    },

    async handleUsernameRecovery(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, recoveryInput } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = { endpoint: BFF_ENDPOINTS.RECOVERY_USERNAME, email: recoveryInput }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT05'))
        await bff.postRecoveryUsername({ email: recoveryInput, captcha })
        dispatch.user.update({ username: recoveryInput })
        dispatch.session.update({ retryDelay: true })
        history.push(PAGE_USERNAME_RECOVERY_SUCCESS)
      } catch (errorData) {
        setLoading(false)
        // Workaround to handle the error in cases when the user tries to request code before 60 secs
        const { response } = errorData
        if (response && response.data && response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT167'))
          return dispatch.session.update({ retryDelay: true })
        }
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.USERNAME_RECOVERY_NO_MATCH:
            setError('recoveryInput', {
              type: BFF_STATUS.USERNAME_RECOVERY_NO_MATCH,
              message: 'form.err_recovery_no_match'
            })
            break
          case BFF_STATUS.RECOVERY_EMAIL_NO_RESULTS:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT103'))
            setError('recoveryInput', {
              type: BFF_STATUS.RECOVERY_EMAIL_NO_RESULTS,
              message: 'form.err_recovery_email_no_results'
            })
            break
          case BFF_STATUS.SERVER_ERROR:
            setError('recoveryInput', {
              type: BFF_STATUS.SERVER_ERROR,
              message: 'form.err_recovery_sending_email_failed'
            })
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async handlePasswordRecovery(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, recoveryInput, previousPage } = options
      const isUsernameMobile = isPhoneString(recoveryInput)
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = isUsernameMobile
        ? { endpoint: BFF_ENDPOINTS.RECOVERY_PHONE_PASSWORD, phoneNumber: recoveryInput }
        : { endpoint: BFF_ENDPOINTS.RECOVERY_PASSWORD, username: recoveryInput }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        if (isUsernameMobile) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT02'))
          await dispatch.user.recoverPasswordMobile({ recoveryInput, captcha })
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT06'))
          await dispatch.user.recoverPassword({ recoveryInput, captcha })
        }
        dispatch.user.update({ username: recoveryInput })
        dispatch.session.update({ retryDelay: true })
        previousPage && previousPage === PAGE_PASSWORD
          ? history.push(PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD)
          : history.push(PAGE_PASSWORD_RESET)
      } catch (errorData) {
        setLoading(false)
        let eventID
        // Workaround to handle the error in cases when the user tries to change the email more than 3 times per day
        const { response } = errorData
        if (response && response.data && response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          eventID = previousPage && previousPage === PAGE_PASSWORD ? 'EVENT192' : 'EVENT155'
          dispatch.udl.trackEvent(udlEvents.getEventByID(eventID))
          return dispatch.session.update({ retryDelay: true })
        }
        // Normal error handling
        switch (errorData.error) {
          case BFF_STATUS.USERNAME_RECOVERY_NO_MATCH:
            if (isUsernameMobile) {
              setError('recoveryInput', {
                type: BFF_STATUS.USERNAME_RECOVERY_NO_MATCH,
                message: 'form.err_recovery_mobile_no_match'
              })
            } else {
              setError('recoveryInput', {
                type: BFF_STATUS.USERNAME_RECOVERY_NO_MATCH,
                message: 'form.err_recovery_no_match'
              })
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT99'))
            }
            break
          case BFF_STATUS.RECOVERY_EMAIL_NO_RESULTS:
            setError('recoveryInput', {
              type: BFF_STATUS.RECOVERY_EMAIL_NO_RESULTS,
              message: 'form.err_recovery_email_no_results'
            })
            break
          case BFF_STATUS.SERVER_ERROR:
            setError('recoveryInput', {
              type: BFF_STATUS.SERVER_ERROR,
              message: isUsernameMobile ? 'form.err_send_email_code' : 'form.err_recovery_sending_email_failed'
            })
            break
          case BFF_STATUS.NOT_ALLOWED:
            setError('recoveryInput', { type: BFF_STATUS.NOT_ALLOWED, message: 'form.mobile_no_verified' })
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async recoverPassword({ recoveryInput, captcha }) {
      const body = {
        username: recoveryInput,
        captcha
      }
      const { data } = await bff.postRecoveryPassword(body)
      return data
    },

    async recoverPasswordMobile({ recoveryInput, captcha }) {
      const body = {
        phoneNumber: recoveryInput,
        captcha
      }
      const { data } = await bff.postRecoveryPasswordMobile(body)
      return data
    },

    async resetPassword({ token, options }) {
      const {
        data,
        setError,
        setLoading,
        username,
        isMobileNumber,
        arkoseParams,
        setWeakPasswordError,
        currentPage
      } = options

      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = isMobileNumber
        ? { endpoint: BFF_ENDPOINTS.RECOVERY_PHONE_PASSWORD_RESET, phoneNumber: username }
        : { endpoint: BFF_ENDPOINTS.RECOVERY_PASSWORD_RESET, username }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { ...data, username, captcha }

      try {
        if (isMobileNumber) {
          await dispatch.user.resetPasswordMobile(body)
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT01'))
        } else {
          await dispatch.user.resetPasswordEmail(body)
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT09'))
        }
        history.push(PAGE_PASSWORD_RESET_SUCCESS)
      } catch (errorData) {
        setLoading(false)
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.INVALID_CODE:
            setError('code', { type: 'invalidCode', message: 'label.code_invalid_request' })
            const eventID = currentPage === PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD ? 'EVENT189' : 'EVENT20'
            dispatch.udl.trackEvent(udlEvents.getEventByID(eventID))
            break
          case BFF_STATUS.INVALID_VALUE:
            setError('password', { type: 'invalidCode', message: 'form.err_password_not_current' })
            break
          case BFF_STATUS.WEAK_PASSWORD:
            if (currentPage && currentPage === PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD) {
              setError('password', { type: 'weakPassword', message: 'label.password_cannot_be_used' })
              return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT190'))
            }
            setError('password', { type: 'weakPassword', message: setWeakPasswordError && setWeakPasswordError() })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT129'))
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    },

    async resetPasswordMobile({ username, code, password, captcha }) {
      const body = {
        phoneNumber: username,
        code: code.toUpperCase(),
        newPassword: password,
        captcha
      }
      const { data } = await bff.postResetPasswordMobile(body)
      return data
    },

    async resetPasswordEmail({ username, code, password, captcha }) {
      const body = {
        username,
        code: code.toUpperCase(),
        newPassword: password,
        captcha
      }
      const { data } = await bff.postResetPasswordEmail(body)
      return data
    },

    async changePassword({ password, newPassword, captcha }) {
      const res = await bff.postSessionChangePassword({
        password,
        newPassword,
        captcha
      })
      return res.data
    },

    async submitChangePassword({ options, token }) {
      const { setLoading, setError, arkoseParams, newPassword, password, setWeakPasswordError } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const updateArkoseParams = {
        ...arkoseParams,
        prepareArkoseBody: { endpoint: BFF_ENDPOINTS.SESSION_CHANGE_PASSWORD }
      }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        const result = await dispatch.user.changePassword({ password: password, newPassword, captcha })
        if (result.status === BFF_STATUS.SUCCESS) {
          history.push(PAGE_PASSWORD_RESET_SUCCESS, { context: PAGE_CHANGE_PASSWORD })
        } else {
          await dispatch.user.userCheckFlowURI(result)
        }
        setLoading(false)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.INVALID_CREDENTIALS:
            setError('newPassword', { type: 'server', message: 'form.err_password_incorrect' })
            setLoading(false)
            break
          case BFF_STATUS.INVALID_NEW_PASSWORD:
            if (
              getFailedPasswordRequirements(errorData.passwordRequirements) ===
              PASSWORD_REQUIREMENT_TYPE.NOT_CURRENT_PASSWORD
            ) {
              setError('newPassword', { type: 'server', message: 'form.err_password_invalid' })
            } else {
              setError('newPassword', { type: 'server', message: 'form.err_password_complexity' })
            }
            setLoading(false)
            break
          case BFF_STATUS.WEAK_PASSWORD:
            displayLearnMorePage
              ? setError('newPassword', { type: 'weakPassword', message: setWeakPasswordError() })
              : setError('newPassword', { type: 'weakPassword', message: 'label.password_leak' })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT130'))
            setLoading(false)
            break
          default:
            dispatch.error.goError(errorData)
            setLoading(false)
        }
      }
    },

    async setPrimaryEmail(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, email } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = {
        endpoint: BFF_ENDPOINTS.SESSION_EMAIL_SET_PRIMARY,
        email
      }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        const res = await bff.postSetPrimaryEmail({ captcha, email })
        const redirectUser = () => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT03'))
          history.push(PAGE_REDIRECT, { nextUrl: res.data.nextUrl })
        }
        res.data.nextUrl ? redirectUser() : await dispatch.user.userCheckFlowURI(res.data)
        setLoading(false)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.CONFLICTING_RESOURCE:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT135'))
            setError('email', { type: 'manual', message: 'form.err_email_in_use' })
            setLoading(false)
            break
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            dispatch.error.goError(errorData)
            setLoading(false)
        }
      }
    },

    async changePrimaryEmail(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, email, identityProvider } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const endpoint =
        identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT
          ? BFF_ENDPOINTS.SESSION_FEDERATED_EMAIL_CHANGE_PRIMARY
          : BFF_ENDPOINTS.SESSION_EMAIL_CHANGE_PRIMARY
      const prepareArkoseBody = { endpoint, email, sendCode: true }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        let res
        if (identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT) {
          res = await bff.postFederatedChangePrimaryEmail({ captcha, email, sendCode: true })
        } else {
          res = await bff.postChangePrimaryEmail({ captcha, email, sendCode: true })
        }
        dispatch.user.update({ username: res.data.email, email: res.data.email })
        // If the code is sent, start the retry timer and redirect the user to the email verification page
        if (res.data.sendCode === 'success') {
          dispatch.session.update({ retryDelay: true })
          history.push(PAGE_EMAIL_VERIFICATION)
        } else {
          history.push(PAGE_CODE_SEND)
        }
      } catch (errorData) {
        // Workaround to handle the error in cases when the user tries to change the email more than 3 times per day
        const { response } = errorData
        if (response?.data?.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          dispatch.session.update({ retryDelay: true })
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT92'))
          setError('emailInput', { type: 'manual', message: 'form.changed_too_many' })
          setLoading(false)
          return
        }
        // Normal error handling
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.EDIT_UNIQUENESS:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT124'))
            setError('emailInput', { type: 'manual', message: 'form.err_unique_username' })
            setLoading(false)
            break
          default:
            dispatch.error.goError(errorData)
            setLoading(false)
        }
      }
    },

    async changePrimaryMobile(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, phoneNumber, identityProvider } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const endpoint =
        identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT
          ? BFF_ENDPOINTS.SESSION_FEDERATED_PHONE_CHANGE_PRIMARY
          : BFF_ENDPOINTS.SESSION_PHONE_CHANGE_PRIMARY
      const prepareArkoseBody = { endpoint, phoneNumber, sendCode: true }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        let res
        if (identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT) {
          res = await bff.postFederatedChangePrimaryMobile({ captcha, phoneNumber, sendCode: true })
        } else {
          res = await bff.postChangePrimaryMobile({ captcha, phoneNumber, sendCode: true })
        }
        dispatch.user.update({ phoneNumber: res.data.phoneNumber, email: res.data.email })
        // If the code is sent, start the retry timer and redirect the user to the phone verification page
        if (res.data.sendCode === 'success') {
          dispatch.session.update({ retryDelay: true })
          history.push(PAGE_PHONE_VERIFICATION)
        } else {
          history.push(PAGE_MOBILE_CODE_SEND)
        }
      } catch (errorData) {
        // Workaround to handle the error in cases when the user tries to change the phone number more than 3 times per day
        const { response } = errorData
        if (response?.data?.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          setError('phoneNumber', { type: 'manual', message: 'form.changed_too_many' })
          setLoading(false)
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT117'))
          return
        }
        // Normal error handling
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.EDIT_UNIQUENESS:
            setError('phoneNumber', { type: 'manual', message: 'form.err_phone_in_use' })
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT116'))
            setLoading(false)
            break
          default:
            dispatch.error.goError(errorData)
            setLoading(false)
        }
      }
    },

    async mfaSendRecoveryCode(data) {
      const { options, token } = data
      const { setLoading, setMadeTooManyRequests, arkoseParams, type } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody =
        type === PARAM_EMAIL
          ? { endpoint: BFF_ENDPOINTS.SESSION_MFA_EMAIL_SEND_CODE }
          : { endpoint: BFF_ENDPOINTS.SESSION_MFA_PHONE_SEND_CODE }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }
      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)
      const body = { captcha }

      try {
        await bff.postMfaSendCode(body, type)
        dispatch.session.update({ retryDelay: true })
        history.push(PAGE_AUTH_RECOVERY_VERIFICATION, { type })
        setLoading(false)
      } catch (errorData) {
        const error = errorData.response ? errorData.response.data.error : errorData.error
        switch (error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.TOO_MANY_REQUESTS:
            setLoading(false)
            setMadeTooManyRequests(true)
            return dispatch.session.update({ retryDelay: true })
          default:
            console.error(errorData)
            setLoading(false)
            dispatch.error.goError(errorData)
        }
      }
    },
    // TODO: Error handling + User feedback??
    async mfaResendRecoveryCode(data) {
      const { options, token } = data
      const { setShowResend, arkoseParams, type } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody =
        type === PARAM_EMAIL
          ? { endpoint: BFF_ENDPOINTS.SESSION_MFA_EMAIL_SEND_CODE }
          : { endpoint: BFF_ENDPOINTS.SESSION_MFA_PHONE_SEND_CODE }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { captcha }

      try {
        dispatch.session.update({ retryDelay: true })
        await bff.postMfaSendCode(body, type)
        setShowResend(true)
      } catch (errorData) {
        // Workaround to handle the error in cases when the user tries to request code before 60 secs
        const { response } = errorData
        if (response && response.data && response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT139'))
          return dispatch.session.update({ retryDelay: true })
        }
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            console.error(errorData)
            setShowResend(true)
        }
      }
    },

    async mfaVerifyRecoveryCode(data) {
      const { options, token } = data
      const { setLoading, setError, arkoseParams, code, type } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody =
        type === PARAM_EMAIL
          ? { endpoint: BFF_ENDPOINTS.SESSION_MFA_EMAIL_VERIFY_CODE }
          : { endpoint: BFF_ENDPOINTS.SESSION_MFA_PHONE_VERIFY_CODE }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { captcha, code }

      try {
        const res = await bff.postMfaVerifyCode(body, type)
        const resData = res.data
        const redirectUser = () => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT03'))
          history.push(PAGE_REDIRECT, { nextUrl: resData.nextUrl })
        }
        resData.nextUrl ? redirectUser() : await dispatch.user.userCheckFlowURI(resData)
        setLoading(false)
      } catch (errorData) {
        switch (errorData.error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          default:
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT164'))
            setError('code', { type: errorData.error, message: 'form.err_recovery_code' })
            setLoading(false)
          // TODO: We need to decide what errors should redirect to the error page
        }
      }
    },

    async resendCode(data) {
      const { token, options } = data
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const { isMobileNumber, setResendLoading, username, arkoseParams } = options
      const prepareArkoseBody = isMobileNumber
        ? { endpoint: BFF_ENDPOINTS.RECOVERY_PHONE_PASSWORD, phoneNumber: username }
        : { endpoint: BFF_ENDPOINTS.RECOVERY_PASSWORD, username }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { ...options, recoveryInput: username, captcha }

      try {
        isMobileNumber
          ? await dispatch.user.recoverPasswordMobile(body)
          : await dispatch.user.recoverPassword(body)
        dispatch.session.update({ retryDelay: true })
        setResendLoading(false)
      } catch (errorData) {
        if (errorData.response.status === 429) {
          dispatch.session.update({ retryDelay: true })
          setResendLoading(false)
        }
      }
    },

    async otpLoginWithEmail({ username, captcha }) {
      const body = {
        email: username,
        captcha
      }
      const { data } = await bff.postOTPLoginSendCode(body)
      return data
    },

    async otpLoginWithPhoneNumber({ username, captcha }) {
      const type = USERNAME_TYPES_ALIAS[USERNAME_TYPES.USERNAME_PHONE]
      const body = {
        phoneNumber: username,
        captcha
      }
      const { data } = await bff.postOTPLoginSendCode(body, type)
      return data
    },

    async otpLoginSendCode(data) {
      dispatch.user.update({ lastOTPStatus: '' })
      const { options, token } = data
      const { setError, setOTPLoading, username, arkoseParams, isEmail } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const prepareArkoseBody = isEmail
        ? { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_EMAIL_SEND_CODE, email: username }
        : { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_PHONE_SEND_CODE, phoneNumber: username }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const body = { username, captcha }

      try {
        const data = isEmail
          ? await dispatch.user.otpLoginWithEmail(body)
          : await dispatch.user.otpLoginWithPhoneNumber(body)
        dispatch.user.update({ username })
        dispatch.user.update({ identities: data.identities })
        dispatch.session.update({ retryDelay: true })
        history.push(PAGE_OTP_SIGN_IN)
        setOTPLoading(false)
      } catch (errorData) {
        setOTPLoading(false)
        const error = errorData.response ? errorData.response.data.error : errorData.error
        switch (error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.session.update({ retryDelay: true })
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.TOO_MANY_REQUESTS:
            dispatch.session.update({ retryDelay: true })
            setError('username', { type: BFF_STATUS.TOO_MANY_REQUESTS, message: 'form.err_send_email_code' })
            break
          case BFF_STATUS.NO_NATIVE:
            dispatch.session.update({ retryDelay: true, })
            dispatch.user.update({ lastOTPStatus: 'NO_NATIVE' })
            setError('username', { type: BFF_STATUS.NO_NATIVE, message: 'label.cannot_otp' })
            break
          //TODO: For now the bff is returning only as serverError.
          // Need to test it after the bff team updates the error code.
          case BFF_STATUS.NOT_ALLOWED:
            dispatch.session.update({ retryDelay: true })
            setError('username', { type: BFF_STATUS.NOT_ALLOWED, message: 'label.unverified_phone_otp' })
            break
          case BFF_STATUS.MFA_ENABLED:
            dispatch.session.update({ retryDelay: true })
            dispatch.user.update({ lastOTPStatus: 'MFA_ENABLED' })
            setError('username', { type: BFF_STATUS.MFA_ENABLED, message: 'label.cannot_otp' })
            break
          case BFF_STATUS.NOT_FOUND:
            dispatch.session.update({ retryDelay: true })
            setError('username', { type: 'notMatch' })
            break
          //TODO: Need to get the correct string (if there will be one) for this scenario (I added a temporary string for now)
          case BFF_STATUS.OTP_LOGIN_DISABLED:
            dispatch.session.update({ retryDelay: true })
            setError('username', { type: BFF_STATUS.OTP_LOGIN_DISABLED, message: 'label.otp_disabled' })
            break
          case BFF_STATUS.UNSUPPORTED_COUNTRY:
            dispatch.session.update({ retryDelay: true })
            setError('username', { type: BFF_STATUS.UNSUPPORTED_COUNTRY, message: 'form.err_unsupported_country' })
            break
          case BFF_STATUS.PROHIBITED_EMAIL_DOMAIN:
            dispatch.session.update({ retryDelay: true })
            setError('username', {
              type: BFF_STATUS.PROHIBITED_EMAIL_DOMAIN,
              message: 'form.err_invalid_hp_email'
            })
            break
          default:
            const error = errorData.response ? errorData.response.data : errorData
            dispatch.error.goError(error)
        }
      }
    },

    async otpResendCode(data) {
      const { options, token } = data
      const { setError, setResendLoading, username, arkoseParams, isEmail } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const formatUsername = !isEmail ? username.replace(/\s+/g, '') : username.trim()
      const prepareArkoseBody = isEmail
        ? { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_EMAIL_SEND_CODE, email: formatUsername }
        : { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_PHONE_SEND_CODE, phoneNumber: formatUsername }
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)
      const body = { username: formatUsername, captcha }

      try {
        isEmail ? await dispatch.user.otpLoginWithEmail(body) : await dispatch.user.otpLoginWithPhoneNumber(body)
        dispatch.session.update({ retryDelay: true })
        setResendLoading(false)
      } catch (errorData) {
        const error = errorData.response ? errorData.response.data.error : errorData
        setResendLoading(false)
        switch (error) {
          case BFF_STATUS.CAPTCHA_REQUIRED:
            dispatch.arkose.handleArkose(updateArkoseParams)
            break
          case BFF_STATUS.TOO_MANY_REQUESTS:
            dispatch.session.update({ retryDelay: true })
            setError('passcode', { type: BFF_STATUS.TOO_MANY_REQUESTS, message: 'form.err_send_email_code' })
            break
          default:
            const error = errorData.response ? errorData.response.data : errorData
            dispatch.error.goError(error)
        }
      }
    },

    async otpLoginVerifyCode(data) {
      const { options, token } = data
      const { username, setError, setLoading, isEmail, arkoseParams, passcode, otpLoginButton } = options
      const type = isEmail ? 'email' : 'phone'
      const captchaData = token && { data: token, type: ARKOSE_KEYS.DEFAULT }

      const prepareArkoseBody = isEmail
        ? { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_EMAIL_VERIFY_CODE, email: username }
        : { endpoint: BFF_ENDPOINTS.SESSION_OTP_LOGIN_PHONE_VERIFY_CODE, phoneNumber: username }

      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody }

      if (!captchaData) {
        return dispatch.arkose.handleArkose(updateArkoseParams)
      }

      const payload = {
        [isEmail ? 'email' : 'phoneNumber']: username,
        code: passcode,
        captcha: captchaData
      }
      try {
        const { data } = await bff.postOTPLoginPVerifyCode(payload, type)

        const redirectUser = () => {
          if (otpLoginButton === OTP_BUTTON_LABEL.USE_ONE_TIME_PASSCODE) {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT198'))
          }
          if (otpLoginButton === OTP_BUTTON_LABEL.SEND_SIGN_IN_CODE) {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT201'))
          }
          history.push(PAGE_REDIRECT, { nextUrl: data.nextUrl })
        }
        data.nextUrl ? redirectUser() : await dispatch.user.userCheckFlowURI(data)
      } catch (errorData) {
        const { error } = errorData.response ? errorData.response.data : errorData
        switch (error) {
          case 'Not implemented':
            setError('passcode', { type: 'invalidCode', message: 'label.passcode_invalid_expired' })
            setLoading(false)
            break
          case 'invalidCredentials':
            setError('passcode', { type: 'invalidCode', message: 'label.passcode_invalid_expired' })
            setLoading(false)
            break
          case 'tooManyRequests':
            setError('passcode', { type: 'tooManyRequests', message: 'form.err_send_email_code' })
            setLoading(false)
            break
          default:
            dispatch.error.goError(errorData)
        }
      }
    }
  })
}

export default user
