import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IconCheckCircle from '@veneer/core/dist/esm/scripts/icons/icon_checkmark_circle'
import { MainLayout, DefaultHeader } from '../../component'
import { EMAIL_VERIFICATION_TAB_TITLE } from '../../constants'

import * as S from './styles'

const VerificationConfirmed = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = EMAIL_VERIFICATION_TAB_TITLE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <DefaultHeader />
      <S.BodyContainer>
        <S.CheckMarkContainer>
          <IconCheckCircle color="chartreuse8" size={50} />
        </S.CheckMarkContainer>
        <S.TextContainer>{t('label.verification_confirmed')}</S.TextContainer>
      </S.BodyContainer>
    </MainLayout>
  )
}

export default VerificationConfirmed
