import bff from '../services'
import { getArkoseLang, couldEndpointThrowTooManyRequestError } from '../util'
import { ARKOSE_KEYS, BFF_STATUS, PARAM_CAPTCHA_BYPASS_KEY, BFF_ENDPOINTS } from '../constants'

let runAgain = false

const arkose = {
  state: {
    isLoadedArkoseDefault: false,
    isLoadedArkoseSignUp: false,
    lastArkoseSession: ''
  },
  reducers: {
    update(state, data) {
      return { ...state, ...data }
    }
  },
  effects: (dispatch) => ({
    async _appendScript(arkoseParams, { session }) {
      const { arkoseKey, arkoseLang, parsedKey } = arkoseParams
      const captchaEngines = session.captchaEngines
      const { data } = captchaEngines ? captchaEngines.find(({ type }) => type === arkoseKey) : ''
      const script = document.createElement('script')
      script.src = `https://hp-api.arkoselabs.com/v2/${data}/api.js`
      script.setAttribute('data-callback', `setupEnforcement${parsedKey}`)
      script.id = parsedKey
      document.head.appendChild(script)

      window[`setupEnforcement${parsedKey}`] = (enforcement) => {
        enforcement.setConfig({
          language: arkoseLang,
          onReady: () => {
            window[`Arkose${parsedKey}`] = enforcement
            dispatch.arkose.update({ [`isLoaded${parsedKey}`]: true })
            dispatch.arkose._load(arkoseParams)
          }
        })
      }
    },

    async _getBlob(payload) {
      try {
        const { data } = await bff.postPrepareArkoseSession(payload)
        const { blob } = data
        return blob
      } catch (error) {
        const { response } = error
        const { endpoint } = payload
        const tooManyRequestError = response?.data?.error === BFF_STATUS.TOO_MANY_REQUESTS

        if (tooManyRequestError && couldEndpointThrowTooManyRequestError(endpoint)) {
          return dispatch.session.update({ retryDelay: true, arkoseTooManyRequests: true })
        }

        if (error.response) {
          return dispatch.error.goError(error.response.data)
        } else {
          return dispatch.error.goError(error)
        }
      }
    },

    async _injectBlob({ prepareArkoseBody, parsedKey }, { session }) {
      const isArkoseDXEnabled = session.arkoseDataExchange
      if (!isArkoseDXEnabled) return
      const blob = await dispatch.arkose._getBlob(prepareArkoseBody)
      window[`Arkose${parsedKey}`].setConfig({
        ...(blob && {
          data: {
            blob
          }
        })
      })
    },

    async _load({ submitSelector, callback, onHide, formErrors, parsedKey, options, prepareArkoseBody }) {
      const arkose = `Arkose${parsedKey}`

      try {
        if (window[arkose]) {
          await dispatch.arkose._injectBlob({ prepareArkoseBody, parsedKey })
          window[arkose].setConfig({
            onCompleted: (data) => {
              if (parsedKey === 'ArkoseDefault') {
                setTimeout(() => {
                  runAgain = false
                }, 1000)
              }
              const sessionArkose = data.token.split('|').shift()
              dispatch.arkose.update({ lastArkoseSession: sessionArkose })
              const params = { ...data, options }
              callback(params)
            },
            onReady: window[arkose].run(),
            selector: formErrors || !submitSelector ? 'selector-default' : `#${submitSelector}`,
            onHide: (data) => {
              runAgain = true
              onHide()
              if (data.error?.error === 'API_REQUEST_SOURCE_VALIDATION') {
                const data = {
                  error: BFF_STATUS.CAPTCHA_REQUIRED
                }
                dispatch.error.goError(data)
              }
            }
          })
        }
      } catch (error) {
        dispatch.error.goError()
      }
    },

    async _loadBypass({ arkoseBypassKey, callback, options }) {
      const callbackParams = {
        completed: true,
        token: `bypass-key:${arkoseBypassKey}`,
        options
      }
      callback(callbackParams)
    },

    async handleArkose(arkoseParams, { arkose, user }) {
      const {
        submitSelector = '',
        callback = () => null,
        onHide = () => null,
        formErrors = undefined,
        arkoseKey = ARKOSE_KEYS.DEFAULT,
        options = {},
        prepareArkoseBody = {}
      } = arkoseParams
 
      const arkoseLang = getArkoseLang(user.locale)
      const arkoseBypassKey = window.sessionStorage.getItem(PARAM_CAPTCHA_BYPASS_KEY) || ''

      if (arkoseBypassKey) return dispatch.arkose._loadBypass({ arkoseBypassKey, callback, options })

      const parsedKey = arkoseKey === ARKOSE_KEYS.SIGN_UP ? 'ArkoseSignUp' : 'ArkoseDefault'
      const { endpoint } = prepareArkoseBody
      const isSendCodeEndpoint =
        endpoint === BFF_ENDPOINTS.SESSION_PHONE_SEND_CODE || endpoint === BFF_ENDPOINTS.SESSION_EMAIL_SEND_CODE

      if (!arkose[`isLoaded${parsedKey}`] || runAgain || parsedKey === 'ArkoseSignUp' || isSendCodeEndpoint) {
        dispatch.arkose._appendScript({
          submitSelector,
          callback,
          onHide,
          formErrors,
          arkoseKey,
          arkoseLang,
          parsedKey,
          options,
          prepareArkoseBody
        })
      } else {
        dispatch.arkose._load({
          submitSelector,
          callback,
          onHide,
          formErrors,
          arkoseKey,
          arkoseLang,
          parsedKey,
          options,
          prepareArkoseBody
        })
      }
    }
  })
}

export default arkose
