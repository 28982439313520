import styled from '@emotion/styled'

export const TextWrapper = styled.div`
  display: block;
  margin-top: 10px;
  p {
    padding-top: 10px;
    strong {
      white-space: nowrap;
    }
  }
  margin-bottom: 30px;
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`