import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { State } from '../../../common/types'
import { DefaultHeader, MainLayout, UIUsername } from '../../../component'
import { ARKOSE_SELECTORS, PARAM_TARGET_FORGET_PWD, USERNAME_TYPES } from '../../../constants'
import { shouldBackToApp, validateMobileNumber, validateUsername, isPhoneString } from '../../../util'
import udlEvents from '../../../common/udlEvents'

import * as S from './styles'

type FormValues = {
  recoveryInput: string
}

const EmailOrPhoneAssociated = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user } = useSelector((state: State) => state)
  const { handlePasswordRecovery } = useSelector((state: State) => state.loading.effects.user)
  const { smsSupportedCountryNumbers, retryDelay } = useSelector((state: State) => state.session)

  const [recoveryTip, setRecoveryTip] = useState('label.enter_email_account')
  const [isLoading, setLoading] = useState(false)

  const defaultValues: FormValues = {
    recoveryInput: user.username || ''
  }

  useEffect(() => {
    if (!smsSupportedCountryNumbers || !smsSupportedCountryNumbers.length) {
      dispatch.session.fetchSmsSupportedCountryNumbers()
    }
  }, [dispatch.session, smsSupportedCountryNumbers])

  const data = { target: PARAM_TARGET_FORGET_PWD }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues
  })

  const submitSelector = ARKOSE_SELECTORS.RECOVERY.FORGOT_PASSWORD

  const email = {
    clearErrors: clearErrors,
    id: 'recoveryInput',
    ariaLabel: 'recoveryInput',
    label: t('label.placeholder_email'),
    error: !!errors.recoveryInput,
    helperText: errors.recoveryInput && t(errors.recoveryInput.message),
    autoCapitalize: 'none',
    autoComplete: 'off',
    autoFocus: true,
    control,
    rules: {
      required: 'form.err_email_empty',
      validate: (value) => {
        const isPhoneNumber = isPhoneString(value)
        if (isPhoneNumber) return validateMobileNumber(value)
        return validateUsername(value)
      }
    }
  }

  const mobile = {
    clearErrors: clearErrors,
    id: 'recoveryInput',
    required: false,
    error: errors.recoveryInput && t(errors.recoveryInput.message),
    control,
    rules: {
      required: 'form.err_mobile_empty',
      validate: (value) => validateMobileNumber(value)
    }
  }

  const renderLabel = () => (shouldBackToApp(data, user) ? 'link.not_now' : 'button_capitalize.cancel')

  useEffect(() => {
    if (user.usernameType === USERNAME_TYPES.USERNAME_EMAIL) {
      setRecoveryTip('label.enter_email_account')
    } else {
      setRecoveryTip('label.enter_mobile_assoc')
    }
  }, [user.usernameType])

  const submit = async ({ recoveryInput }) => {
    setLoading(true)
    const options = { setLoading, setError, recoveryInput }
    const arkoseParams = {
      options,
      onHide: () => setLoading(false),
      callback: dispatch.user.handlePasswordRecovery,
      submitSelector,
      formErrors: errors
    }
    await dispatch.user.handlePasswordRecovery({
      options: { ...options, arkoseParams }
    })
  }

  useEffect(() => {
    if (errors.recoveryInput) {
      if (errors.recoveryInput.message === 'form.err_email_empty') {
        return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT97'))
      }
      if (errors.recoveryInput.message === 'form.err_invalid_hp_email') {
        return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT98'))
      }
      if (errors.recoveryInput.message === 'form.err_sms_number_invalid') {
        return dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT100'))
      }
    }
  }, [dispatch.udl, errors.recoveryInput])

  return (
    <MainLayout>
      <DefaultHeader title={t('label.forgot_password')} />
      <form onSubmit={handleSubmit(submit)}>
        <S.RecoveryTip>{t(recoveryTip)}</S.RecoveryTip>
        <UIUsername cProps={[email, mobile]} />
        <S.ActionWrapper>
          <S.ButtonWrapper>
            <Button
              id={submitSelector}
              name={submitSelector}
              type="submit"
              loading={isLoading || handlePasswordRecovery}
              disabled={retryDelay}
              onClick={() => dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT94'))}
              expanded
            >
              {t('button_capitalize.continue')}
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              tabIndex={0}
              id="cancel-button"
              appearance="ghost"
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT93'))
                dispatch.user.handleBackLink(data)
              }}
              expanded
            >
              {t(renderLabel())}
            </Button>
          </S.ButtonWrapper>
          {retryDelay && (
            <InlineNotification
              hideIcon={false}
              closeButton={false}
              title={t('label.code_in_moment_recovery')}
              type="informative"
            />
          )}
        </S.ActionWrapper>
      </form>
    </MainLayout>
  )
}

export default EmailOrPhoneAssociated
