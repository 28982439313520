import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface HeaderTitleProps {
  preventLineBreak?: boolean
  isRTL?: boolean
}

export const DefaultHeader = styled.header`
  display: flex;
  flex-direction: column;
`
export const Logo = styled.header`
  margin-bottom: -5px; // Fixing Extra Margin for veneer logo component
`

export const HeaderTitle = styled.h1<HeaderTitleProps>`
  font-size: ${(props) => (props.preventLineBreak ? '22px' : '36px')};
  font-weight: normal;
  letter-spacing: 0;
  line-height: ${(props) => (props.preventLineBreak ? '30px' : '36px')};
`

export const HeaderTextDiv = styled.div<HeaderTitleProps>`
  display: flex;
  align-items: end;
  margin: ${props => props.isRTL ? '10px' : '12px'} 0;
  justify-content: space-between;
  ${(props) =>
    props.preventLineBreak &&
    css`
      a {
        font-size: 12px;
      }
    `}
`
