import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { DefaultHeader, MainLayout, ChangePasswordForm, AccountRecoveryMessage } from '../../../component'
import { PAGE_DESCRIPTIONS, PAGE_USERNAME } from '../../../constants'
import Button from '@veneer/core/dist/esm/scripts/button'
import udlEvents from '../../../common/udlEvents'
import useDirection from '../../../customHooks/useDirection'

import * as S from './styles'

const PasswordReset = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleCancelButton = () => {
    dispatch.user.update({ username: '' })
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT193'))
    navigate(PAGE_USERNAME)
  }
  const { isRTL } = useDirection()

  return (
    <MainLayout>
      <DefaultHeader preventLineBreak={isRTL} isRTL={isRTL} title={t('label.forgot_password')} />
      <S.PageWrapper>
        <S.TextWrapper
          id={PAGE_DESCRIPTIONS.PASSWORD_RESET.CHECK_EMAIL_PHONE_DESC}
          aria-label={PAGE_DESCRIPTIONS.PASSWORD_RESET.CHECK_EMAIL_PHONE_DESC}
        >
          <AccountRecoveryMessage />
        </S.TextWrapper>
        <ChangePasswordForm />
        <S.ButtonWrapper>
          <Button
            tabIndex={0}
            id="cancel-button"
            aria-label="cancel-button"
            appearance="ghost"
            onClick={handleCancelButton}
            expanded
          >
            {t('button_capitalize.cancel')}
          </Button>
        </S.ButtonWrapper>
      </S.PageWrapper>
    </MainLayout>
  )
}

export default PasswordReset
