import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'
import { DefaultHeader, MainLayout, Print, TnCContent, UISpinner } from '../../../component'
import { State } from '../../../common/types'
import udlEvents from '../../../common/udlEvents'

import * as S from './styles'

const TnCPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { locale, country } = useSelector((state: State) => state.user)
  const { tncs } = useSelector((state: State) => state.session)
  const { loading } = useSelector((state: State) => state)

  const buttonBehavior = {
    accept: false,
    decline: false
  }

  const [isLoading, setLoading] = useState(buttonBehavior)
  const [isDisabled, setIsDisabled] = useState(buttonBehavior)

  const acceptOrDeclineTerms = async (decision: string) => {
    if (decision === 'accepted') {
      setLoading({ ...isLoading, accept: true })
      setIsDisabled({ ...isDisabled, decline: true })
    } else {
      setLoading({ ...isLoading, decline: true })
      setIsDisabled({ ...isDisabled, accept: true })
    }
    const data = { termsAndConditions: decision, options: { setLoading } }
    dispatch.session.acceptOrDeclineTermsAndConditions(data)
  }

  useEffect(() => {
    dispatch.session.fetchTermsAndConditions({ locale, country })
  }, [locale, country, dispatch.session])

  return (
    <MainLayout>
      <DefaultHeader />
      {loading.effects.session.fetchTermsAndConditions ? (
        <UISpinner />
      ) : (
        <>
          <div>
            <TnCContent tncs={tncs} />
          </div>
          <S.ButtonsContainer>
            <Button
              aria-label="btn-accept-tnc-page"
              name="btn-accept-tnc-page"
              id="btn-accept-tnc-page"
              loading={isLoading.accept}
              disabled={isDisabled.accept}
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT153'))
                acceptOrDeclineTerms('accepted')
              }}
            >
              {t('button_capitalize.accept')}
            </Button>
            <S.BackButtonContainer>
              <Button
                aria-label="btn-decline-tnc-page"
                name="btn-decline-tnc-page"
                id="btn-decline-tnc-page"
                appearance="ghost"
                loading={isLoading.decline}
                disabled={isDisabled.decline}
                onClick={() => {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT154'))
                  acceptOrDeclineTerms('rejected')
                }}
                expanded
              >
                {t('button_capitalize.decline')}
              </Button>
            </S.BackButtonContainer>
          </S.ButtonsContainer>
          <Print tncs={tncs} />
        </>
      )}
    </MainLayout>
  )
}

export default TnCPage
