import { UIPassword } from '..'
import { useTranslation } from 'react-i18next'

const ConfirmPassword = ({ errors, control, placeholder, rules, label }) => {
  const { t } = useTranslation()

  return (
    <UIPassword
      aria-label="confirmPassword"
      id="confirmPassword"
      error={!!errors.confirmPassword}
      helperText={errors.confirmPassword && t(errors.confirmPassword.message)}
      control={control}
      label={label}
      rules={rules}
    />
  )
}

export default ConfirmPassword
