import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { DefaultHeader, MainLayout, UITextBox } from '../../component'
import {
  ARKOSE_SELECTORS,
  BFF_IDENTITY_PROVIDER,
  BFF_STATUS,
  LINK_SELECTORS,
  PAGE_EDIT_EMAIL,
  PAGE_VERIFICATION_SUCCESS,
  PAGE_COMPLETE_ACCOUNT,
  POLLING_DELAY,
  PAGE_DESCRIPTIONS,
  IDENTITY_PROVIDER
} from '../../constants'
import { isValidEmail } from '../../util'
import { State } from '../../common/types'
import useInterval from '../../customHooks/useInterval'
import config from '../../config'
import { editEmailForWechat } from '../../features'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const EmailVerification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [delay, setDelay] = useState(POLLING_DELAY)
  const [isLoading, setLoading] = useState(false)
  const [resendEmail, setResendEmail] = useState('')
  const [isResendLoading, setResendLoading] = useState(false)

  const { user, session } = useSelector((state: State) => state)
  const { verifyCode } = useSelector((state: State) => state.loading.effects.user)
  const { retryDelay } = session

  const defaultValues = { code: '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })
  const EMAIL = isValidEmail(user.username) ? user.username : user.email
  const submitSelector = ARKOSE_SELECTORS.EMAIL_VERIFICATION.SUBMIT_CODE
  const resendSelector = ARKOSE_SELECTORS.EMAIL_VERIFICATION.RESEND_CODE
  const editSelector = LINK_SELECTORS.EDIT_EMAIL

  useEffect(() => {
    async function delayAndCheckedVerified() {
      setDelay(POLLING_DELAY)
    }
    delayAndCheckedVerified()
  }, [])

  useInterval(() => {
    checkVerifiedLoop()
  }, delay)

  const checkVerifiedLoop = async () => {
    try {
      const result = await dispatch.user.checkVerified()
      if (result.data?.nextUrl) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT16'))
        navigate(PAGE_VERIFICATION_SUCCESS, { state: { redirectURL: result.data.nextUrl } })
      } else if (result.data?.status === BFF_STATUS.COMPLETION_REQUIRED) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT16'))
        navigate(PAGE_COMPLETE_ACCOUNT)
      } else {
        await dispatch.user.userCheckFlowURI(result.data)
      }
    } catch (error) {
      const { response } = error
      const isRetryError =
        response &&
        response.data &&
        (response.data.error === BFF_STATUS.TOO_MANY_REQUESTS ||
          response.data.error === BFF_STATUS.NOT_YET_VERIFIED)
      const retryAfter = error.response && error.response.headers['retry-after']
      if (isRetryError && retryAfter) {
        const extraDelay = parseInt(retryAfter) + 2
        setDelay(extraDelay * 1000)
      }
    }
  }

  const resendVerifyCode = async () => {
    setResendEmail('')
    setResendLoading(true)
    const options = { setResendEmail, setResendLoading, setError }
    const arkoseParams = {
      callback: dispatch.codeVerification.resendEmailCode,
      submitSelector: resendSelector,
      onHide: () => setResendLoading(false),
      options
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT56'))
    await dispatch.codeVerification.resendEmailCode({
      options: { ...options, arkoseParams }
    })
  }

  const submitVerifyEmail = async ({ code }) => {
    setLoading(true)
    const options = { setLoading, setError, code }
    const arkoseParams = {
      options,
      submitSelector: submitSelector,
      callback: dispatch.user.emailVerificationFormVerifyEmail,
      onHide: () => setLoading(false),
      formErrors: errors
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT54'))
    await dispatch.user.emailVerificationFormVerifyEmail({
      options: { ...options, arkoseParams }
    })
  }

  const adjustHeight = () => {
    if (retryDelay && resendEmail) {
      return '735px'
    }
    if (retryDelay) {
      return '720px'
    }
    if (resendEmail) {
      return '632px'
    }
    return '600px'
  }

  return (
    <MainLayout adjustHeight={adjustHeight}>
      <DefaultHeader title={t('label.verify_email_address')} />
      <S.BodyWrapper>
        <S.TextLabel id={PAGE_DESCRIPTIONS.EMAIL_VERIFICATION.CHECK_EMAIL_MESSAGE}>
          <Trans
            i18nKey="label.check_email_for_message"
            components={{ bold: <strong /> }}
            values={{ email: `<bold>${EMAIL}</bold>` }}
          />
        </S.TextLabel>
        {resendEmail ? (
          <InlineNotification
            id={1}
            hideIcon={false}
            title={t('label.email_resend_to', { EMAIL: resendEmail })}
            type="positive"
            closeButton={true}
            onClose={() => setResendEmail('')}
          />
        ) : (
          <S.ButtonWrapper>
            <Button
              appearance="ghost"
              aria-label={resendSelector}
              id={resendSelector}
              name={resendSelector}
              disabled={retryDelay}
              expanded
              loading={isResendLoading}
              onClick={resendVerifyCode}
            >
              {t('label.resend_message')}
            </Button>
          </S.ButtonWrapper>
        )}
        {(!user.identityProvider ||
          user.identityProvider === BFF_IDENTITY_PROVIDER.HPID ||
          (user.identityProvider &&
            user.identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT &&
            editEmailForWechat)) && (
          <S.ButtonWrapper>
            <Button
              appearance="ghost"
              aria-label={editSelector}
              id={editSelector}
              name={editSelector}
              disabled={retryDelay}
              expanded
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT53'))
                navigate(PAGE_EDIT_EMAIL)
              }}
            >
              {t('link.change_email_address')}
            </Button>
          </S.ButtonWrapper>
        )}
        {retryDelay && (
          <InlineNotification
            hideIcon={false}
            closeButton={false}
            title={t('label.code_in_moment_email')}
            type="informative"
          />
        )}
        <S.FormFields onSubmit={handleSubmit(submitVerifyEmail)}>
          <UITextBox
            id="code"
            aria-label="code"
            inputmode="numeric"
            label={t('form.enterSixDigit')}
            error={!!errors.code}
            helperText={errors.code && t(errors.code.message)}
            filter={(value) => value.replace(/\D/g, '')}
            autoComplete="off"
            autoFocus
            maxLength={6}
            control={control}
            rules={{
              required: 'form.err_code_empty',
              pattern: {
                value: config.verificationCodeRegex,
                message: 'form.err_invalid_email_code'
              }
            }}
          />
          <Button
            appearance="secondary"
            aria-label={submitSelector}
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading || verifyCode}
            expanded
          >
            {t('button_capitalize.verify')}
          </Button>
        </S.FormFields>
      </S.BodyWrapper>
    </MainLayout>
  )
}

export default EmailVerification
