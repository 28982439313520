import styled from '@emotion/styled'

export const TnCLabel = styled.h1`
  font-size: 32px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 36px;
`

export const TnCContentContainer = styled.div`
  height: 332px;
  margin-top: 12px;
`

export const TnCButtonContainer = styled.div`
  margin-top: 12px;
`