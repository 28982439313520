import styled from '@emotion/styled'

export const TextWrapper = styled.div`
  margin-bottom: 12px;
  height: 460px;
  margin-right: 8px;
`

export const BoldWrapper = styled.p`
  margin-bottom: 10px;
  font-weight: 600;
`

export const NormalWrapper = styled.p`
  margin-bottom: 10px;
`

export const ReqsWrapper = styled.li`
  margin: 10px 0 10px 30px;
`

export const CategoriesWrapper = styled.li`
  list-style-type: circle;
  margin: 6px 0 6px 60px;
`

export const ButtonWrapper = styled.div<{isRTL: boolean}>`
  margin: ${props => props.isRTL ? '4px' : '12px'} 0;
`
