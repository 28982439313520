import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as S from './styles'
import udlEvents from '../../../../common/udlEvents'
import useDirection from '../../../../customHooks/useDirection'

interface MultipleTnCProps {
  tncLinks: string[]
}

const MultipleTnC = ({ tncLinks }: MultipleTnCProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addSpace = ' '
  const triggerUDLEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT22'))
  }
  const { isRTL } = useDirection()

  const Tncs = () => (
    <S.UlTnCs isRTL={isRTL}>
      {tncLinks.map((link, index) => (
        <li key={`tnc-link-${index}`}>{link}</li>
      ))}
    </S.UlTnCs>
  )

  return (
    <>
      <Trans i18nKey="link.multiple_tnc_fix_1">
        <a
          id="privacy_link"
          tabIndex={0}
          href={t('link.privacy_url')}
          onClick={triggerUDLEvent}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('link.privacy_url_label')}
        </a>
        {addSpace}
      </Trans>
      {addSpace}
      <Trans i18nKey="link.multiple_tnc_fix_2" />
      <Tncs />
    </>
  )
}

export default MultipleTnC
