import styled from '@emotion/styled'

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
`

export const TextLabel = styled.p`
  word-break: break-word;
`

export const TnCWrapper = styled.div`
  margin: 20px 0;
`

export const TnCTitle = styled.p`
  font-weight: 600;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;

  :nth-of-type(3) {
    margin-top: 20px;
  }
`
