import styled from '@emotion/styled'
import tokens from '@veneer/tokens/dist/tokens'

const { typography } = tokens
const { lineHeight3 } = typography

export const UlTnCs = styled.ul<{ isRTL?: boolean }>`
  list-style-type: square;
  margin-top: 5px;
  ${(props) => (props.isRTL ? 'padding-right' : 'padding-left')}: 14px;
  line-height: ${lineHeight3};
`
