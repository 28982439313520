import { useState, useEffect } from 'react'
import Parse from 'libphonenumber-js/max'
import { LogoHp } from '@veneer/core/dist/esm/scripts/logos/hp'
import Button from '@veneer/core/dist/esm/scripts/button/button'
import IconPerson from '@veneer/core/dist/esm/scripts/icons/icon_person'
import IconBuilding from '@veneer/core/dist/esm/scripts/icons/icon_building'
import IconChevronLeft from '@veneer/core/dist/esm/scripts/icons/icon_chevron_left'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PAGE_PASSWORD, IDENTITY_PROVIDER } from '../../constants'
import { DefaultHeader, MainLayout } from '../../component'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import * as S from './styles'

import { UIIconGoogle, UIIconFacebook, UIIconWeChat, UIIconAzure, UIIconMicrosoft } from '../../component/UIIcons'

const iconComponents = {
  google: UIIconGoogle,
  facebook: UIIconFacebook,
  azure: UIIconAzure,
  wechat: UIIconWeChat,
  microsoft: UIIconMicrosoft,
  hp: LogoHp,
  user: IconPerson,
  msp: IconBuilding
}

const CheckUsername = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.user)
  const [username, setUsername] = useState(user.username)

  const clickHandler = (identity: any) => {
    return async () => {
      dispatch.udl.trackEvent(
        udlEvents.getFederatedEvent(
          'EVENT61',
          'check-username',
          `account-${identity.identityProvider}-button-click`
        )
      )

      if (identity.identityProvider === IDENTITY_PROVIDER.HPID) {
        navigate(PAGE_PASSWORD)
      } else {
        try {
          await dispatch.user.initFederated(identity.name)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  useEffect(() => {
    const parsedUsername = Parse(user.username)
    if (parsedUsername?.isValid()) setUsername(parsedUsername.formatInternational())
  }, [user.username])

  const backButton = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT59'))
    dispatch.user.handleBackLink()
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.sign_in')} />
      <>
        <S.UsernameContainer>
          <S.BackLinkContainer>
            <Button
              id="back-link"
              small={true}
              appearance="ghost"
              aria-label="back-link"
              onClick={backButton}
              leadingIcon={<IconChevronLeft color="colorHpBlue6" display="inline-block" size={24} />}
            />
          </S.BackLinkContainer>
          <S.UsernameLabel>{username}</S.UsernameLabel>
        </S.UsernameContainer>
        <S.Description>{t('label.username_desc')}</S.Description>
      </>
      <S.Identities>
        {user.identities.map((identity: any) => {
          const IconName = iconComponents[identity.icon]
          const iconProps = {
            multicolor: identity.multicolor,
            color: !identity.multicolor ? 'colorHpBlue6' : undefined,
            appearance: identity.multicolor ? 'multicolor' : undefined,
            size: 20
          }
          return (
            <Button
              id={identity.identityProvider}
              key={identity.name}
              leadingIcon={<div><IconName {...iconProps} /></div>}
              onClick={clickHandler(identity)}
              appearance="ghost"
              expanded
            >
              <span>{t(`label.accounts_${identity.identityProvider}_title`)}</span>
            </Button>
          )
        })}
      </S.Identities>
    </MainLayout>
  )
}

export default CheckUsername
