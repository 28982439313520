import React from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'

interface UILinkProps {
  id?: string
  to?: string
  bold?: boolean
  error?: string
  className?: string
  children?: any
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLAnchorElement>) => void
  disable?: boolean
  ariaLabel?: string
  handleTimerChange?: (timer: number) => void
}

const UILink = (props: UILinkProps) => {
  const { to, onClick, children, onKeyDown, disable, ariaLabel, handleTimerChange } = props

  const handleEvent = (event) => {
    if (disable) return
    if (event && event.type === 'keydown' && event.key !== 'Enter') {
      return
    }
    onClick()
  }

  const hasKeyDown = onKeyDown || handleEvent

  if (onClick) {
    return (
      <S.Link
        {...props}
        {...(props.handleTimerChange
          ? {
              onMouseEnter: () => handleTimerChange(400),
              onMouseLeave: () => handleTimerChange(100)
            }
          : {})}
        role="button"
        tabIndex={0}
        onClick={(e) => handleEvent(e)}
        onKeyDown={hasKeyDown}
        disable={disable}
        aria-label={ariaLabel}
      >
        {children}
      </S.Link>
    )
  }

  return (
    <Link tabIndex={0} to={to} {...props}>
      {children}
    </Link>
  )
}

export default UILink
