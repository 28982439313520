import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import udlEvents from '../../common/udlEvents'
import { useTheme } from '@veneer/theme'
import * as S from './styles'

const UIFooter = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const triggerUDLEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT21'))
  }
  const { mode } = useTheme()

  return (
    <S.UIFooter
      theme={mode}
      mainContent={{
        trailingArea: (
          <S.PrivacyLink
            tabIndex={0}
            theme={mode}
            id="privacy"
            className="label"
            href={t('link.privacy_url')}
            onClick={triggerUDLEvent}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('link.privacy')}
          </S.PrivacyLink>
        )
      }}
      appearance="ultraminimal"
    />
  )
}

export default UIFooter
