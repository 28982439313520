export const getRequirements = (t = () => '') => ([
  { id: 0, label: t('form.password_min_length_required') },
  {
    id: 1,
    label: t('form.password_multi_required'),
    requirements: [
      { id: 1.0, label: t('form.password_uppercase_required') },
      { id: 1.1, label: t('form.password_lowercase_required') },
      { id: 1.2, label: t('form.password_numeral_required') },
      { id: 1.3, label: t('form.password_special_char_required') }
    ]
  }
])
