import { useState } from 'react'
import Password from '@veneer/core/dist/esm/scripts/password'
import { Controller } from 'react-hook-form'
import PasswordWithReqs from './PasswordWithReqs'
import { controlledOnBlur, controlledOnChange } from '../utils'
import { css } from '@emotion/react'
import useDirection from '../../../customHooks/useDirection'

interface UIPasswordProps {
  id: string
  control: any
  rules?: any
  required?: boolean
  isWithReqs?: boolean
  maxLength?: number
  className?: string
  error?: boolean | any
  weakPasswordError?: () => void
}

const textAlignStyle = css`
  input {
    text-align: right;
  }
`

const UIPassword = (props: UIPasswordProps) => {
  const {
    id,
    control,
    rules,
    required = true,
    maxLength = 200,
    isWithReqs = false,
    weakPasswordError,
  } = props
  const [isChanged, setIsChanged] = useState(false)
  const { isRTL } = useDirection()

  const getPasswordComponent = (cProps) => {
    const handleOnChange = (value) => {
      if (!isChanged) setIsChanged(true)
      controlledOnChange({ cProps, value })
    }

    if (isWithReqs) {
      return (
        <PasswordWithReqs
          {...props}
          {...cProps}
          onChange={handleOnChange}
          onBlur={() => controlledOnBlur({ cProps, isChanged })}
          maxLength={maxLength}
          required={required}
          weakPasswordError={weakPasswordError}
          dir="auto"
          css={isRTL && textAlignStyle}
        />
      )
    }
    return (
      <Password
        {...props}
        {...cProps}
        onChange={handleOnChange}
        onBlur={() => controlledOnBlur({ cProps, isChanged })}
        maxLength={maxLength}
        required={required}
        dir="auto"
        css={isRTL && textAlignStyle}
      />
    )
  }

  return <Controller name={id} control={control} rules={rules} render={getPasswordComponent} />
}

export default UIPassword
