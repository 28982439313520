const UIIconAzure = ({ multicolor, size = 25, ...restProps }) => {
  const azureMulticolor = (
    <g id="azure-ad-multicolor">
      <path
        style={{ fill: '#0078D7' }}
        d="M16,0l-16,19l15.9,13l16-12.9L16,0z M24.5,21c-0.7,0-1.3-0.3-1.8-0.8l-4.4,3.1
        c0.3,0.4,0.4,0.9,0.4,1.4c0,1.4-1.2,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-0.6,0.2-1.2,0.6-1.7l-5.1-2.7C8.8,20.8,8.2,21,7.6,21
        c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.2-2.5,2.5-2.5c0.4,0,0.8,0.1,1.2,0.3l5.3-4.9c-0.4-0.5-0.6-1.1-0.6-1.7c0-1.6,1.3-2.8,2.9-2.8
        s2.9,1.3,2.9,2.8c0,0.6-0.2,1-0.5,1.5l4.5,5.3c0.4-0.3,1-0.5,1.5-0.5c1.4,0,2.5,1.1,2.5,2.5C27,19.8,25.8,21,24.5,21z M15.4,12.9
        v9.6l-5.5-3.3C10,19,10,18.7,10,18.5c0-0.4-0.2-0.9-0.4-1.3l5.3-4.9C15,12.3,15.3,12.9,15.4,12.9z M17.6,12.2c-0.1,0.1-1,0.7-1,0.8
        v9.5L22,19c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.6,0.2-1L17.6,12.2z"
      />
    </g>
  )
  const azureIcon = (
    <g id="azure">
      <path
        d="M16,0l-16,19l15.9,13l16-12.9L16,0z M24.5,21c-0.7,0-1.3-0.3-1.8-0.8l-4.4,3.1c0.3,0.4,0.4,0.9,0.4,1.4
        c0,1.4-1.2,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-0.6,0.2-1.2,0.6-1.7l-5.1-2.7C8.8,20.8,8.2,21,7.6,21c-1.4,0-2.5-1.1-2.5-2.5
        c0-1.4,1.2-2.5,2.5-2.5c0.4,0,0.8,0.1,1.2,0.3l5.3-4.9c-0.4-0.5-0.6-1.1-0.6-1.7c0-1.6,1.3-2.8,2.9-2.8s2.9,1.3,2.9,2.8
        c0,0.6-0.2,1-0.5,1.5l4.5,5.3c0.4-0.3,1-0.5,1.5-0.5c1.4,0,2.5,1.1,2.5,2.5C27,19.8,25.8,21,24.5,21z M15.4,12.9v9.6l-5.5-3.3
        C10,19,10,18.7,10,18.5c0-0.4-0.2-0.9-0.4-1.3l5.3-4.9C15,12.3,15.3,12.9,15.4,12.9z M17.6,12.2c-0.1,0.1-1,0.7-1,0.8v9.5L22,19
        c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.6,0.2-1L17.6,12.2z"
      />
    </g>
  )
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill={multicolor ? 'none' : 'currentColor'} {...restProps}>
      <svg focusable="false" version="1.1" viewBox="0 0 32 32">
        {multicolor ? azureMulticolor : azureIcon}
      </svg>
    </svg>
  )
}

UIIconAzure.displayName = 'UIIconAzure'

export default UIIconAzure
