import styled from '@emotion/styled'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'

export const FormFieldsWrapper = styled(Scrollbar)`
  max-height: 300px;
`

export const FieldWrapper = styled.div`
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`

export const ResendWrapper = styled.div`
  margin-top: 14px;
`

