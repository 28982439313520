import Card from '@veneer/core/dist/esm/scripts/card'
import ClipBoard from '../../pages/ClipBoard/ClipBoard'
import { useTheme } from '@veneer/theme'

import * as S from './styles'

interface ErrorCardContentProps {
  error: object
}

const ErrorCardContent = ({ error }: ErrorCardContentProps) => {
  const { mode } = useTheme()
  const hasErrorCode = error['x-request-id'] || error['correlation-id']

  const content = (
    <>
      <S.ContentWrapper theme={mode} id="clipboard-content">
        {error['x-request-id'] && (
          <div id="x-request-id">
            <p>X-request-id: </p>
            <p>{error['x-request-id']}</p>
          </div>
        )}
        {error['correlation-id'] && (
          <div id="correlation-id">
            <p>Correlation-id: </p>
            <p>{error['correlation-id']}</p>
          </div>
        )}
        {!hasErrorCode && error['sessionId'] && (
          <div id="session-id">
            <p>Session-id: </p>
            <p>{error['sessionId']}</p>
          </div>
        )}
      </S.ContentWrapper>
      <S.ClipboardLink>
        <ClipBoard clipboardAreaId="clipboard-content" />
      </S.ClipboardLink>
    </>
  )

  return (
    <S.ClipboardCardWrapper>
      <Card content={content} appearance="outlined" aria-label="errorCodes" />
    </S.ClipboardCardWrapper>
  )
}

export default ErrorCardContent
