import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Parse from 'libphonenumber-js/max'
import Button from '@veneer/core/dist/esm/scripts/button'
import { PAGE_USERNAME, ARKOSE_SELECTORS, PAGE_LEARN_MORE } from '../../constants'
import { getPasswordRequirements } from '../../util'
import { UIPassword, MainLayout, DefaultHeader, UILink } from '../../component'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const ChangePassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, loading } = useSelector((state: State) => state)
  const [username, setUsername] = useState(user.username)
  const [isLoading, setLoading] = useState(false)
  const defaultValues = { newPassword: '', confirmNewPassword: '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    control
  } = useForm({ mode: 'onBlur', defaultValues })
  const { isRTL } = useDirection()
  useEffect(() => {
    if (!user.password) {
      navigate(PAGE_USERNAME, { replace: true })
    }
  }, [navigate, user.password])

  useEffect(() => {
    const parsedUsername = Parse(user.username)
    if (parsedUsername?.isValid()) setUsername(parsedUsername.formatInternational())
  }, [user.username])

  const submitSelector = ARKOSE_SELECTORS.CHANGE_PASSWORD.SUBMIT_BUTTON

  const setWeakPasswordError = () => (
    <Trans i18nKey="label.update_app_for">
      For security reasons this password cannot be used.
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT180'))
          navigate(PAGE_LEARN_MORE)
        }}
        ariaLabel="learn-more-link"
        id="learn-more-link"
        error
      >
        Learn more
      </UILink>
    </Trans>
  )

  const submit = async ({ newPassword }) => {
    setLoading(true)
    const options = { setLoading, password: user.password, newPassword, setError, setWeakPasswordError }
    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.submitChangePassword,
      onHide: () => setLoading(false),
      formErrors: errors
    }
    await dispatch.user.submitChangePassword({ options: { ...options, arkoseParams } })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT118'))
    handleSubmit(submit)()
  }

  useEffect(() => {
    if (errors.confirmNewPassword && errors.confirmNewPassword.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT120'))
    }
    if (errors.confirmNewPassword && errors.confirmNewPassword.message === 'form.err_confirm_password_not_match') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT121'))
    }
  }, [dispatch.udl, errors.confirmNewPassword])

  return (
    <MainLayout>
      <DefaultHeader preventLineBreak={isRTL} isRTL={isRTL} title={t('label.change_password')} />
      <S.ChangePasswordText>{t('label.create_confirm_password')}</S.ChangePasswordText>
      <S.UsernameLabel>{username}</S.UsernameLabel>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <UIPassword
          aria-label="newPassword"
          id="newPassword"
          error={errors.newPassword}
          weakPasswordError={setWeakPasswordError}
          label={t('label.placeholder_new_password')}
          autoFocus
          isWithReqs
          control={control}
          rules={{ required: 'form.err_password_reset_empty' }}
        />
        <S.ConfirmPasswordInput>
          <UIPassword
            aria-label="confirmNewPassword"
            id="confirmNewPassword"
            error={!!errors.confirmNewPassword}
            label={t('label.placeholder_confirm_new_password')}
            helperText={errors.confirmNewPassword && t(errors.confirmNewPassword.message)}
            control={control}
            rules={{
              required: 'form.err_confirm_password_empty',
              validate: (value) => {
                if (!value.trim()) {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT120'))
                  return 'form.err_confirm_password_empty'
                }
                if (value && getValues('newPassword') !== value) return 'form.err_confirm_password_not_match'
                if (!getPasswordRequirements(value).isValidPassword) {
                  dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT119'))
                  return 'form.password_check_requirements'
                }
              }
            }}
          />
        </S.ConfirmPasswordInput>
        <Button
          id={submitSelector}
          name={submitSelector}
          type="submit"
          loading={isLoading || loading.effects.user.changePassword}
          expanded
        >
          {t('button_capitalize.confirm')}
        </Button>
      </form>
    </MainLayout>
  )
}

export default ChangePassword
