import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'
import { DefaultHeader, MainLayout } from '../../component'
import { PAGE_USERNAME } from '../../constants'
import udlEvents from '../../common/udlEvents'
import { State } from '../../common/types'

import * as S from './style'

function UsernameRecoverySuccess() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { username } = useSelector((state: State) => state.user)

  const handleClick = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT136'))
    navigate(PAGE_USERNAME)
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.recover_username')} />
      <S.RecoveryText>
        <Trans
          i18nKey="label.email_exist_remember"
          components={{ bold: <strong /> }}
          values={{ email_address: `<bold>${username}</bold>` }}
        >
          If {{ username }} exists in our database, you'll hear from HP there. Remember to check your spam and junk
          mail folders, where automated messages sometimes filter.
        </Trans>
      </S.RecoveryText>
      <S.ContainerRecoveryAction>
        <S.ContainerActionLink>
          <Button tabIndex={0} id="back-link" appearance="ghost" onClick={handleClick} expanded>
            {t('label.go_to_sign_in')}
          </Button>
        </S.ContainerActionLink>
      </S.ContainerRecoveryAction>
    </MainLayout>
  )
}

export default UsernameRecoverySuccess
