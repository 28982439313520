import Button from '@veneer/core/dist/esm/scripts/button'
import { LogoHp } from '@veneer/core/dist/esm/scripts/logos/hp'
import IconBuilding from '@veneer/core/dist/esm/scripts/icons/icon_building'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { UIIconGoogle, UIIconFacebook, UIIconWeChat, UIIconAzure, UIIconMicrosoft } from '../UIIcons'
import udlEvents from '../../common/udlEvents'

interface UISocialIconProps {
  button: boolean
  provider: any
  text?: string
  expanded?: boolean
}

const iconComponents = {
  google: UIIconGoogle,
  facebook: UIIconFacebook,
  azure: UIIconAzure,
  wechat: UIIconWeChat,
  microsoft: UIIconMicrosoft,
  hp: LogoHp,
  msp: IconBuilding
}

const UISocialIcon = (props: UISocialIconProps) => {
  const { provider, expanded = true } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const IconName = iconComponents[provider.icon]
  const handleClick = (provider) => handleSocialLogin(provider)
  const handleSocialLogin = async (provider) => {
    try {
      dispatch.udl.trackEvent(
        udlEvents.getFederatedEvent('EVENT61', 'federated-sign-in', `continue-with-${provider.name}-button-click`)
      )
      await dispatch.user.initFederated(provider.name)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button
      leadingIcon={<IconName multicolor={provider.multicolor} filled />}
      appearance="ghost"
      id={provider.identityProvider}
      expanded={expanded}
      onClick={() => handleClick(provider)}
      aria-label={`${provider.identityProvider}`}
      {...props}
    >
      <span>
        {t('button.social_login', {
          NAME: provider.displayName && provider.displayName
        })}
      </span>
    </Button>
  )
}

export default UISocialIcon
