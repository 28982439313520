import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@veneer/core/dist/esm/scripts/button'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { UITextBox, MainLayout, DefaultHeader } from '../../../component'
import { ARKOSE_SELECTORS, PARAM_EMAIL } from '../../../constants'
import { ILocation, State } from '../../../common/types'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import udlEvents from '../../../common/udlEvents'

import * as S from './styles'

const RecoveryVerification = () => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [showResend, setShowResend] = useState(true)
  const retryDelay = useSelector(({ session }: State) => session.retryDelay)
  const dispatch = useDispatch()
  const location = useLocation() as ILocation
  const type = location.state?.type || PARAM_EMAIL
  const defaultValues = { code: '' }
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm({ mode: 'onBlur', defaultValues })

  const submitSelector = ARKOSE_SELECTORS.MULTIFACTOR_AUTHENTICATION.SUBMIT_REC_CODE
  const resendSelector = ARKOSE_SELECTORS.MULTIFACTOR_AUTHENTICATION.RESEND_REC_CODE

  const submit = async ({ code }) => {
    setLoading(true)
    const options = { setLoading, setError, code, type }
    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.mfaVerifyRecoveryCode,
      onHide: () => setLoading(false),
      formErrors: errors
    }
    await dispatch.user.mfaVerifyRecoveryCode({
      options: { ...options, arkoseParams }
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT165'))
    handleSubmit(submit)()
  }

  const resend = async () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT163'))
    setShowResend(false)
    const options = { setShowResend, type }
    const arkoseParams = {
      options,
      submitSelector: resendSelector,
      callback: dispatch.user.mfaResendRecoveryCode,
      onHide: () => setShowResend(true),
      formErrors: errors
    }
    await dispatch.user.mfaResendRecoveryCode({
      options: { ...options, arkoseParams }
    })
  }

  const handleLinkOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      resend()
    }
  }

  useEffect(() => {
    if (errors.code && errors.code.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT164'))
    }
  }, [dispatch.udl, errors.code])

  return (
    <MainLayout>
      <DefaultHeader title={t('label.recover_account')} />
      <p>{t('label.received_code_mfa')}</p>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <S.Wrapper>
          <UITextBox
            id="code"
            aria-label="code"
            label={t('label.placeholder_enter')}
            error={!!errors.code}
            helperText={errors.code && t(errors.code.message)}
            autoFocus
            control={control}
            rules={{ required: 'form.err_recovery_code' }}
          />
          <S.Wrapper>
            <Button
              id={submitSelector}
              name={submitSelector}
              type="submit"
              aria-label="next"
              loading={isLoading}
              expanded
            >
              {t('button_capitalize.next')}
            </Button>
          </S.Wrapper>
        </S.Wrapper>
      </form>

      <S.LinkWrapper>
        {showResend ? (
          <Trans i18nKey="link.no_mfa_code">
            Didn't receive a code?
            <Button
              id={resendSelector}
              onClick={resend}
              onKeyDown={handleLinkOnKeyDown}
              appearance="ghost"
              aria-label="resend"
              disabled={retryDelay}
            >
              Resend recovery code.
            </Button>
          </Trans>
        ) : (
          <S.LoadingContainer>
            <ProgressIndicator />
          </S.LoadingContainer>
        )}
      </S.LinkWrapper>
      {showResend && retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.code_in_moment_recovery')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default RecoveryVerification
