import styled from '@emotion/styled'

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  margin-bottom: 12px;
`

export const TextLabel = styled.p`
  text-align: left;
  margin-bottom: 20px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`

export const FormFields = styled.form`
  margin-top: auto;

  > div > div {
    margin-bottom: 20px;
  }
`
