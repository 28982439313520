import styled from '@emotion/styled'

export const SocialWrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 5px;
  }
`

export const SocialButtonWrapper = styled.div`
  & button {
    justify-content: flex-start;
  }
`
