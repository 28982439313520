import styled from '@emotion/styled'

export const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
`
export const BackLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
`
export const UsernameLabel = styled.p`
  display: flex;
  align-items: center;
  padding-top: 2px;
`
export const Description = styled.p`
  margin-bottom: 20px;
`
export const Identities = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    justify-content: flex-start;
  }
  
  > *:not(:last-child) {
    margin-bottom: 5px;
  }
`