import Parse from 'libphonenumber-js/max'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button/button'
import IconChevronLeft from '@veneer/core/dist/esm/scripts/icons/icon_chevron_left'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import { DefaultHeader, MainLayout, PasswordForm } from '../../component'
import { PAGE_USERNAME } from '../../constants'
import * as S from './style'

const Password = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user } = useSelector((state: State) => state)
  const navigate = useNavigate()
  const username = Parse(user.username)?.isValid() ? Parse(user.username).formatInternational() : user.username

  const backLink = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT57'))
    navigate(PAGE_USERNAME)  
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.sign_in')} />
      <S.BodyContainer>
        <S.UsernameContainer>
          <S.BackLinkContainer>
            <Button
              id="back-link"
              small={true}
              appearance="ghost"
              onClick={backLink}
              aria-label="back-link"
              leadingIcon={<IconChevronLeft color="colorHpBlue6" display="inline-block" size={24} />}
            />
          </S.BackLinkContainer>
          <S.UsernameLabel>{username}</S.UsernameLabel>
        </S.UsernameContainer>
        <PasswordForm />
      </S.BodyContainer>
    </MainLayout>
  )
}

export default Password
