import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification/inline_notification'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootModel } from '../../models'
import udlEvents from '../../common/udlEvents'
import { useEffect } from 'react'

export default function UpdateAppNotification() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { client } = useSelector((state: RootModel) => state.session)
  const APP_NAME = client && client.displayName

  useEffect(() => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT140'))
  }, [dispatch.udl])

  return (
    <InlineNotification
      closeButton={false}
      title={t('label.update_app_title', { APP_NAME })}
      description={t('label.update_app_desc', { APP_NAME })}
      type="warning"
    />
  )
}
