import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MainLayout, DefaultHeader, UISpinner } from '../../component'
import {
  BFF_STATUS,
  PAGE_SIGN_UP,
  PARAM_FIRSTNAME,
  PARAM_EMAIL,
  PARAM_LASTNAME,
  SOCIAL_LOGIN_ERROR,
  PAGE_USERNAME,
  PAGE_AUTH,
  USER_INTERACTION_TIMEOUT,
  PAGE_REDIRECT
} from '../../constants'

import * as S from './styles'

const SocialLogin = () => {
  const [progress, setProgress] = useState(0)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function getParameters() {
    const query = window.location.search.substring(1)
    return query.split('&').reduce(function (prev, curr) {
      const p = curr.split('=')
      prev[p[0]] = p[1]
      return prev
    }, {})
  }

  useEffect(() => {
    dispatch.user.update({ expiredInteraction: false })
    const timer = window.setTimeout(() => {
      dispatch.user.update({ expiredInteraction: true })
    }, USER_INTERACTION_TIMEOUT)

    return () => clearTimeout(timer)
  }, [dispatch.user])

  useEffect(() => {
    const socialPayload = getParameters()
    async function submitExternalIDP() {
      try {
        const { error }: any = socialPayload
        if (error === SOCIAL_LOGIN_ERROR) {
          setProgress(100)
          navigate(PAGE_USERNAME)
          return
        }
        setProgress(50)
        const res = await dispatch.user.authorizeFederated(socialPayload)
        const { status, sessionIdentityResource } = res
        if (sessionIdentityResource) {
          dispatch.session.updateUserSession(sessionIdentityResource)
        }
        if (status === BFF_STATUS.SUCCESS) {
          setProgress(100)
          if (res.nextUrl) {
            navigate(PAGE_REDIRECT, { state: { nextUrl: res.nextUrl } })
          } else {
            console.error('Social login redirect failed')
            dispatch.error.goError()
          }
        } else if (status === BFF_STATUS.MFA_REQUIRED) {
          setProgress(100)
          dispatch.user.update({ availableMfaMethods: res.availableMfaMethods })
          navigate(PAGE_AUTH)
        } else {
          await dispatch.user.userCheckFlowURI(res)
        }
      } catch (errorData) {
        if (errorData.error === BFF_STATUS.PROHIBITED_EMAIL_DOMAIN) {
          setProgress(100)
          navigate(PAGE_USERNAME, { state: { isProhibitedEmail: true } })
        } else {
          if (errorData.status === BFF_STATUS.NO_EXTERNAL_IDP_ACCOUNT) {
            dispatch.user.update({
              [PARAM_EMAIL]: errorData.email,
              [PARAM_LASTNAME]: errorData.familyName,
              [PARAM_FIRSTNAME]: errorData.givenName,
              identityProvider: errorData.provider,
              isExternalIdentity: true
            })
            setProgress(100)
            navigate(PAGE_SIGN_UP, { state: { isExternalIdentity: true, provider: errorData.provider } })
          } else {
            setProgress(100)
            dispatch.error.goError(errorData)
          }
        }
      }
    }
    submitExternalIDP()
  }, [dispatch.error, dispatch.session, dispatch.user, navigate])

  return (
    <MainLayout>
      <DefaultHeader />
      <S.LoaderContainer>
        <UISpinner value={progress} />
        <S.LabelContainer>{t('label.wait_msg')}</S.LabelContainer>
      </S.LoaderContainer>
    </MainLayout>
  )
}

export default SocialLogin
