import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Parse from 'libphonenumber-js/max'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { MainLayout, DefaultHeader, UITextBox } from '../../component'
import { State } from '../../common/types'
import { PAGE_USERNAME, ARKOSE_SELECTORS, OTP_BUTTON_LABEL } from '../../constants'
import { isValidEmail } from '../../util'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const OTPSignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.user)
  const { retryDelay, arkoseTooManyRequests, otpLoginButton } = useSelector((state: State) => state.session)
  const { otpResendCode, otpLoginVerifyCode } = useSelector((state: State) => state.loading.effects.user)
  const [isLoading, setLoading] = useState(false)
  const [isResendLoading, setResendLoading] = useState(false)
  const username = Parse(user.username)?.isValid() ? Parse(user.username).formatInternational() : user.username
  const hasMultipleAccounts = user.identities && user.identities.length > 1 ? true : false
  const submitSelector = ARKOSE_SELECTORS.SIGN_IN.OTP
  const resendCodeID = ARKOSE_SELECTORS.SIGN_IN.RESEND_PASSCODE

  const defaultValues = { passcode: '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors
  } = useForm({
    mode: 'onBlur',
    defaultValues
  })

  const submit = async (data) => {
    if (otpLoginButton === OTP_BUTTON_LABEL.USE_ONE_TIME_PASSCODE) {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT197'))
    }
    if (otpLoginButton === OTP_BUTTON_LABEL.SEND_SIGN_IN_CODE) {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT200'))
    }

    const isEmail = isValidEmail(username, true).result
    const { passcode } = data

    const options = { username: user.username, passcode, setError, setLoading, isEmail, otpLoginButton }

    const arkoseParams = {
      options,
      callback: dispatch.user.otpLoginVerifyCode,
      submitSelector: 'VERIFY_CODE',
      onHide: () => setLoading(false),
      formErrors: errors
    }
    setLoading(true)
    await dispatch.user.otpLoginVerifyCode({ options: { ...options, arkoseParams } })
  }

  const handleResendPasscode = async () => {
    setResendLoading(true)
    const isEmailValid = isValidEmail(username, true)
    const options = { setResendLoading, setError, username, isEmail: isEmailValid.result }
    const arkoseParams = {
      options,
      resendCodeID,
      callback: (dispatch as any).user.otpResendCode,
      onHide: () => setResendLoading(false),
      formErrors: errors
    }

    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT195'))

    await (dispatch as any).user.otpResendCode({
      options: { ...options, arkoseParams }
    })
  }

  useEffect(() => {
    if (arkoseTooManyRequests) {
      setError('passcode', { message: t('form.err_send_email_code') })
    }
  }, [arkoseTooManyRequests, setError, t])

  const handleBackLink = () => {
    navigate(PAGE_USERNAME)
  }

  const handleMessageDescription = () =>
    hasMultipleAccounts ? (
      <Trans i18nkey="label.check_multiple_accounts">
        You are logging in with your <strong>local account</strong>. Check <strong>{{ EMAILORPHONE: username }}</strong>{' '}
        for a message from HP.
      </Trans>
    ) : (
      <Trans
        i18nKey="label.check_email_phone"
        components={{ bold: <strong /> }}
        values={{ EMAILORPHONE: `<bold>${username}</bold>` }}
      >
        Check {{ username }} for a message from HP.
      </Trans>
    )

  return (
    <>
      <MainLayout>
        <DefaultHeader title={t('label.sign_in')} />
        <form onSubmit={handleSubmit(submit)}>
          <S.MessageContainer>{handleMessageDescription()}</S.MessageContainer>
          <S.InputContainer>
            <UITextBox
              id="passcode"
              aria-label="passcode"
              inputmode="numeric"
              maxLength={6}
              filter={(value: string) => value.replace(/\D/g, '')}
              label={t('form.enterPasscode')}
              error={!!errors.passcode}
              helperText={errors.passcode && t(errors.passcode.message)}
              control={control}
              rules={{ required: 'form.enterPasscode' }}
              clearErrors={clearErrors}
              autoFocus
            />
          </S.InputContainer>
          <S.ButtonContainer>
            <Button
              id={submitSelector}
              aria-label="submit-button"
              name={submitSelector}
              type="submit"
              loading={isLoading || otpLoginVerifyCode}
              expanded
              disabled={!username}
            >
              {t('button.sign_in_2')}
            </Button>
          </S.ButtonContainer>
          <S.ButtonContainer>
            <Button
              tabIndex={0}
              id={resendCodeID}
              aria-label={resendCodeID}
              appearance="ghost"
              onClick={handleResendPasscode}
              loading={isResendLoading || otpResendCode}
              disabled={retryDelay || isLoading || !username}
              expanded
            >
              {t('label.request_new_message')}
            </Button>
          </S.ButtonContainer>
          {retryDelay && (
            <S.ElementsContainer>
              <InlineNotification
                id="passcode-message-resent"
                aria-label="passcode-message-resent"
                hideIcon={false}
                closeButton={false}
                title={t('label.resend_passcode_message')}
                type="informative"
              />
            </S.ElementsContainer>
          )}
          <S.ElementsContainer>
            <Button id="back-link" appearance="ghost" onClick={handleBackLink} aria-label="back-link" expanded>
              {t('link.back_link')}
            </Button>
          </S.ElementsContainer>
        </form>
      </MainLayout>
    </>
  )
}

export default OTPSignIn
