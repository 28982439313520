import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setCaptchaBypassKey, isPathnameOutsideSession } from '../../util'
import {
  PARAM_CSRF_TOKEN,
  PARAM_FLOW_TOKEN,
  PARAM_CAPTCHA_BYPASS_KEY,
  APP_RESET,
  PARAM_FLOW_ID
} from '../../constants'

const SessionChecker = ({ children }) => {
  const query = new URLSearchParams(useLocation().search)
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    [PARAM_CSRF_TOKEN]: csrfToken,
    [PARAM_FLOW_TOKEN]: flowToken,
    locale
  } = useSelector((state) => state.user)

  useEffect(() => {
    dispatch.user.changeLanguage()
    // eslint-disable-next-line
  }, [locale])

  useLayoutEffect(() => {
    const isNewSession =
      (query.has(PARAM_CSRF_TOKEN) && query.get(PARAM_CSRF_TOKEN) !== csrfToken) ||
      (query.has(PARAM_FLOW_TOKEN) && query.get(PARAM_FLOW_TOKEN) !== flowToken) ||
      (query.has(PARAM_FLOW_ID) && query.get(PARAM_FLOW_ID) !== flowToken)

    if (isNewSession) {
      dispatch({ type: APP_RESET })
    }
    // eslint-disable-next-line
  }, [])

  // Set configs in pages without session
  useEffect(() => {
    if (isPathnameOutsideSession(location.pathname)) {
      // handle bypassKey to use in cases when arkose is displayed
      const arkoseBypassKey = query.has(PARAM_CAPTCHA_BYPASS_KEY) && query.get(PARAM_CAPTCHA_BYPASS_KEY)
      if (arkoseBypassKey) setCaptchaBypassKey(arkoseBypassKey)
    }
    // eslint-disable-next-line
  }, [])

  return children
}

export default SessionChecker
