import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import Button from '@veneer/core/dist/esm/scripts/button'
import IconWarning from '@veneer/core/dist/esm/scripts/icons/icon_warning'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import moment from 'moment'
import config from '../../config'
import * as S from './styles'
import udlEvents from '../../common/udlEvents'

const SessionController = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { displayName } = useSelector(({ session }) => session.client)
  const startTime = useSelector(({ session }) => session.startTime)
  const warned = useSelector(({ session }) => session.warned)
  const [time, unit] = config.session_timeout

  const [endTime, setEndTime] = useState(moment(startTime).add(time - 1, unit))
  const [show, toggleShow] = useState(false)
  const [intervalID, setIntervalID] = useState()

  const [confirmButtonLabel, setConfirmButtonLabel] = useState()
  const [titleText, setTitleText] = useState(t('label.modal_session_expiring_title'))
  const [closeModal, setCloseModal] = useState()
  const [modalMessage, setModalMessage] = useState(<></>)
  const [clear, setClear] = useState(false)

  useLayoutEffect(() => {
    if (!startTime) {
      const start = moment()
      dispatch.session.update({ startTime: start })
    }
  }, [dispatch.session, startTime])

  useLayoutEffect(() => {
    if (startTime) {
      setEndTime(moment(startTime).add(time - 1, unit))
    }
  }, [startTime, time, unit])

  useEffect(() => {
    const remainingTime = endTime.diff(moment())
    if (remainingTime && endTime && !warned) {
      setTimeout(sessionExpiring, remainingTime - 300000)
    }
    // eslint-disable-next-line
  }, [endTime, warned])

  useEffect(() => {
    if (clear) {
      clearInterval(intervalID)
    }
  }, [clear, intervalID])

  useEffect(() => {
    const remainingTime = endTime.diff(moment())
    if (warned) {
      clearInterval(intervalID)
      setTimeout(sessionExpired, remainingTime)
    }
    // eslint-disable-next-line
  }, [warned])

  const getModalTitle = () => (
    <S.TitleWrapper>
      <>
        <IconWarning name="warning" css={{ marginRight: '1em' }} size={48} />
        {titleText}
      </>
    </S.TitleWrapper>
  )

  const closeWarning = () => {
    toggleShow(false)
  }

  const redirectToApp = () => {
    dispatch.session.sendUIEvents('session-timeout-expired')
    dispatch.user.redirectToAPP()
    setTimeout(() => {
      toggleShow(false)
    }, 500)
  }

  const sessionExpiring = () => {
    const remainingTime = endTime.diff(moment(), 'seconds')
    dispatch.session.update({ warned: true })
    dispatch.session.sendUIEvents('session-timeout-displayed')
    setConfirmButtonLabel(t('button_capitalize.close'))
    setModalMessage(<Trans i18nKey="label.modal_session_expiring_msg">{timeString(remainingTime)}</Trans>)
    toggleShow(true)
    setCloseModal(() => closeWarning as any)
    const interval = setInterval(sessionTimerMonitor, 1000)
    setIntervalID(interval as any)
  }

  const timeString = (currentTime) => {
    const minutes = Math.floor(currentTime / 60)
    const seconds = (currentTime % 60) % 60
    return `${minutes < 1 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }

  const sessionExpired = () => {
    toggleShow(true)
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT23'))
    setModalMessage(
      <S.MessageWrapper>
        <div>{t('label.modal_session_expired_msg', { APPNAME: displayName })}</div>
        <S.LoaderWrapper>
          <ProgressIndicator />
        </S.LoaderWrapper>
      </S.MessageWrapper>
    )
    setConfirmButtonLabel(t('label.recovery_skip_wait'))
    setTitleText(t('label.modal_session_expired_title'))
    setCloseModal(() => redirectToApp as any)
    setTimeout(() => {
      redirectToApp()
    }, 5000)
  }

  const sessionTimerMonitor = () => {
    const remainingTime = endTime.diff(moment(), 'seconds')
    if (remainingTime > 0) {
      setModalMessage(
        <>
          <Trans i18nKey="label.modal_session_expiring_msg">{timeString(remainingTime)}</Trans>
        </>
      )
    } else {
      setClear(true)
    }
  }

  return (
    <div>
      <Modal
        aria-label="session-modal"
        title={getModalTitle() as any}
        show={show}
        onClose={closeModal}
        align="center"
        portal={false}
        footer={
          <div>
            <Button onClick={closeModal}>{confirmButtonLabel}</Button>
          </div>
        }
      >
        <span>{modalMessage}</span>
      </Modal>
    </div>
  )
}

export default SessionController
