const UIIconGoogle = ({ multicolor, size = 25, ...restProps }) => {
  const multiColorIcon = (
    <g id="google-multicolor" aria-label="google-logo">
      <path
        fill="#3E82F1"
        d="M31.7,16c0-1-0.1-2-0.3-3h-1.2h-4.8h-9V19h9.1c-0.6,2.1-1.8,3.9-3.5,5.1l4.7,3.7C29.8,25,31.7,20.7,31.7,16z"
      />
      <path
        fill="#E74133"
        d="M16.5,6.5c2.3,0,4.4,0.8,6,2.2l4.4-4.4C24.1,1.6,20.4,0,16.3,0C10.2,0,4.7,3.6,2,8.9l5.4,4.1C8.8,9.2,12.4,6.5,16.5,6.5z"
      />
      <path
        fill="#F9BB00"
        d="M2.1,23.3l5.2-4.1c-0.3-0.9-0.5-2-0.5-3c0-1.2,0.2-2.3,0.6-3.3L2,8.9C0.9,11,0.3,13.4,0.3,16C0.3,18.6,1,21.1,2.1,23.3z"
      />
      <path
        fill="#32A753"
        d="M7.3,19.2l-5.2,4.1c2.7,5.1,8.1,8.7,14.2,8.7c4,0,7.6-1.6,10.3-4.1l-4.7-3.7c-1.5,1.1-3.4,1.8-5.4,1.8C12.3,25.9,8.6,23.1,7.3,19.2z"
      />
    </g>
  )
  const google = (
    <g id="google">
      <path
        d="M31.4,13h-1.2h-4.8h-9V19h9.1c-0.6,2.1-1.8,3.9-3.5,5.1c-1.5,1.1-3.4,1.8-5.4,1.8c-4.2,0-7.9-2.8-9.2-6.7
        c-0.3-0.9-0.5-2-0.5-3c0-1.2,0.2-2.3,0.6-3.3c1.4-3.8,5-6.5,9.1-6.5c2.3,0,4.4,0.8,6,2.2l4.4-4.4C24.1,1.6,20.4,0,16.3,0
        C10.2,0,4.7,3.6,2,8.9C0.9,11,0.3,13.4,0.3,16c0,2.6,0.7,5.1,1.8,7.3c2.7,5.1,8.1,8.7,14.2,8.7c4,0,7.6-1.6,10.3-4.1
        c3.1-2.9,5-7.2,5-11.9C31.7,15,31.6,14,31.4,13z"
      />
    </g>
  )
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill={multicolor ? 'none' : 'currentColor'} {...restProps}>
      <svg focusable="false" version="1.1">
        {multicolor ? multiColorIcon : google}
      </svg>
    </svg>
  )
}

UIIconGoogle.displayName = 'UIIconGoogle'

export default UIIconGoogle
