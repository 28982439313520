import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tokens from '@veneer/tokens/dist/tokens'

const { color } = tokens
const { gray3 } = color

interface IProps {
  disable: boolean
}

export const Link = styled.a`
  ${(props: IProps) =>
    props.disable &&
    css`
      color: ${gray3};
    `}
  :hover {
    ${(props: IProps) =>
      props.disable
        ? css`
            color: ${gray3};
            text-decoration: none;
            cursor: default;
          `
        : css`
            text-decoration: underline;
          `}
  }
`
