import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { validateEmail, shouldBackToApp } from '../../util'
import { ARKOSE_SELECTORS, PARAM_TARGET_FORGET_PWD, PAGE_DESCRIPTIONS } from '../../constants'
import { DefaultHeader, MainLayout, UITextBox } from '../../component'
import { blockSignInNativeHp } from '../../features'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const UsernameRecovery = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user, loading, session } = useSelector((state: State) => state)
  const username = user.username
  const retryDelay = session.retryDelay
  const [isLoading, setLoading] = useState(false)
  const defaultValues = {
    recoveryInput: username
  }

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onSubmit', defaultValues })

  const submitSelector = ARKOSE_SELECTORS.RECOVERY.USERNAME_RECOVERY
  const data = { target: PARAM_TARGET_FORGET_PWD }
  const renderLabel = () => (shouldBackToApp(data, user) ? 'link.not_now' : 'link.back_link')

  const submit = async ({ recoveryInput }) => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT101'))
    setLoading(true)
    const options = { setLoading, setError, recoveryInput }
    const arkoseParams = {
      callback: (dispatch as any).user.handleUsernameRecovery,
      submitSelector,
      formErrors: errors,
      onHide: () => setLoading(false),
      options
    }
    await (dispatch as any).user.handleUsernameRecovery({ options: { ...options, arkoseParams } })
  }

  const handleClick = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT102'))
    dispatch.user.handleBackLink(data)
  }

  useEffect(() => {
    if (errors.recoveryInput) {
      if (errors.recoveryInput.type === 'required') {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT104'))
      } else if (
        errors.recoveryInput.type === 'validate' &&
        errors.recoveryInput.message === 'form.err_invalid_hp_email'
      ) {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT105'))
      }
    }
  }, [dispatch.udl, errors.recoveryInput])

  return (
    <MainLayout>
      <DefaultHeader title={t('label.recover_username')} />
      <form onSubmit={handleSubmit(submit)}>
        <S.UsernameRecoveryText id={PAGE_DESCRIPTIONS.USERNAME_RECOVERY.USERNAME_RECOVERY_DESC}>
          {t('label.recovery_username_tip')}
        </S.UsernameRecoveryText>
        <UITextBox
          id="recoveryInput"
          aria-label="recoveryInput"
          label={t('label.recovery_input_placeholder')}
          error={!!errors.recoveryInput}
          helperText={errors.recoveryInput && t(errors.recoveryInput.message)}
          autoCapitalize="none"
          autoComplete="off"
          autoFocus
          control={control}
          rules={{
            required: 'form.err_email_empty',
            validate: (value) => validateEmail(value, blockSignInNativeHp)
          }}
        />
        <S.ButtonContainer>
          <Button
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading || loading.effects.user.handleUsernameRecovery}
            expanded
            disabled={retryDelay}
          >
            {t('button_capitalize.next')}
          </Button>
        </S.ButtonContainer>
        <S.BackLinkContainer>
          <Button tabIndex={0} id="back-link" appearance="ghost" onClick={handleClick} expanded>
            {t(renderLabel())}
          </Button>
        </S.BackLinkContainer>
      </form>
      {retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.request_recovery')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default UsernameRecovery
