import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import * as S from './styles'

const UISpinner = () => {
  return (
    <S.UISpinnerContainer>
      <ProgressIndicator />
    </S.UISpinnerContainer>
  )
}

export default UISpinner
