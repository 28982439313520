import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from '@veneer/core/dist/esm/scripts/tabs'
import Button from '@veneer/core/dist/esm/scripts/button'
import Card from '@veneer/core/dist/esm/scripts/card'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'
import IconDocument  from '@veneer/core/dist/scripts/icons/icon_document'
import * as S from './styles'

interface TnCContentProps {
  tncs: any[],
  activeTabIndex?: any,
  setTab?: { () : void},
}

const TnCContent = ({ tncs, activeTabIndex = 0 }: TnCContentProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(activeTabIndex)

  const getContent = (tncContent) => {
    return(
      <Scrollbar >
        <S.TnCContentContainer >
          <div dangerouslySetInnerHTML={{ __html: tncContent }} />
          <S.TnCButtonContainer>
            <Button aria-label="Secondary" appearance="secondary" leadingIcon={<IconDocument />} onClick={() => window.print()} expanded>
              {t('button_capitalize.print')}
            </Button>
          </S.TnCButtonContainer>
        </S.TnCContentContainer>
      </Scrollbar>
    )
  }

  const tabs = tncs.map(
    ({ link, displayTitle, tncId, content }, index) => {
      const label = link ? link.text : displayTitle || tncId
        return ({
          id: index,
          label,
          content: (
            <div>
              <div tabIndex={0}>
                {getContent(content)}
              </div>
            </div>
          )
        })
    }
  )

  if (tncs.length === 0) return null

  const singleTnc = () => (
    tncs.map(
      ({ link, displayTitle, tncId, content }) => {
        const label = link ? link.text : displayTitle || tncId
        return (
          <div>
            <div>
              <S.TnCLabel>{label}</S.TnCLabel>
            </div>
            {getContent(content)}
          </div>
        )
      }
    )
  )

  const content = () => (
    <>
      {tncs.length === 1 ? (
        singleTnc()
      ) : (
        <Tabs
          controlId="contained"
          onChangeTab={setTab}
          selectedTabId={tab}
          tabs={tabs}
        />
      )}
    </>
  )

  return (
    <Card content={content()} />
  )
}

export default TnCContent