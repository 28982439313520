import UICountryCode from '../UICountryCode'

interface ICountryNumbers {
  isoCode: string,
  countryCode: string
}

interface ICountryLabels {
  label: string,
  value: string
}

export const filterCountryNumbers = (countryNumbers: ICountryNumbers[]) => {
  const filteredCountryNumbers = {}
  if (!countryNumbers || typeof countryNumbers.forEach !== 'function') {
    return {}
  }
  countryNumbers.forEach((cn) => { filteredCountryNumbers[cn.isoCode] = cn.countryCode })
  return filteredCountryNumbers
}

export const filterCountryLabels = (countryLabels: ICountryLabels[]) => {
  const filteredCountryLabels = {}
  if (!countryLabels || typeof countryLabels.forEach !== 'function') {
    return {}
  }
  countryLabels.forEach((cl) => { filteredCountryLabels[cl.value] = cl.label })
  return filteredCountryLabels
}

export const generateCountryOptions = (countryLabels, countryNumbers) => {
  if (!countryLabels || !countryNumbers) return []
  return Object.keys(countryNumbers)
    .map((cn) => ({
      value: cn,
      label: (<UICountryCode countryNumber={countryNumbers[cn]} countryName={countryLabels[cn]} />),
      searchName: `+${countryNumbers[cn]} ${countryLabels[cn]}`
    }))
}
