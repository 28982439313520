import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import udlEvents from '../../../../common/udlEvents'
import * as S from './styles'

// Note: Was identified that this component with "Trans" usage is breaking IE11 (PE-24880)
const Privacy = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addSpace = ' '
  const triggerUDLEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT22'))
  }

  const PrivacyLink = () => {
    return (
      <a
        id="privacy_link"
        tabIndex={0}
        href={t('link.privacy_url')}
        onClick={triggerUDLEvent}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('link.privacy_url_label')}
      </a>
    )
  }

  return (
    <S.PrivacyWrapper>
      <Trans i18nKey="link.agree_privacy_fix">
        {addSpace}
        {PrivacyLink()}
      </Trans>
    </S.PrivacyWrapper>
  )
}
export default Privacy
