import styled from '@emotion/styled'

export const PasswordFormFieldContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`
export const ForgotPasswordContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`
export const FooterWrapper = styled.div`
  width: 100%;
`