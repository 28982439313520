import { BFF_STATUS, PAGE_COOKIE_ENABLEMENT, PAGE_ERROR } from '../constants'
import { isMissingCookie, isSessionExpired } from '../util'
import { history } from '../store'

const error = {
  state: {
    error: '',
    statusCode: null,
    errorDescription: '',
    'x-request-id': '',
    'correlation-id': '',
    message: ''
  },
  reducers: {
    update(state, data) {
      return { ...state, ...data }
    }
  },

  effects: (dispatch) => ({
    async goError(data = {}, { session }) {
      if (data.errorDescription === BFF_STATUS.ARKOSE_ERROR_DESCRIPTION) {
        data.error = BFF_STATUS.CAPTCHA_REQUIRED
      }

      if (session.sessionId) {
        data.sessionId = session.sessionId
      }

      if (!data.error) {
        data.error = 'Network connection issue'

        if (!data.message) {
          data.message = 'errors.err_page_request_timed_out'
        }
      }

      dispatch.error.update(data)
      // Detect if there is no cookie available and redirect to PAGE_COOKIE_ENABLEMENT
      if (isMissingCookie(data)) {
        history.push(PAGE_COOKIE_ENABLEMENT)
      } else if (isSessionExpired(data)) {
        history.push(PAGE_COOKIE_ENABLEMENT, { sessionExpired: true })
      } else {
        history.push(PAGE_ERROR)
      }
    }
  })
}

export default error
