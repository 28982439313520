import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { UILink } from '../../component'
import udlEvents from '../../common/udlEvents'

interface ClipBoardProps {
  clipboardAreaId: string
}

const ClipBoard = ({ clipboardAreaId }: ClipBoardProps) => {
  const isClipboardAvailable = navigator.clipboard
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClick = async () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT137'))
    try {
      const clipboardElements = []
      const clipboardElement: HTMLElement = document.querySelector(`#${clipboardAreaId}`)
      clipboardElement.childNodes.forEach((child) => {
        clipboardElements.push(child.textContent)
      })
      const clipboardText = clipboardElements.join('\n')
      await navigator.clipboard.writeText(clipboardText)
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error)
    }
  }

  if (!isClipboardAvailable) return null
  return <UILink onClick={handleClick}>{t('link.copy_ids_clipboard')}</UILink>
}
export default ClipBoard
