import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    width: 100%; // IE Fix
    text-align: center;
    margin-bottom: 20px;
  }
`
