import { useState } from 'react'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { validateEmail } from '../../util'
import { ARKOSE_SELECTORS } from '../../constants'
import { UITextBox, MainLayout, DefaultHeader } from '../../component'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const EditEmail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const { user, loading } = useSelector((state: State) => state)
  const retryDelay = useSelector(({ session }: State) => session.retryDelay)
  const defaultValues = { emailInput: user.email }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })

  const submitSelector = ARKOSE_SELECTORS.EDIT_EMAIL.EDIT_EMAIL_BUTTON
  const identityProvider = user.identityProvider

  const submitEmail = async ({ emailInput }) => {
    setLoading(true)
    const options = { setLoading, setError, email: emailInput, identityProvider }
    const arkoseParams = {
      options,
      callback: dispatch.user.changePrimaryEmail,
      onHide: () => setLoading(false),
      submitSelector,
      formErrors: errors
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT122'))
    await dispatch.user.changePrimaryEmail({ options: { ...options, arkoseParams } })
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('link.change_email_address')} />
      <S.Description>{t('label.enter_email_address')}</S.Description>
      <form onSubmit={handleSubmit(submitEmail)}>
        <S.InputWrapper>
          <UITextBox
            id="emailInput"
            aria-label="email"
            label={t('label.placeholder_email')}
            error={!!errors.emailInput}
            helperText={errors.emailInput && t(errors.emailInput.message)}
            autoCapitalize="none"
            autoComplete="off"
            autoFocus
            control={control}
            rules={{
              required: 'form.err_email_empty',
              validate: (value) => validateEmail(value, true, user.email)
            }}
          />
        </S.InputWrapper>
        <S.ButtonWrapper>
          <Button
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading || loading.effects.user.changePrimaryEmail}
            disabled={retryDelay}
            expanded
          >
            {t('label.send_message')}
          </Button>
        </S.ButtonWrapper>
      </form>
      <S.ButtonWrapper>
        <Button
          tabIndex={0}
          id="back-link"
          appearance="ghost"
          onClick={() => {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT123'))
            navigate(-1)
          }}
          expanded
        >
          {t('link.back_link')}
        </Button>
      </S.ButtonWrapper>
      {retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.code_in_moment_email')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default EditEmail
