import config from '../config'

// Get the URL to the application, including CDN, at runtime
function __resolveCdn() {
  // By default, we will use the application prefix (generally /login3) to load the
  // translations from the UI frontend express server.
  let computedLoadPath = config.applicationPrefix

  /*
   Now we'll search the document running the application for a <script>
   element, which source points to the main JS bundle created by webpack.
   That source matches the pattern "<@= staticDomain@><app prefix>/static/js/main.<hash>.js"
   where `<@= staticDomain@>` is the CDN origin address.
   */
  const pattern = config.applicationPrefix + '/static/js/main'
  const allScripts = document.querySelectorAll('script')
  const nbrScripts = allScripts.length
  let scriptIndex = 0
  // Let's iterate through all scripts
  while (scriptIndex < nbrScripts) {
    const script = allScripts.item(scriptIndex)
    if (script && script.src && script.src.length > 0 && script.src.includes(pattern)) {
      // We found the main bundle script
      try {
        /*
          We will load the translation through the same origin server than the main
          JS bundle, i.e. the CDN. We use the URL object to do that.
        */
        const mainScriptUrl = new URL(script.src)
        computedLoadPath = mainScriptUrl.origin + config.applicationPrefix
      } catch (badUrlError) {
        /*
          will occur if the script addressa is relative, i.e. same as index.ejs page,
          i.e. running without a CDN. In that case we use a relative address for the
          locale loadup as well.
        */
      }
      // There should be only one main JS bundle created by webpack, so once found,
      // we don't have to cycle through the other scripts
      break
    }
    scriptIndex++
  }

  return computedLoadPath
}

const moduleInterface = {
  resolveCdn: __resolveCdn
}

export default moduleInterface

