import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'
import RadioButtons from '@veneer/core/dist/esm/scripts/radio_buttons'
import RadioButton from '@veneer/core/dist/esm/scripts/radio_button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { MainLayout } from '../../../component'
import { State } from '../../../common/types'
import DefaultHeader from '../../../component/DefaultHeader'
import udlEvents from '../../../common/udlEvents'
import * as S from './styles'

import { CODE_TYPES, ARKOSE_SELECTORS } from '../../../constants'

const getDefaultValues = ({ isEmailAvailable, isMobileAvailable }) => {
  const result = { type: '', headerText: '' }
  if (isEmailAvailable && isMobileAvailable) {
    result.type = CODE_TYPES.EMAIL_CODE
    result.headerText = 'label.recovery_header_mfa'
  } else if (isEmailAvailable) {
    result.type = CODE_TYPES.EMAIL_CODE
    result.headerText = 'label.recovery_code_email'
  } else if (isMobileAvailable) {
    result.type = CODE_TYPES.MOBILE_CODE
    result.headerText = 'label.recovery_code_sms'
  }
  return result
}

const CodeSend = () => {
  const { availableMfaMethods = [] } = useSelector((state: State) => state.user)
  const { retryDelay } = useSelector((state: State) => state.session)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [hasMadeTooManyRequests, setMadeTooManyRequests] = useState(false)
  const isEmailAvailable = availableMfaMethods.includes('email')
  const isMobileAvailable = availableMfaMethods.includes('phone')
  const { type, headerText } = getDefaultValues({ isEmailAvailable, isMobileAvailable })
  const [codeRecovery, setCodeRecovery] = useState(type)

  const submitSelector = ARKOSE_SELECTORS.MULTIFACTOR_AUTHENTICATION.SEND_REC_CODE

  const renderRecoveryOptions = () => (
    <S.RadioButton>
      <RadioButtons
        name="radiobuttons-options"
        value={codeRecovery}
        onChange={(_e, value) => {
          setCodeRecovery(value)
          value === CODE_TYPES.EMAIL_CODE
            ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT158'))
            : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT159'))
        }}
      >
        <RadioButton
          id="radiobutton-email"
          data-testid="radiobutton-email"
          value={CODE_TYPES.EMAIL_CODE}
          label={t('button.email_option')}
        />
        <RadioButton
          id="radiobutton-mobile"
          data-testid="radiobutton-mobile"
          value={CODE_TYPES.MOBILE_CODE}
          label={t('button.sms_option')}
        />
      </RadioButtons>
    </S.RadioButton>
  )

  const renderRecoveryButton = () => (
    <S.RecoveryButton>
      <Button
        id={submitSelector}
        name={submitSelector}
        onClick={handleClick}
        loading={isLoading}
        disabled={retryDelay}
        expanded
      >
        {t('button.recovery_code')}
      </Button>
    </S.RecoveryButton>
  )

  const handleClick = async () => {
    setLoading(true)
    setMadeTooManyRequests(false)
    const options = { setLoading, setMadeTooManyRequests, type: codeRecovery }
    const arkoseParams = {
      options,
      submitSelector: submitSelector,
      callback: dispatch.user.mfaSendRecoveryCode,
      onHide: () => setLoading(false)
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT161'))
    await dispatch.user.mfaSendRecoveryCode({
      options: { ...options, arkoseParams }
    })
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.recover_account')} />
      <div>{t(headerText)}</div>
      {isEmailAvailable && isMobileAvailable && renderRecoveryOptions()}
      {(isEmailAvailable || isMobileAvailable) && renderRecoveryButton()}
      <S.RecoveryButton>
        <Button
          tabIndex={0}
          id="back-link"
          appearance="ghost"
          onClick={() => {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT160'))
            navigate(-1)
          }}
          expanded
        >
          {t('link.back_link')}
        </Button>
      </S.RecoveryButton>
      <S.NotificationWrapper>
      {retryDelay && hasMadeTooManyRequests && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('form.err_send_email_code')}
          type="negative"
        />
        )}
        {retryDelay && (
          <InlineNotification
            hideIcon={false}
            closeButton={false}
            title={t('label.code_in_moment_recovery')}
            type="informative"
          />
        )}
      </S.NotificationWrapper>
    </MainLayout>
  )
}

export default CodeSend
