import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ILocation, State } from '../../common/types'
import { MainLayout, DefaultHeader, ErrorCardContent } from '../../component'
import * as S from './style'

const CookieEnablement = () => {
  const { t } = useTranslation()
  const location = useLocation() as ILocation
  const error = useSelector((state: State) => state.error)

  const hasErrorCode = error['x-request-id'] || error['correlation-id']

  return (
    <>
      <MainLayout>
        <DefaultHeader title={t('form.something_wrong_error')} />
        <div>
          <S.UlWrapper>
            {location.state?.sessionExpired ? (
              <li>{t('form.session_expired_error')}</li>
            ) : (
              <>
                <li>{t('form.cookies_disabled_error')}</li>
                <li>{t('form.system_clock_error')}</li>
              </>)}
          </S.UlWrapper>
        </div>
        {hasErrorCode &&
          <ErrorCardContent error={error} />
        }
      </MainLayout>
    </>
  )
}

export default CookieEnablement
