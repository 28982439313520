import Button from '@veneer/core/dist/esm/scripts/button'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import udlEvents from '../../common/udlEvents'
import { MainLayout, UITextBox } from '../../component'
import DefaultHeader from '../../component/DefaultHeader'
import { ARKOSE_SELECTORS, PAGE_USERNAME, VALIDATION_TYPES } from '../../constants'
import { RootModel } from '../../models'
import { validateEmail } from '../../util'
import * as S from './style'

const AddEmail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const defaultValues = { email: '' }
  const { client } = useSelector((state: RootModel) => state.session)
  const submitSelector = ARKOSE_SELECTORS.ADD_EMAIL.ADD_EMAIL_BUTTON
  const APPNAME = client && client.displayName
  const EMAILVALIDATION = client && client.emailValidation
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })

  const onSubmit = async ({ email }) => {
    setLoading(true)
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT133'))
    const options = { setLoading, setError, email }
    const arkoseParams = {
      options,
      onHide: () => setLoading(false),
      callback: dispatch.user.setPrimaryEmail,
      submitSelector,
      formErrors: errors
    }
    await dispatch.user.setPrimaryEmail({
      options: { ...options, arkoseParams }
    })
  }

  const renderText = () => {
    if (EMAILVALIDATION === VALIDATION_TYPES.OPTIONAL_EMAIL) {
      return t('label.requires_email', { APPNAME: APPNAME })
    }
    return t('label.requires_verif', { APPNAME: APPNAME })
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.sign_in')} />
      <S.MarginWrapper>
        <p>{renderText()}</p>
      </S.MarginWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ContainerAddEmail>
          <S.MarginWrapper>
            <UITextBox
              aria-label="email"
              id="email"
              label={t('label.placeholder_email')}
              error={!!errors.email}
              helperText={errors.email && t(errors.email.message)}
              autoCapitalize="none"
              autoComplete="off"
              autoFocus
              required
              control={control}
              rules={{
                required: 'form.err_email_empty',
                validate: (value: string) => validateEmail(value, true)
              }}
            />
          </S.MarginWrapper>
          <S.ButtonWrapper>
            <Button
              aria-label="submit"
              id={submitSelector}
              name={submitSelector}
              type="submit"
              loading={loading}
              expanded
            >
              {t('button_capitalize.continue')}
            </Button>
          </S.ButtonWrapper>
        </S.ContainerAddEmail>
      </form>
      <S.ButtonWrapper>
        <Button
          tabIndex={0}
          id="back-link"
          aria-label="back"
          appearance="ghost"
          onClick={() => {
            navigate(PAGE_USERNAME)
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT134'))
          }}
          expanded
        >
          {t('link.back_signin')}
        </Button>
      </S.ButtonWrapper>
    </MainLayout>
  )
}

export default AddEmail
