import { useState, useEffect } from 'react'
import Password from '@veneer/core/dist/esm/scripts/password'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getRequirements } from './utils/Requirements'
import { getPasswordRequirements, sleep } from '../../../util'
import { State } from '../../../common/types'

const propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object
}

const STATUS = {
  complete: 'complete',
  incomplete: 'incomplete'
}

// TODO: Merge this component with UIPassword
const PasswordWithReqs = ({ id, onChange, error, onBlurFunction, weakPasswordError, ...props }) => {
  const { t } = useTranslation()
  const locale = useSelector((state: State) => state.locale)
  const [errorState, setErrorState] = useState(false)
  const [requirementsItems, setRequirementsItems] = useState(getRequirements(t))
  const [hide, setHide] = useState(true)
  const [touched, setTouched] = useState(false)
  const [isRequirementComplete, setIsRequirementComplete] = useState(false)

  useEffect(() => {
    // To fix onFocus function being triggered by React render
    setHide(true)
  }, [])

  useEffect(() => {
    setRequirementsItems(getRequirements(t))
  }, [locale, t])

  useEffect(() => {
    setErrorState(!!error)
  }, [error])

  const validatePassword = (password) => {
    const _requirements = [...requirementsItems]
    const [item0, item1] = _requirements
    const [item10, item11, item12, item13] = item1.requirements

    const {
      hasMinLength,
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialCharacter,
      policyCriteria,
      isValidPassword
    } = getPasswordRequirements(password)
    item0.status = hasMinLength ? STATUS.complete : STATUS.incomplete
    item10.status = hasUpperCase ? STATUS.complete : STATUS.incomplete
    item11.status = hasLowerCase ? STATUS.complete : STATUS.incomplete
    item12.status = hasNumber ? STATUS.complete : STATUS.incomplete
    item13.status = hasSpecialCharacter ? STATUS.complete : STATUS.incomplete
    item1.status = policyCriteria ? STATUS.complete : STATUS.incomplete

    setErrorState(!isValidPassword)
    setRequirementsItems(_requirements)
    setIsRequirementComplete(isValidPassword)
  }

  const handleOnChange = (password) => {
    !touched && setTouched(true)
    onChange(password)
    validatePassword(password)
  }

  const handleOnBlur = async () => {
    // allowing event propagation
    if (typeof onBlurFunction === 'function') {
      onBlurFunction()
    }
    await sleep(100)
    await setHide(true)
    if (touched) {
      const status = requirementsItems.some((item) => item.status !== STATUS.complete)
      setErrorState(status)
    }
  }

  const i18n =
    error && error.message
      ? typeof error.message === 'string'
        ? t(error.message)
        : weakPasswordError()
      : t('form.password_check_requirements')

  useEffect(() => {
    setHide(isRequirementComplete)
  }, [isRequirementComplete])

  return (
    <Password
      {...props}
      id={id}
      error={errorState}
      onChange={handleOnChange}
      onFocus={(e) => {
        if (!isRequirementComplete) {
          setHide(false)
        }
        validatePassword(e.target.value)
      }}
      behavior="compact"
      requirementsPlacement="top"
      onBlur={handleOnBlur}
      requirements={requirementsItems}
      hideRequirements={hide}
      helperText={(!isRequirementComplete || (isRequirementComplete && errorState)) && i18n}
      maxLength={200}
    />
  )
}

PasswordWithReqs.propTypes = propTypes

export default PasswordWithReqs
