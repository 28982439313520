import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Privacy from './Privacy'
import SingleTnC from './SingleTnC'
import MultipleTnC from './MultipleTnC'
import TnCFieldLink from './TnCFieldLink'
import { Tnc } from '../../../common/types'
import udlEvents from '../../../common/udlEvents'

interface TnCFieldProps {
  tncs: object[]
  toggleModal: Function
  jumpTo: Function
  pageName: string
}

const TnCField = ({ tncs, jumpTo, toggleModal, pageName }: TnCFieldProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const openModal = (index: number) => {
    jumpTo(index)
    toggleModal(true)
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT40'))
  }

  const handleClick = (index: number) => {
    openModal(index)
  }

  const handleKeyDown = (event: KeyboardEvent, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      openModal(index)
    }
  }

  const TnCLinks = tncs.map((tnc: Tnc, index: number) => {
    const getLinkText = () => {
      const parsedDisplayTitle = tnc.link ? tnc.link.text : tnc.displayTitle
      if (tncs.length > 1) return parsedDisplayTitle || tnc.tncId
      return parsedDisplayTitle || t('link.single_tnc_link')
    }
    const canHaveSideText = !!(tnc.link && tnc.displayTitle)
    return (
      <TnCFieldLink
        id={`tnc-link-${index}`}
        key={index}
        onClick={() => handleClick(index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        linkText={getLinkText()}
        leftText={canHaveSideText ? tnc.displayTitle.substring(0, tnc.link.startIndex) : ''}
        rightText={
          canHaveSideText ? tnc.displayTitle.substring(tnc.link.endIndex + 1, tnc.displayTitle.length) : ''
        }
      />
    )
  })

  const buildTnCField = () => {
    if (TnCLinks.length === 0) return <Privacy />
    if (TnCLinks.length === 1) return <SingleTnC tncLink={TnCLinks[0]} />
    return <MultipleTnC tncLinks={TnCLinks as any} />
  }

  return <div>{buildTnCField()}</div>
}

export default TnCField
