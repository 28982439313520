import { Models } from '@rematch/core';
import { ExtraModelsFromLoading } from '@rematch/loading'
import arkose from './arkose'
import codeVerification from './codeVerification'
import session from './session'
import user from './user'
import error from './error'
import udl from './udl'

export interface RootModel extends Models<RootModel> {
  arkose: typeof arkose
  codeVerification: typeof codeVerification
  session: typeof session
  user: typeof user
  error: typeof error
  udl: typeof udl
}
export type FullModel = ExtraModelsFromLoading<RootModel>

export const models: RootModel = {
  arkose,
  codeVerification,
  session,
  user,
  error,
  udl
}
