import styled from '@emotion/styled'
import Scrollbar from '@veneer/core/dist/scripts/scrollbar/scrollbar'

const height = 334
const paddingT = 39
const paddingB = 33

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckboxContainer = styled(Scrollbar)`
  max-height: calc(${height}px - ${paddingT + paddingB}px);
  flex: 1;
`

export const ConsentAccept = styled.div`
  margin-bottom: 20px;
`

export const ButtonConsent = styled.div`
  margin-bottom: 20px;
`

export const CheckboxContent = styled.div`
  margin: 15px 3px;
`
