import { useState, useEffect } from 'react'
import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { MainLayout, UIMobileNumber, DefaultHeader } from '../../component'
import { ARKOSE_SELECTORS } from '../../constants'
import { validateMobileNumber } from '../../util'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

import * as S from './styles'

const EditMobile = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const { phoneNumber, identityProvider } = useSelector((state: State) => state.user)
  const { loading } = useSelector((state: State) => state)
  const retryDelay = useSelector(({ session }: State) => session.retryDelay)
  const defaultValues = { phoneNumber: phoneNumber || '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })
  const { smsSupportedCountryNumbers } = useSelector((state: State) => state.session)

  useEffect(() => {
    if (!smsSupportedCountryNumbers || !smsSupportedCountryNumbers.length) {
      dispatch.session.fetchSmsSupportedCountryNumbers()
    }
  }, [smsSupportedCountryNumbers, dispatch.session])

  const submitSelector = ARKOSE_SELECTORS.EDIT_MOBILE.EDIT_MOBILE_BUTTON

  const submitMobile = async ({ phoneNumber }) => {
    setLoading(true)
    const options = { setLoading, setError, phoneNumber, identityProvider }
    const arkoseParams = {
      options,
      callback: dispatch.user.changePrimaryMobile,
      onHide: () => setLoading(false),
      submitSelector,
      formErrors: errors
    }
    await dispatch.user.changePrimaryMobile({ options: { ...options, arkoseParams } })
  }

  const triggerUDLEvent = () => dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT115'))

  return (
    <MainLayout>
      <DefaultHeader title={t('link.change_mobile_number')} />
      <S.Description>{t('label.enter_mobile_number_account')}</S.Description>
      <form onSubmit={handleSubmit(submitMobile)}>
        <S.InputWrapper>
          <UIMobileNumber
            id="phoneNumber"
            error={errors.phoneNumber && t(errors.phoneNumber.message)}
            control={control}
            rules={{
              required: 'form.err_mobile_empty',
              validate: (value) => validateMobileNumber(value)
            }}
            overwriteValue={phoneNumber}
          />
        </S.InputWrapper>
        <S.ButtonWrapper>
          <Button
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading || loading.effects.user.changePrimaryMobile}
            disabled={retryDelay}
            onClick={triggerUDLEvent}
            expanded
          >
            {t('label.send_message')}
          </Button>
        </S.ButtonWrapper>
      </form>
      <S.ButtonWrapper>
        <Button
          tabIndex={0}
          id="back-link"
          appearance="ghost"
          onClick={() => {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT114'))
            navigate(-1)
          }}
          expanded
        >
          {t('link.back_link')}
        </Button>
      </S.ButtonWrapper>
      {retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.code_in_moment_mobile')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default EditMobile
