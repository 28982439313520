export const BASE_URL = '/login3'

export const PAGE_ADD_EMAIL = `${BASE_URL}/add-email`
export const PAGE_AUTH = `${BASE_URL}/auth`
export const PAGE_AUTH_CODE_SEND = `${BASE_URL}/auth/code-send`
export const PAGE_AUTH_RECOVERY_VERIFICATION = `${BASE_URL}/auth/recovery-verification`
export const PAGE_AUTH_UNVERIFIED = `${BASE_URL}/auth/unverified`
export const PAGE_CHANGE_PASSWORD = `${BASE_URL}/change-password`
export const PAGE_CHECK_USERNAME = `${BASE_URL}/check-username`
export const PAGE_CODE_SEND = `${BASE_URL}/code-send`
export const PAGE_COMPLETE_ACCOUNT = `${BASE_URL}/complete-account`
export const PAGE_COOKIE_ENABLEMENT = `${BASE_URL}/cookie-enablement`
export const PAGE_CONSENT_MANAGEMENT = `${BASE_URL}/consent-management`
export const PAGE_SESSION_INFO = `${BASE_URL}/session-info`
export const PAGE_EDIT_EMAIL = `${BASE_URL}/edit-email`
export const PAGE_EDIT_MOBILE = `${BASE_URL}/edit-mobile`
export const PAGE_EMAIL_VERIFICATION = `${BASE_URL}/email-verification`
export const PAGE_ERROR = `${BASE_URL}/error`
export const PAGE_MOBILE_CODE_SEND = `${BASE_URL}/mobile-code-send`
export const PAGE_PASSWORD = `${BASE_URL}/password`
export const PAGE_EMAIL_OR_PHONE_ASSOCIATED = `${BASE_URL}/password-recovery`
export const PAGE_PASSWORD_RESET = `${BASE_URL}/password-recovery/reset`
export const PAGE_PASSWORD_RESET_SUCCESS = `${BASE_URL}/password-recovery/reset-success`
export const PAGE_PHONE_VERIFICATION = `${BASE_URL}/phone-verification`
export const PAGE_REDIRECT = `${BASE_URL}/redirect`
export const PAGE_REDIRECT_TO = `${BASE_URL}/redirect-to`
export const PAGE_SIGN_UP = `${BASE_URL}/sign-up`
export const PAGE_SIGNOUT = `${BASE_URL}/sign-out`
export const PAGE_SOCIAL_LOGIN = `${BASE_URL}/social-login`
export const PAGE_TERMS_AND_CONDITIONS = `${BASE_URL}/terms-and-conditions`
export const PAGE_USERNAME = `${BASE_URL}`
export const PAGE_USERNAME_RECOVERY = `${BASE_URL}/username-recovery`
export const PAGE_USERNAME_RECOVERY_SUCCESS = `${BASE_URL}/username-recovery/recovery-success`
export const PAGE_VERIFICATION = `${BASE_URL}/verification`
export const PAGE_VERIFICATION_CONFIRMED = `${BASE_URL}/verification/confirmed`
export const PAGE_VERIFICATION_ERROR = `${BASE_URL}/verification/error`
export const PAGE_VERIFICATION_SUCCESS = `${BASE_URL}/verification-success`
export const PAGE_OOBE_SIGN_UP = `${BASE_URL}/oobe-sign-up`
export const PAGE_TNC_MANAGEMENT = `${BASE_URL}/tnc-management`
export const PAGE_LEARN_MORE = `${BASE_URL}/learn-more`
export const PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD = `${BASE_URL}/leaked-credentials/change-password`
export const PAGE_OTP_SIGN_IN = `${BASE_URL}/otp-sign-in`

export const UDL_PAGE_NAME = {
  [PAGE_USERNAME]: 'username',
  [PAGE_PASSWORD]: 'password',
  [PAGE_CHANGE_PASSWORD]: 'change-password',
  [PAGE_CHECK_USERNAME]: 'check-username',
  [PAGE_REDIRECT_TO]: 'redirect-to',
  [PAGE_SIGN_UP]: 'sign-up',
  [PAGE_EMAIL_VERIFICATION]: 'email-verification',
  [PAGE_ADD_EMAIL]: 'add-email',
  [PAGE_CODE_SEND]: 'code-send',
  [PAGE_MOBILE_CODE_SEND]: 'mobile-code-send',
  [PAGE_PASSWORD_RESET]: 'forgot-password',
  [PAGE_PASSWORD_RESET_SUCCESS]: 'password-recovery-success',
  [PAGE_PHONE_VERIFICATION]: 'phone-verification',
  [PAGE_CONSENT_MANAGEMENT]: 'consent-management',
  [PAGE_USERNAME_RECOVERY_SUCCESS]: 'username-recovery-success',
  [PAGE_ERROR]: 'error',
  [PAGE_TERMS_AND_CONDITIONS]: 'full-tnc-view',
  [PAGE_SOCIAL_LOGIN]: 'social-login',
  [PAGE_SIGNOUT]: 'sign-out',
  [PAGE_REDIRECT]: 'redirect',
  [PAGE_ADD_EMAIL]: 'add-email',
  [PAGE_EDIT_EMAIL]: 'edit-email',
  [PAGE_AUTH]: 'mfa-auth',
  [PAGE_AUTH_CODE_SEND]: 'mfa-auth-code-send',
  [PAGE_AUTH_UNVERIFIED]: 'mfa-auth-unverified',
  [PAGE_AUTH_RECOVERY_VERIFICATION]: 'mfa-auth-recovery-verification',
  [PAGE_VERIFICATION]: 'verification-link',
  [PAGE_VERIFICATION_CONFIRMED]: 'verification-confirmed',
  [PAGE_VERIFICATION_SUCCESS]: 'verification-success',
  [PAGE_VERIFICATION_ERROR]: 'verification-link-error',
  [PAGE_USERNAME_RECOVERY]: 'username-recovery',
  [PAGE_EMAIL_OR_PHONE_ASSOCIATED]: 'forgot-password-confirm-username',
  [PAGE_SESSION_INFO]: 'session-info',
  [PAGE_COOKIE_ENABLEMENT]: 'cookie-enablement',
  [PAGE_COMPLETE_ACCOUNT]: 'complete-account',
  [PAGE_EDIT_MOBILE]: 'edit-mobile',
  [PAGE_OOBE_SIGN_UP]: 'oobe-sign-up',
  [PAGE_TNC_MANAGEMENT]: 'tnc-management',
  [PAGE_LEARN_MORE]: 'learn-more',
  [PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD]: 'leaked-creds-change-password',
  [PAGE_OTP_SIGN_IN]: 'otp-sign-in',
  UNDEFINED: 'undefined-page-name'
}

export const PARAM_APP_INSTANCE_ID_LEGACY = 'appInstanceId'
export const PARAM_APP_INSTANCE_ID = 'appInstanceID'
export const PARAM_TARGET = 'target'
export const PARAM_FIRSTNAME = 'given_name'
export const PARAM_LASTNAME = 'family_name'
export const PARAM_EMAIL = 'email'
export const PARAM_USERNAME = 'username'
export const PARAM_PHONE_NUMBER = 'phoneNumber'
export const PARAM_PHONE_NUMBER_CONFLICT = 'phonenumber'
export const PARAM_LOGIN_HINT = 'login_hint'
export const PARAM_ALLOW_RETURN = 'allow_return'
export const PARAM_THEME_ID = 'theme_id'
export const PARAM_COUNTRY = 'country'
export const PARAM_LOCALE = 'locale'
export const PARAM_HIDE_CREATE = 'hide_create'
export const PARAM_REMEMBER_ME = 'remember_me'
export const PARAM_FLOW_TOKEN = 'flow'
export const PARAM_FLOW_ID = 'flowId'
export const PARAM_OTP = 'otp'
export const PARAM_CSRF_TOKEN = 'CSRF-TOKEN'
export const PARAM_CAPTCHA_BYPASS_KEY = 'captcha_bypass_key'
export const PARAM_LOGOUT_REDIRECT_URI = 'post_logout_redirect_uri'
export const PARAM_FEDERATION = 'federation'
export const PARAM_OTP_CODE = 'otp'
export const PARAM_AUTOREDIRECT = 'autoredirect'
export const PARAM_USERNAME_TYPE = 'username_type'
export const PARAM_TARGET_CREATE = 'create'
export const PARAM_TARGET_PASSWORD = 'password'
export const PARAM_TARGET_FORGET_PWD = 'forgot-password'
export const PARAM_PROHIBITED_EMAIL = 'prohibited_email'

export const IS_PRISTINE = 'isPristine'
export const PRODUCTION_ENV = 'prod'

export const ROUTE_ACTION_REPLACE = 'replace'
export const ROUTE_ACTION_PUSH = 'push'

export const USERNAME = 'username'
export const IDENTITIES = 'identities'
export const COOKIE_REMEMBER_USERNAME = 'hpid_username'
export const APP_RESET = 'APP_RESET'
export const SUPPORT_HPID = 'supportHPIDNativeLogin'
export const SESSION_DATA = 'sessionIdentityResource'
export const SOCIAL_LOGIN_ERROR = 'access_denied'
export const PHONE_VERIFICATION = 'PHONE_VERIFICATION'
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION'
export const TRACKED_ROUTES = [
  PAGE_AUTH_RECOVERY_VERIFICATION,
  PAGE_AUTH,
  PAGE_PASSWORD,
  PAGE_SIGN_UP,
  PAGE_TERMS_AND_CONDITIONS,
  PAGE_CONSENT_MANAGEMENT,
  PAGE_VERIFICATION_SUCCESS,
  PAGE_COMPLETE_ACCOUNT
]
export const REDIRECT_LINK_TIMEOUT = 30000
export const USER_INTERACTION_TIMEOUT = 3000

// Define UI Backend status
export const BFF_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  MFA_REQUIRED: 'mfa-required',
  LOGIN_REQUIRED: 'login-required',
  INVALID_CREDENTIALS: 'invalidCredentials',
  ACCOUNT_LOCKED: 'accountLocked',
  BAD_REQUEST: 'badRequest',
  UNEXPECTED_REQUEST: 'unexpectedRequest',
  EMAIL_VERIFICATION: 'email-verification-required',
  PHONE_VERIFICATION: 'phone-verification-required',
  EMAIL_OR_PHONE_VERIFICATION: 'email-or-phone-verification-required',
  CONSENT_MANAGEMENT: 'scope-consent-required',
  MUST_CHANGE_PASSWORD: 'mustChangePassword',
  INVALID_CODE: 'invalidCode',
  EXPIRED_CODE: 'expiredCode',
  INVALID_VALUE: 'invalidValue',
  INVALID_REQUEST: 'invalidRequest',
  INVALID_PASSWORD: 'invalidPassword',
  INVALID_NEW_PASSWORD: 'invalidNewPassword',
  INVALID_PHONE_NUMBER: 'invalidPhoneNumber',
  UNSUPPORTED_COUNTRY: 'unsupportedCountry',
  CAPTCHA_REQUIRED: 'captchaRequired',
  CONFLICTING_RESOURCE: 'conflictingResource',
  USERNAME_RECOVERY_NO_MATCH: 'noMatch',
  SERVER_ERROR: 'serverError',
  RECOVERY_EMAIL_NO_RESULTS: 'noResults',
  TNC_VALIDATION_REQUIRED: 'tnc-validation-required',
  NO_EXTERNAL_IDP_ACCOUNT: 'no-linked-account',
  EDIT_UNIQUENESS: 'uniqueness',
  TOO_MANY_REQUESTS: 'tooManyRequests',
  NOT_YET_VERIFIED: 'notYetVerified',
  NO_TARGET: 'noTarget',
  EMAIL_REQUIRED: 'email-capture-required',
  NOT_ALLOWED: 'notAllowed',
  PROHIBITED_EMAIL_DOMAIN: 'prohibitedEmailDomain',
  NO_COOKIE_FOUND: 'noCookieFound',
  EXPIRED_SESSION: 'expiredSession',
  COMPLETION_REQUIRED: 'sign-up-completion-required',
  WEAK_PASSWORD: 'weakPassword',
  ARKOSE_ERROR_DESCRIPTION: 'Invalid CAPTCHA response (DX)',
  NO_NATIVE: 'noNative',
  MFA_ENABLED: 'mfaEnabled',
  NOT_FOUND: 'notFound',
  OTP_LOGIN_DISABLED: 'otpLoginDisabled'
}

export const PASSWORD_REQUIREMENT_TYPE = {
  NOT_CURRENT_PASSWORD: 'notCurrentPassword',
  LENGTH: 'length',
  REGULAR_EXPRESSION: 'regularExpression'
}

export const IDENTITY_PROVIDER = {
  HPID: 'hpid',
  ONE_HP: 'onehp',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  WE_CHAT: 'wechat',
  AZURE: 'azure',
  MSP: 'msp'
}

export const SIMPLIFIED_CHINESE_LOCALE = ['zh_CN', 'zh_SG']

// TODO: This is a temporarily workaround to dont show FACEBOOK button as the list of IDPs
export const INTERNAL_IDPS = [IDENTITY_PROVIDER.ONE_HP, IDENTITY_PROVIDER.MSP, IDENTITY_PROVIDER.FACEBOOK]
export const PAGES_WITHOUT_SESSION = [
  PAGE_ERROR,
  PAGE_SIGNOUT,
  PAGE_VERIFICATION,
  PAGE_VERIFICATION_CONFIRMED,
  PAGE_VERIFICATION_ERROR,
  PAGE_COOKIE_ENABLEMENT
]
export const PAGES_WITH_BACKGROUND = [PAGE_SIGN_UP, PAGE_USERNAME, PAGE_PASSWORD]
export const COOKIE_ERROR_MESSAGE = ['timedrift', 'request.cookies should have required property']

export const BFF_IDENTITY_PROVIDER = {
  HPID: 'HPID'
}

export const THEMES = {
  DARK: 'dark',
  OMEN: 'omen',
  HPAPP: 'hpapp'
}

export const IMAGE_RESOLUTION = {
  X_LARGE: 'XLarge',
  LARGE: 'Large',
  MEDIUM: 'Medium'
}

export const SCREEN_LAYOUT = {
  DEFAULT: 'default',
  COMPACT: 'compact'
}

export const LINK_SELECTORS = {
  SWITCH_USERNAME: 'switch-username',
  EDIT_EMAIL: 'edit-email',
  EDIT_PHONE: 'edit-phone'
}

export const PAGE_DESCRIPTIONS = {
  PASSWORD_RESET: {
    CHECK_EMAIL_PHONE_DESC: 'check-email-phone-desc'
  },
  USERNAME_RECOVERY: {
    USERNAME_RECOVERY_DESC: 'username-recovery-desc'
  },
  EMAIL_VERIFICATION: {
    CHECK_EMAIL_MESSAGE: 'check-email-message'
  },
  LEAKED_CREDENTIALS_CHANGE_PASSWORD: {
    LEARN_MORE_DESC: 'learn-more-desc',
    CHECK_EMAIL_PHONE_DESC: 'check-email-phone-desc'
  }
}

export const ARKOSE_SELECTORS = {
  SIGN_IN: {
    USERNAME: 'user-name-form-submit',
    PASSWORD: 'sign-in',
    OTP: 'otp-sign-in-form-submit',
    RESEND_PASSCODE: 'resend-passcode',
    USE_PASSWORD_OTP: 'use-password'
  },
  SIGN_UP: {
    SUBMIT: 'sign-up-submit'
  },
  SEND_CODE: {
    SEND_EMAIL: 'send-email',
    SEND_PHONE: 'send-phone',
    VERIFY_BY_EMAIL: 'verify-by-email'
  },
  ADD_EMAIL: {
    ADD_EMAIL_BUTTON: 'add-email'
  },
  EDIT_EMAIL: {
    EDIT_EMAIL_BUTTON: 'edit-email'
  },
  EDIT_MOBILE: {
    EDIT_MOBILE_BUTTON: 'edit-mobile'
  },
  EMAIL_VERIFICATION: {
    SUBMIT_CODE: 'submit-code',
    RESEND_CODE: 'resend-email-code'
  },
  PHONE_VERIFICATION: {
    SUBMIT_CODE: 'submit-code',
    SUBMIT_CODE_BY_EMAIL: 'submit-code-by-email',
    RESEND_CODE: 'resend-sms-code'
  },
  RECOVERY: {
    USERNAME_RECOVERY: 'username-recovery',
    FORGOT_PASSWORD: 'password-recovery'
  },
  MULTIFACTOR_AUTHENTICATION: {
    SUBMIT_AUTH_CODE: 'submit-auth-code',
    SUBMIT_REC_CODE: 'submit-recovery-code',
    SEND_REC_CODE: 'send-recovery-code',
    RESEND_REC_CODE: 'resend-recovery-code'
  },
  COMPLETE_ACCOUNT: {
    SUBMIT_BUTTON: 'continue-button'
  },
  CHANGE_PASSWORD: {
    SUBMIT_BUTTON: 'sign-in'
  },
  OOBE_SIGN_UP: {
    SUBMIT_BUTTON: 'oobe-sign-up-submit'
  },
  CHANGE_PASSWORD_FORM: {
    CONFIRM_BUTTON: 'confirm-button',
    RESEND_CODE_LINK: 'resend-code'
  }
}

export const ARKOSE_KEYS = {
  DEFAULT: 'arkose',
  SIGN_UP: 'arkose_signup'
}

export const CODE_TYPES = {
  EMAIL_CODE: 'email',
  MOBILE_CODE: 'phone'
}

export const RECOVERY_TYPES = {
  RECOVERY_PASSWORD: '0',
  USERNAME_RECOVERY: '1'
}

export const USERNAME_TYPES = {
  USERNAME_EMAIL: '0',
  USERNAME_MOBILE: '1',
  USERNAME: '2'
}

export const USERNAME_TYPES_ALIAS = {
  [USERNAME_TYPES.USERNAME_EMAIL]: 'email',
  [USERNAME_TYPES.USERNAME_MOBILE]: 'mobile',
  [USERNAME_TYPES.USERNAME]: 'username',
  [USERNAME_TYPES.USERNAME_PHONE]: 'phone'
}

export const VALIDATION_TYPES = {
  REQUIRED: 0,
  NOT_REQUIRED: -1,
  EMAIL_REQUIRED_PHONE_OPTIONAL: -2,
  OPTIONAL_EMAIL: -3
}

export const POLLING_DELAY = 7000

export const EMAIL_VERIFICATION_TAB_TITLE = 'HP email verification'

export const BFF_ENDPOINTS = {
  SESSION_USERNAME_PASSWORD: '/session/username-password',
  SESSION_SIGN_UP: '/session/sign-up',
  SESSION_CHECK_USERNAME: '/session/check-username',
  SESSION_CHANGE_PASSWORD: '/session/change-password',
  SESSION_EMAIL_SEND_CODE: '/session/email/send-code',
  SESSION_EMAIL_VERIFY_CODE: '/session/email/verify-code',
  SESSION_EMAIL_CHANGE_PRIMARY: '/session/email/change-primary',
  SESSION_EMAIL_SET_PRIMARY: '/session/email/set-primary',
  EMAIL_VERIFY_LINK: '/email/verify-link',
  SESSION_PHONE_SEND_CODE: '/session/phone/send-code',
  SESSION_PHONE_VERIFY_CODE: '/session/phone/verify-code',
  SESSION_PHONE_CHANGE_PRIMARY: '/session/phone/change-primary',
  RECOVERY_USERNAME: '/recovery/username',
  RECOVERY_PASSWORD: '/recovery/password',
  RECOVERY_PHONE_PASSWORD: '/recovery/phone/password',
  RECOVERY_PASSWORD_RESET: '/recovery/password/reset',
  RECOVERY_PHONE_PASSWORD_RESET: '/recovery/phone/password/reset',
  SESSION_MFA_VERIFY_CODE: '/session/mfa/verify-code',
  SESSION_MFA_EMAIL_VERIFY_CODE: '/session/mfa/email/verify-code',
  SESSION_MFA_PHONE_VERIFY_CODE: '/session/mfa/phone/verify-code',
  SESSION_MFA_EMAIL_SEND_CODE: '/session/mfa/email/send-code',
  SESSION_MFA_PHONE_SEND_CODE: '/session/mfa/phone/send-code',
  SESSION_FEDERATED_SIGN_UP: '/session/federated/sign-up',
  SESSION_FEDERATED_EMAIL_CHANGE_PRIMARY: '/session/federated/email/change-primary',
  SESSION_FEDERATED_PHONE_CHANGE_PRIMARY: '/session/federated/phone/change-primary',
  SESSION_OTP_LOGIN_EMAIL_SEND_CODE: '/session/otp-login/email/send-code',
  SESSION_OTP_LOGIN_PHONE_SEND_CODE: '/session/otp-login/phone/send-code',
  SESSION_OTP_LOGIN_EMAIL_VERIFY_CODE: '/session/otp-login/email/verify-code',
  SESSION_OTP_LOGIN_PHONE_VERIFY_CODE: '/session/otp-login/phone/verify-code'
}

export const OTP_STATUS = ['MFA_ENABLED', 'NO_NATIVE']

export const OTP_BUTTON_LABEL = {
  USE_ONE_TIME_PASSCODE: 'button.use_otp',
  SEND_SIGN_IN_CODE: 'button.use_otp_2'
}