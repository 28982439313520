import styled from '@emotion/styled'

export const RadioButton = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const RecoveryButton = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px 0px;
`
