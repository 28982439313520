import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DefaultHeader, MainLayout, ErrorCardContent } from '../../component'
import { State } from '../../common/types'
import * as S from './styles'

const Failure = () => {
  const { t } = useTranslation()
  const error = useSelector((state: State) => state.error)
  const { sessionId } = useSelector((state: State) => state.session)
  const hasErrorInfo = error['x-request-id'] || error['correlation-id'] || sessionId

  return (
    <MainLayout>
      <DefaultHeader title={t('errors.err_general')} />
      {error.message && <S.Text id="message">{t(error.message)}</S.Text>}
      {error.error && <S.Text id="description">{error.error}</S.Text>}
      {hasErrorInfo && <ErrorCardContent error={error} />}
    </MainLayout>
  )
}

export default Failure
