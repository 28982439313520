import styled from '@emotion/styled'

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  margin-bottom: 20px;
`

export const VerifyNumberMessage = styled.p`
  text-align: ${({ isRTL }: { isRTL: boolean }) => (isRTL ? 'right' : 'left')};
  margin-bottom: 20px;
  strong {
    white-space: nowrap;
  }
`

export const UITextContainer = styled.div`
  margin-bottom: 20px;
`

export const VerifyButtonContainer = styled.div`
  margin-bottom: 20px;
  justify-content: center;
`

export const NotReceiveMessage = styled.div`
  margin-top: 27px;
`

export const ResendButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InLineContainer = styled.div`
  margin-top: 20px;
`

export const ChangeMobileContainer = styled.div`
  margin-top: 20px;
`
