const UIIconWechat = ({ multicolor, size = 25, ...restProps }) => {
  const weChatMulticolorIcon = (
    <g id="wechat-multicolor">
      <path
        style={{ overflow: 'visible', fill: '#8FE254' }}
        d="M0,12.8c0,2.9,1.6,5.5,4,7.3c0.2,0.1,0.3,0.4,0.3,0.6c0,0.1,0,0.2,0,0.2c-0.2,0.7-0.5,1.9-0.5,1.9
          c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.2,0.4,0.4,0.4c0.1,0,0.2,0,0.2-0.1L6.8,22c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0.1
          c1.2,0.3,2.5,0.5,3.8,0.5c6.4,0,11.6-4.3,11.6-9.7c0-5.3-5.2-9.7-11.6-9.7C5.2,3.1,0,7.4,0,12.8"
      />
      <path
        style={{ overflow: 'visible', fill: '#E9EBEC' }}
        d="M12.7,19.9c0,4.4,4.3,8,9.7,8c1.1,0,2.2-0.2,3.2-0.4c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.5,0.1l2.1,1.2
        c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2-0.1-0.2c0,0-0.3-1-0.4-1.6c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.4,0.3-0.5
        c2-1.5,3.3-3.7,3.3-6.1c0-4.4-4.3-8-9.7-8C17,11.9,12.7,15.5,12.7,19.9"
      />
      <path
        style={{ fill: '#126F21' }}
        d="M9.3,9.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.5-0.7-1.5-1.6c0-0.9,0.7-1.5,1.5-1.5
          C8.6,8.1,9.3,8.8,9.3,9.6"
      />
      <path
        style={{ fill: '#126F21' }}
        d="M17,9.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.5,1.6-1.5
          C16.3,8.1,17,8.8,17,9.6"
      />
      <path
        style={{ fill: '#7B7F7F' }}
        d="M24.4,17.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
          C25,16.2,24.4,16.8,24.4,17.5"
      />
      <path
        style={{ fill: '#7B7F7F' }}
        d="M17.9,17.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
          C18.5,16.2,17.9,16.8,17.9,17.5"
      />
    </g>
  )
  const weChatIcon = (
    <g id="wechat">
      <path
        d="M15.4,11.2c-0.9,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S17,8.8,17,9.6S16.3,11.2,15.4,11.2 M7.7,11.2c-0.9,0-1.5-0.7-1.5-1.5
        s0.7-1.5,1.5-1.5c0.9,0,1.5,0.7,1.5,1.5S8.6,11.2,7.7,11.2 M11.6,3.1C5.2,3.1,0,7.4,0,12.7c0,2.9,1.6,5.5,4,7.3
        c0.2,0.1,0.3,0.4,0.3,0.6c0,0.1,0,0.2,0,0.2c-0.2,0.7-0.5,1.9-0.5,1.9c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.2,0.4,0.4,0.4
        c0.1,0,0.2,0,0.2-0.1l2.5-1.5c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0.1c1.2,0.3,2.5,0.5,3.8,0.5c0.2,0,0.4,0,0.6,0
        c-0.3-0.8-0.4-1.5-0.4-2.4c0-4.9,4.7-8.8,10.5-8.8c0.2,0,0.4,0,0.6,0C22.1,6.6,17.3,3.1,11.6,3.1 M25.6,18.7c-0.7,0-1.3-0.6-1.3-1.3
        c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C26.9,18.1,26.3,18.7,25.6,18.7 M19.1,18.7c-0.7,0-1.3-0.6-1.3-1.3
        c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C20.4,18.1,19.9,18.7,19.1,18.7 M28.7,26c2-1.5,3.3-3.7,3.3-6.1c0-4.4-4.3-8-9.6-8
        s-9.6,3.6-9.6,8c0,4.4,4.3,8,9.6,8c1.1,0,2.2-0.2,3.1-0.4c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.5,0.1l2.1,1.2c0.1,0,0.1,0.1,0.2,0.1
        c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2-0.1-0.2c0,0-0.3-1-0.4-1.6c0-0.1,0-0.1,0-0.2C28.4,26.3,28.5,26.1,28.7,26"
      />
    </g>
  )
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill={multicolor ? 'none' : 'currentColor'} {...restProps}>
      <svg focusable="false" version="1.1">
        {multicolor ? weChatMulticolorIcon : weChatIcon}
      </svg>
    </svg>
  )
}

UIIconWechat.displayName = 'UIIconWechat'

export default UIIconWechat
