import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import UISocialIcon from '../UISocialIcon/UISocialIcon'
import { INTERNAL_IDPS, SUPPORT_HPID } from '../../constants'
import { State } from '../../common/types'

import * as S from './styles'

export const sortProviders = (providers, country, locale, hasHPIDLocalAccountSupport) => {
  if (!providers.length) {
    return null
  }
  const defaultOrder = ['facebook', 'google', 'wechat', 'azure']
  const altOrder = ['wechat', 'facebook', 'google', 'azure']
  const _providers = hasHPIDLocalAccountSupport
    ? providers.filter((provider) => INTERNAL_IDPS.indexOf(provider.identityProvider) === -1)
    : [...providers]

  const regexp = /^zh_\w{2}$/

  if (locale.match(regexp) || country === 'CN') {
    return _providers.sort(function (a, b) {
      return altOrder.indexOf(a.identityProvider) - altOrder.indexOf(b.identityProvider)
    })
  }
  return _providers.sort(function (a, b) {
    return defaultOrder.indexOf(a.identityProvider) - defaultOrder.indexOf(b.identityProvider)
  })
}

const SocialWrapper = () => {
  const { session } = useSelector((state: State) => state)
  const { locale, country } = useSelector((state: State) => state.user)
  const hasHPIDLocalAccountSupport = useSelector((state: State) => state.session.client[SUPPORT_HPID])
  const { t } = useTranslation()

  const providers = sortProviders(session.supportedIDP, country, locale, hasHPIDLocalAccountSupport)
  if (!providers) return null
  return (
    <S.SocialWrapper>
      {providers.map((item, key) => {
        return (
          <S.SocialButtonWrapper key={key}>
            <UISocialIcon
              button
              key={item.name}
              provider={item}
              text={t(item.text, { IDPNAME: item.description })}
            />
          </S.SocialButtonWrapper>
        )
      })}
    </S.SocialWrapper>
  )
}

export default SocialWrapper
