import styled from '@emotion/styled'

export const MessageContainer = styled.div`
  margin-bottom: 20px;
`
export const InputContainer = styled.div`
  margin-bottom: 40px;
`
export const ButtonContainer = styled.div`
  margin-top: 20px;
`
export const ElementsContainer = styled.div`
  margin: 20px 0;
`
