import { createBrowserHistory } from 'history'
import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import loadingPlugin from '@rematch/loading'
import persistPlugin, { getPersistor } from '@rematch/persist'
import storage from 'redux-persist/lib/storage/session' //TODO: Verify if import ends with session
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createFilter from 'redux-persist-transform-filter'
import { models, RootModel, FullModel } from './models'
import {
  PARAM_EMAIL,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  PARAM_ALLOW_RETURN,
  PARAM_TARGET,
  PARAM_THEME_ID,
  PARAM_LOCALE,
  PARAM_COUNTRY,
  PARAM_HIDE_CREATE,
  PARAM_FLOW_TOKEN,
  PARAM_FLOW_ID,
  USERNAME,
  IDENTITIES,
  APP_RESET,
  IS_PRISTINE,
  PARAM_CSRF_TOKEN
} from './constants'

// We want to store only a subset of your state of user
const userSubsetFilter = createFilter('user', [
  USERNAME,
  IDENTITIES,
  'showRememberMe',
  'loginHint',
  PARAM_EMAIL,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  PARAM_ALLOW_RETURN,
  PARAM_TARGET,
  PARAM_LOCALE,
  PARAM_COUNTRY,
  'market',
  PARAM_HIDE_CREATE,
  PARAM_FLOW_TOKEN,
  PARAM_FLOW_ID,
  PARAM_CSRF_TOKEN,
  IS_PRISTINE,
  'usernameType'
])
// We want to store only a subset of your state of session
const sessionSubsetFilter = createFilter('session', [
  PARAM_THEME_ID,
  'supportedIDP',
  'startTime',
  'warned',
  'regionEndpointUrl',
  'retryDelay',
  'timestampDelay',
  'signUpEventSent'
])
// TODO: Need to improve redux persist
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['user', 'error', 'session', 'udl'],
  transforms: [userSubsetFilter, sessionSubsetFilter],
  version: 1
}

const store = init<RootModel, FullModel>({
  models,
  plugins: [loadingPlugin(), persistPlugin(persistConfig)],
  redux: {
    rootReducers: {
      [APP_RESET]: ({ _persist }) => {
        return { _persist }
      }
    }
  }
})

// TODO: Is there any reason to call history within a dispatch?
// We need to check if there is any conflict with useNavigate
// Some places we use navigate replace. is it right?
export const history = createBrowserHistory({ window })
export const persistor = getPersistor()

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel, FullModel>

export default store
