import axios from 'axios'
import Cookies from 'js-cookie'
import { BFF_STATUS, PARAM_CSRF_TOKEN } from '../../constants'
import store from '../../store'
import config from '../../config'

const axiosConfig = axios.create()

axiosConfig.defaults.withCredentials = true
// this is the header name that backend will inspect
axiosConfig.defaults.xsrfCookieName = PARAM_CSRF_TOKEN
axiosConfig.defaults.xsrfHeaderName = PARAM_CSRF_TOKEN
axiosConfig.defaults.baseURL = config.backendUrl

const injectTimeDriftInfo = (response) => {
  try {
    const bffTS = new Date(response.headers.date).getTime()
    const timedrift = Date.now() - bffTS

    if (Math.abs(timedrift) > 10 * 60 * 1000) {
      // 10 minutes
      response.data.timedrift = timedrift
    }
  } catch (error) {
    // Just ignore the error: it might be caused by a missing date header, ...
  }
}

export const handleError = (error) => {
  if (!error.response || !error.response.data) {
    return store.dispatch.error.goError()
  }

  injectTimeDriftInfo(error.response)

  const errorData = error.response.data

  // Returning entire error to check the 'retry_after' on headers
  const isRetryError =
    errorData.error === BFF_STATUS.TOO_MANY_REQUESTS || errorData.error === BFF_STATUS.NOT_YET_VERIFIED

  if (isRetryError) {
    return Promise.reject(error)
  }

  const isBlobError =
    errorData.error === BFF_STATUS.CAPTCHA_REQUIRED &&
    errorData.errorDescription === BFF_STATUS.ARKOSE_ERROR_DESCRIPTION

  if (isBlobError) {
    const error = {
      errorDescription: errorData.errorDescription,
      'x-request-id': errorData['x-request-id']
    }
    return Promise.reject(error)
  }

  return Promise.reject(errorData)
}

export const addHeaders = (config) => {
  // Add CSRF token
  const cookiesCSRF = Cookies.get(PARAM_CSRF_TOKEN)
  if (!cookiesCSRF) {
    const stateCSRF = store.getState().session[PARAM_CSRF_TOKEN]
    if (stateCSRF) {
      config.headers[PARAM_CSRF_TOKEN] = stateCSRF
    }
  } else {
    config.headers[PARAM_CSRF_TOKEN] = cookiesCSRF
  }
  // Add X-Date header to allow UI-Backend to detect client time config issues
  config.headers['X-Date'] = new Date().toUTCString()
  return config
}

axiosConfig.interceptors.response.use((r) => r, handleError)
axiosConfig.interceptors.request.use(addHeaders, handleError)

export default axiosConfig
