import { Trans } from 'react-i18next'
import Parse from 'libphonenumber-js/max'
import { isPhoneString, isValidEmail } from '../../util'
import { useSelector } from 'react-redux'
import { State } from '../../common/types'

const AccountRecoveryMessage = () => {
  const { user } = useSelector((state: State) => state)
  const accessWithUsername = !isPhoneString(user.username) && !isValidEmail(user.username)
  const username = Parse(user.username)?.isValid() ? Parse(user.username).formatInternational() : user.username

  return accessWithUsername ? (
    <Trans i18nKey="label.checkEmail">
      Check your email for a message from HP to finish recovering your account.
    </Trans>
  ) : (
    <Trans
      i18nKey="label.check_email_phone"
      components={{ bold: <strong /> }}
      values={{ EMAILORPHONE: `<bold dir="ltr">${username}</bold>` }}
    >
      Check {{ username }} for a message from HP.
    </Trans>
  )
}

export default AccountRecoveryMessage
