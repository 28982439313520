import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import config from './config'
import resolver from './utils/resolver.js'

const computedLoadPath = resolver.resolveCdn()

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    ns: ['common', 'countries'],
    defaultNS: 'common',
    load: 'currentOnly',
    fallbackLng: config.defaultLocale,
    debug: false,
    backend: {
      loadPath: computedLoadPath + '/locales/{{lng}}/{{ns}}.json',
        queryStringParams: { v: '4.50', t: process.env.REACT_APP_BUILD_STAMP }
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
