import config from './config'

export const blockSignInNativeHp = config.featureFlags.blockSignInNativeHp || false
export const simplifiedAccount = config.featureFlags.simplifiedAccount || false // Turn to true to enable the simplified sign up
export const displayUpdateAppNotification = config.featureFlags.displayUpdateAppNotification || false // Turn to true to show UpdateAppNotification from form
export const conditionHideConsentCheckbox = config.featureFlags.conditionHideConsentCheckbox || false // Turn to true to show UpdateAppNotification from form
export const editMobileForWechat = config.featureFlags.editMobileForWechat || false // Turn to true to show edit mobile for wechat
export const enableHighContrast = config.featureFlags.enableHighContrast || false // Turn to true to enable the HighContrast
export const editEmailForWechat = config.featureFlags.editEmailForWechat || false // Turn to true to show edit email for wechat
export const displayLearnMorePage = config.featureFlags.displayLearnMorePage || false // Turn to true to show the new Learn More page
export const enablePostFederatedSignUpErrorHandling = config.featureFlags.enablePostFederatedSignUpErrorHandling || false // Turn to true enable the Federated Sign Up error handling
