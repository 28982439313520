import styled from '@emotion/styled'

import tokens from '@veneer/tokens/dist/tokens'
const { layout } = tokens
const { smMin } = layout

export const UIPhoneNumber = styled.div`
  display: flex;
  direction: ltr;
`

export const Select = styled.div<{ isRTL?: boolean }>`
  display: flex;
  direction: ltr;
  #isoCode-helper-text {
    opacity: 1;
    visibility: visible;
    display: flex;
    max-width: 370px;
    ${(props) => props.isRTL && 'flex-direction: row-reverse;'}
    ${(props) =>
      props.isRTL &&
      `
    span.caption.text {
      text-align: right;
    }
    span.icon {
      margin-right: -12px;
    }`}
    margin-right: -30.3em;
  }

  @media (min-width: ${smMin}) {
    #isoCode-helper-text {
      ${(props) => props.isRTL && 'flex-direction: row-reverse;'}
      ${(props) =>
        props.isRTL &&
        `
    span.icon {
      margin-right: -12px;
    }`}
    margin-right: -30.3em;
    }
  }

  @media (max-width: ${smMin}) {
    #isoCode-helper-text {
      max-width: 340px;
    }
  }
`

export const PhoneNumberField = styled.div<{ isRTL?: boolean }>`
  flex: 1;
  direction: ltr;
  margin-left: 8px;
  ${(props) =>
    props.isRTL &&
`
    input {
    text-align: right;
  } 
    `}
`

export const HideCountry = styled.div`
  .country-name {
    display: none;
  }
`
