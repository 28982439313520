import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import TnCContent from '../TnCContent/TnCContent'
import TnCField from './TnCField'
import Print from '../Print/Print'
import { State } from '../../common/types'
import * as S from './styles'
import { useTheme } from '@veneer/theme'

const TnCSignUp = ({ pageName }) => {
  const dispatch = useDispatch()
  const { locale, country } = useSelector((state: State) => state.user)
  const { tncs, isTNCLoaded } = useSelector(({ session }: State) => session)
  const [initialTab, setInitialTab] = useState(0)
  const [show, toggleModal] = useState(false)
  const onClose = () => toggleModal(false)
  const { mode } = useTheme()

  useEffect(() => {
    async function fetchTermsAndConditions() {
      dispatch.session.fetchSingUPTermsAndConditions({ locale, country })
    }
    fetchTermsAndConditions()
  }, [locale, country, dispatch.session])

  return (
    <S.TnCs theme={mode}>
      {!isTNCLoaded ? (
        <S.LoadingContainer>
          <ProgressIndicator />
        </S.LoadingContainer>
      ) : (
        <>
          <TnCField pageName={pageName} tncs={tncs} toggleModal={toggleModal} jumpTo={setInitialTab} />
          <div>
            <Modal show={show} onClose={onClose} closeButton portal={false}>
              <TnCContent tncs={tncs} activeTabIndex={initialTab} />
            </Modal>
          </div>
          <Print tncs={tncs} />
        </>
      )}
    </S.TnCs>
  )
}

export default TnCSignUp
