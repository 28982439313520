import './Print.scss'

const Print = ({ tncs }) => {
  if (tncs === undefined) return null
  return (
    <div className="print-area">
      {
        tncs.map(({ title, content }, index) => (
          <div key={`tnc-print-${index}`}>
            <h5>{title}</h5>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        ))
      }
    </div>
  )
}

export default Print