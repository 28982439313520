import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    padding-bottom: 16px;
  }
`
