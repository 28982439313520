import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useSendEvent = (fieldChanged: boolean, eventSent: boolean) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (fieldChanged && !eventSent) {
      dispatch.session.sendUIEvents('sign-up-data-changed')
      dispatch.session.update({ signUpEventSent: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldChanged])
}

export default useSendEvent
