import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@veneer/core/dist/esm/scripts/button'
import { DefaultHeader, MainLayout, UISpinner } from '../../component'
import { PARAM_OTP_CODE } from '../../constants'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import { sleep } from '../../util'
import * as S from './styles'

const Verification = ({ showSpinner = true }) => {
  const dispatch: any = useDispatch()
  const { t } = useTranslation()
  const { captchaEngines } = useSelector((state: State) => state.session)
  const query = new URLSearchParams(useLocation().search)
  const [hide, setHide] = useState(showSpinner)

  useEffect(() => {
    if (!captchaEngines.length) dispatch.session.fetchCaptchaEngines()
    else verify()
    // eslint-disable-next-line
  }, [captchaEngines])

  const onHide = async () => {
    await sleep(5000)
    setHide(false)
  }

  const verify = async () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT58'))
    setHide(true)
    const options = { otp: query?.get(PARAM_OTP_CODE) }
    const arkoseParams = {
      callback: dispatch.user.verifyEmailLink,
      onHide: onHide,
      options
    }
    await dispatch.user.verifyEmailLink({
      options: { ...options, arkoseParams }
    })
  }

  if (hide) return <UISpinner />

  return (
    <MainLayout>
      <DefaultHeader />
      <S.TextLabel>{t('label.complete_challenge')}</S.TextLabel>
      <Button appearance="ghost" aria-label="verify" id="verify" onClick={verify} expanded>
        {t('button_capitalize.verify')}
      </Button>
    </MainLayout>
  )
}

export default Verification
