import { useLocation } from 'react-router-dom'

const useQueryObject = () => {
  const searchObject = {}
  for (const [key, value] of new URLSearchParams(useLocation().search)) {
    searchObject[key] = value
  }
  return searchObject
}

export default useQueryObject
