export const controlledOnBlur = ({ cProps, isChanged, onBlur }: { cProps?; isChanged?; onBlur? }) => {
  if (isChanged && cProps) cProps.field.onBlur()
  if (onBlur) onBlur()
}

export const controlledOnChange = ({
  cProps,
  value,
  filter,
  onChange
}: {
  cProps?
  value?
  filter?
  onChange?
}) => {
  value = filter ? filter(value) : value
  if (cProps) cProps.field.onChange(value)
  if (onChange) onChange(value)
}
