const UIIconFacebook = ({ multicolor, size = 25, ...restProps }) => {
  const faceBookMulticolor = (
    <g id="facebook-multicolor">
      <path
        style={{ fill: '#4267B2' }}
        d="M30.2,0H1.8C0.8,0,0,0.8,0,1.8v28.5c0,1,0.8,1.8,1.8,1.8h15.3V19.6h-4.2v-4.8h4.2v-3.6c0-4.1,2.5-6.4,6.2-6.4
        C25.1,4.8,26.6,5,27,5v4.3h-2.5c-2,0-2.4,1-2.4,2.4v3.1h4.8l-0.6,4.8h-4.2V32h8.1c1,0,1.8-0.8,1.8-1.8v0V1.8C32,0.8,31.2,0,30.2,0z"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M22.1,32V19.6h4.2l0.6-4.8h-4.8v-3.1c0-1.4,0.4-2.4,2.4-2.4H27V5c-0.4-0.1-2-0.2-3.7-0.2
        c-3.7,0-6.2,2.2-6.2,6.4v3.6h-4.2v4.8h4.2V32H22.1z"
      />
    </g>
  )
  const facebook = (
    <g id="facebook">
      <path
        d="M30.2,0H1.8C0.8,0,0,0.8,0,1.8v28.5c0,1,0.8,1.8,1.8,1.8h15.3V19.6h-4.2v-4.8h4.2v-3.6
        c0-4.1,2.5-6.4,6.2-6.4C25.1,4.8,26.6,5,27,5v4.3h-2.5c-2,0-2.4,1-2.4,2.4v3.1h4.8l-0.6,4.8h-4.2V32h8.1c1,0,1.8-0.8,1.8-1.8v0V1.8
        C32,0.8,31.2,0,30.2,0z"
      />
    </g>
  )
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill={multicolor ? 'none' : 'currentColor'} {...restProps}>
      <svg focusable="false" version="1.1" viewBox="0 0 32 32">
        {multicolor ? faceBookMulticolor : facebook}
      </svg>
    </svg>
  )
}

UIIconFacebook.displayName = 'UIIconFacebook'

export default UIIconFacebook
