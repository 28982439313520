const UIIconMicrosoft = ({ multicolor, size = 25, ...restProps }) => {
  const multiColorIcon = (
    <g id="microsoft-multicolor">
      <path style={{ fill: '#F25022' }} d="M0,0h15.2c0,5.1,0,10.1,0,15.2c-5.1,0-10.1,0-15.2,0V0L0,0z" />
      <path
        style={{ fill: '#80BA01' }}
        d="M16.8,0H32c0,5.1,0,10.1,0,15.2c-5.1,0-10.1,0-15.2,0C16.8,10.1,16.8,5.1,16.8,0L16.8,0z"
      />
      <path style={{ fill: '#02A4EF' }} d="M0,16.8c5.1,0,10.1,0,15.2,0c0,5.1,0,10.1,0,15.2H0V16.8L0,16.8z" />
      <path
        style={{ fill: '#FFB902' }}
        d="M16.8,16.8c5.1,0,10.1,0,15.2,0c0,5.1,0,10.1,0,15.2H16.8C16.8,26.9,16.8,21.9,16.8,16.8L16.8,16.8z"
      />
    </g>
  )
  const icon = (
    <g id="microsoft">
      <path
        d="M0,0h15.2c0,5.1,0,10.1,0,15.2c-5.1,0-10.1,0-15.2,0V0L0,0z M16.8,0c0,5.1,0,10.1,0,15.2
        c5.1,0,10.1,0,15.2,0c0-5.1,0-10.1,0-15.2H16.8L16.8,0z M0,16.8V32h15.2c0-5.1,0-10.1,0-15.2C10.1,16.8,5.1,16.8,0,16.8L0,16.8z
        M16.8,16.8c0,5.1,0,10.1,0,15.2H32c0-5.1,0-10.1,0-15.2C26.9,16.8,21.9,16.8,16.8,16.8L16.8,16.8z"
      />
    </g>
  )
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill={multicolor ? 'none' : 'currentColor'} {...restProps}>
      <svg focusable="false" version="1.1" viewBox="0 0 32 32">
        {multicolor ? multiColorIcon : icon}
      </svg>
    </svg>
  )
}

UIIconMicrosoft.displayName = 'UIIconMicrosoft'

export default UIIconMicrosoft
