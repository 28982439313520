import { useState, useEffect } from 'react'
import Select from '@veneer/core/dist/esm/scripts/select'
import TextBox from '@veneer/core/dist/esm/scripts/text_box'
import { useTranslation } from 'react-i18next'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { filterCountryLabels, filterCountryNumbers, generateCountryOptions } from './utils'
import { isPhoneString, getSelectI18n } from '../../util'
import { controlledOnBlur } from '../Controlled/utils'
import UICountryCode from './UICountryCode'
import * as S from './styles'

import './UIPhoneNumber.scss'

const unifyIsoCode = (phoneNumber, isoCode) => {
  const onlyDigitsNationalPhoneNumber = phoneNumber.formatNational().replace(/\D+/g, '')
  return parsePhoneNumberFromString(onlyDigitsNationalPhoneNumber, isoCode)
}

const UIPhoneNumber = (cProps) => {
  const {
    defaultIsoCode = 'US',
    defaultValue = '',
    locale = 'en_US',
    countryNumbers,
    error = '',
    displayDisclaimer = false,
    required = true,
    label = '',
    isRTL = false
  } = cProps

  const { t } = useTranslation()
  const [cursorPosition, setCursorPosition] = useState({ start: null, end: null })
  const [countryOptions, setCountryOptions] = useState([])
  const [filteredCountryOptions, setFilteredCountryOptions] = useState([])
  const [isoCode, setIsoCode] = useState(defaultIsoCode)
  const [phoneNumber, setPhoneNumber] = useState(defaultValue)
  const [lastKeyCode, setLastKeyCode] = useState(0)
  const [prevLength, setPrevLength] = useState(phoneNumber.length)
  const [isChanged, setIsChanged] = useState(true)

  const onInputSelect = (event) => {
    setCursorPosition({
      start: event.target.selectionStart,
      end: event.target.selectionEnd
    })
  }

  useEffect(() => {
    setPhoneNumber(new AsYouType(isoCode).input(phoneNumber))
    const onSetLastKeyCode = (event) => {
      setLastKeyCode(event.keyCode)
    }
    window.addEventListener('keydown', onSetLastKeyCode)

    return () => {
      window.removeEventListener('keydown', onSetLastKeyCode)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setIsChanged(false)
    cProps.clearErrors && cProps.clearErrors()
    if (phoneNumber !== '') {
      setValueToForm(phoneNumber, isoCode)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const filteredCountryNumbers = filterCountryNumbers(countryNumbers)
    let effectiveIsoCode = 'US'
    if (filteredCountryNumbers && filteredCountryNumbers[defaultIsoCode]) {
      effectiveIsoCode = defaultIsoCode
    }
    setIsoCode(effectiveIsoCode)
    const countryOptions = generateCountryOptions(
      filterCountryLabels(t('countries:values', { returnObjects: true })),
      filteredCountryNumbers
    )
    setCountryOptions(countryOptions)
    setFilteredCountryOptions(countryOptions)
  }, [locale, countryNumbers, defaultIsoCode, t])

  const onSearchHandle = (event) => {
    const containsInput = (country) => country.searchName.toUpperCase().includes(event.toUpperCase())
    setFilteredCountryOptions(countryOptions.filter((country) => containsInput(country)))
  }

  const setValueToForm = (phoneNumberValue, isoCodeValue) => {
    let fieldChangeFn = (_arg) => {
      // empty function in case the caller of UIPhoneNumber does not pass a valid function in
      // (defensive programming)
    }
    if (cProps.field && cProps.field.onChange) {
      fieldChangeFn = cProps.field.onChange
    }

    let value = parsePhoneNumberFromString(phoneNumberValue, isoCodeValue)
    if (value) {
      if (value.isValid()) {
        value = unifyIsoCode(value, isoCodeValue)
      }
      fieldChangeFn(value.number)
    } else if (isPhoneString(phoneNumberValue)) {
      fieldChangeFn(phoneNumberValue)
    } else {
      fieldChangeFn('')
      setPhoneNumber('')
    }
  }

  const formatPhoneNumber = (value, phoneNumber) => {
    setPhoneNumber(new AsYouType(value).input(phoneNumber))
    controlledOnBlur({ cProps, isChanged })
  }

  const onIsoCodeChange = ({ value }) => {
    setIsoCode(value)
    setValueToForm(phoneNumber, value)
    formatPhoneNumber(value, phoneNumber)
  }

  const onPhoneNumberChange = (value) => {
    const currentLength = value.length
    const cursorAtEnd = cursorPosition.start === phoneNumber.length
    // Let's check if the modified phone is the same as the initial one, minus the ")"
    const trimmedValue = value.replace(/[( -]/g, '')
    const trimmedPhone = phoneNumber.replace(/[() -]/g, '')
    // Yes, we have removed a ")"
    if (trimmedValue === trimmedPhone && value.length > 0) {
      // Are we doing a DELETE?
      if (lastKeyCode === 8) {
        // ...Then let's find the former place of ")" and delete one char before it
        const modelPhone = phoneNumber.replace(/[( -]/g, '')
        let parenPlace = modelPhone.indexOf(')')
        if (parenPlace === -1) {
          parenPlace = modelPhone.length
        }
        value = modelPhone.substr(0, parenPlace - 1) + modelPhone.substr(parenPlace + 1)
      } else if (lastKeyCode === 46) {
        // We are doing a SUPPR
        // So we should delete the next char after the ")"
        const modelPhone = phoneNumber.replace(/[( -]/g, '')
        const parenPlace = modelPhone.indexOf(')')
        value = modelPhone.substr(0, parenPlace) + modelPhone.substr(parenPlace + 2)
      }
    }

    if (!isChanged) setIsChanged(true)
    const isLengthIncreasing = currentLength > prevLength
    const isLengthDecreasing = currentLength < prevLength
    const isMiddleEditing = !cursorAtEnd && (isLengthDecreasing || isLengthIncreasing)
    // Only applying the asYouFormat if you are not editing the phone
    if (!isMiddleEditing) {
      setPhoneNumber(new AsYouType(isoCode).input(value))
    } else {
      const trimmedValue = value.trim()
      setPhoneNumber(trimmedValue)
    }
    setValueToForm(value, isoCode)
    setPrevLength(currentLength)
  }

  const getHelperText = () => {
    if (error) return error
    if (displayDisclaimer) return t('label.sign_up_mobile_number_subtitle')
    return ''
  }

  return (
    <S.UIPhoneNumber>
      <S.Select isRTL={isRTL}>
        <S.HideCountry>
          <Select
            className="selectCountryNumber"
            i18n={getSelectI18n(t)}
            id="isoCode"
            options={filteredCountryOptions}
            value={[isoCode]}
            clearIcon={false}
            onChange={onIsoCodeChange}
            showSearch
            onSearch={onSearchHandle}
            placeholder={(<UICountryCode />) as any}
            error={!!error}
            helperText={getHelperText() as any}
            css={{ width: '120px' }}
          />
        </S.HideCountry>
      </S.Select>
      <S.PhoneNumberField isRTL={isRTL}>
        <TextBox
          onSelect={onInputSelect}
          id="phoneNumber"
          name="phoneNumber"
          aria-label="phoneNumber"
          value={phoneNumber}
          label={label || t('label.placeholder_mobile_number')}
          onChange={onPhoneNumberChange}
          onBlur={() => {
            formatPhoneNumber(isoCode, phoneNumber)
          }}
          error={!!error}
          required={required}
        />
      </S.PhoneNumberField>
    </S.UIPhoneNumber>
  )
}

export default UIPhoneNumber
