import { UIIconGoogle, UIIconFacebook, UIIconWeChat, UIIconMicrosoft } from '../UIIcons'
import { LogoHp } from '@veneer/core/dist/esm/scripts/logos/hp'
import IconBuilding from '@veneer/core/dist/esm/scripts/icons/icon_building'

import { Wrapper } from './styles'
import { Trans } from 'react-i18next'

const iconComponents = {
  GooglePlus: UIIconGoogle,
  Facebook: UIIconFacebook,
  Azure: UIIconMicrosoft,
  WeChat: UIIconWeChat,
  OneHP: LogoHp,
  MSP: IconBuilding
}

const FederatedSignupMessage = ({ identityProvider }) => {
  const IconName = identityProvider && iconComponents[identityProvider.name]

  return (
    <Wrapper iconCode={identityProvider.icon}>
      <IconName multicolor={identityProvider.multicolor} filled />
      <p>
        <Trans
          i18nKey="label.federated_account"
          components={{ bold: <strong /> }}
          values={{ IdP: `<bold>${identityProvider?.displayName}</bold>` }}
        >
          You are using your {{ IdP: identityProvider?.displayName }} account to create and sign in to your new HP
          account.
        </Trans>
      </p>
    </Wrapper>
  )
}

export default FederatedSignupMessage
