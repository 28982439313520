import styled from '@emotion/styled'

export const TextWrapper = styled.p`
  margin-bottom: 20px;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 520px;
`

export const ProgressWrapper = styled.div`
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
`
