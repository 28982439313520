import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import './i18n'
import store, { persistor, history } from './store'
import App from './App'
import { UISpinner } from './component'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<UISpinner />} persistor={persistor}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);
