import styled from '@emotion/styled'

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const CheckMarkContainer = styled.div`
  margin-bottom: 20px;
`
export const TextContainer = styled.div`
  text-align: center;
  width: 100%;
`
