import { Control } from 'react-hook-form'
import { UISelect } from '..'
import { useTranslation } from 'react-i18next'

interface CountrySelectProps {
  errors: { countryResidence?: { message?: string } }
  control: Control
  onChange: () => void
  key: string
}

const CountrySelect = ({ errors, control, onChange, key }: CountrySelectProps) => {
  const { t } = useTranslation()

  return (
    <UISelect
      aria-label="selectCountry"
      id="countryResidence"
      placeholder={t('label.placeholder_country')}
      options={t('countries:values', { returnObjects: true })}
      onChange={onChange}
      error={!!errors.countryResidence}
      helperText={errors.countryResidence && t(errors.countryResidence.message)}
      visibleOptions={6}
      clearIcon={false}
      key={key}
      control={control}
    />
  )
}

export default CountrySelect
