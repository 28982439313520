import styled from '@emotion/styled'

export const RememberWrapper = styled.div<{hasFederatedAccounts?: boolean}>`
  margin-top: ${props => props.hasFederatedAccounts ? '20px' : '130px'};
  label > span.label.vn-checkbox__span--text {
    word-break: normal !important;
  }
`

export const ForgetUsername = styled.div<{isRTL?: boolean}>`
  margin: ${props => props.isRTL ? '16px' : '20px'} 0;
  display: flex;
  justify-content: center;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 20px;
`
export const FooterWrapper = styled.div`
  width: 100%;
`