import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'

interface SignUpProps {
  hasScrollbar?: boolean
  isIE?: boolean
  isFederated?: boolean
  hasMultipleTncs?: boolean
  hasMultipleTncsForFederated?: boolean
  formHasErrors?: boolean
  showPhoneField?: boolean
  isRTL?: boolean
}

const setFormHeight = (props, errorHeight) => {
  if (!props.isFederated && !props.showPhoneField) {
    if (props.formHasErrors && props.hasMultipleTncs) {
      return css`
        height: ${errorHeight + 45}px;
      `
    }
    return css`
      height: ${props.formHasErrors ? `${errorHeight}px` : '420px'};
    `
  }
}

// This height represents the maximum size the SignUp component can have before
// triggering the default MainLayout Scrollbar
export const SignUpForm = styled.form<SignUpProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.hasMultipleTncsForFederated && !props.showPhoneField ? '575px' : '534px')};
  ${(props) =>
    !props.isIE &&
    css`
      position: relative;
    `}
  margin-right: ${(props) => (props.hasScrollbar ? '-17px' : '')};
  @media all and (max-height: 754px) and (max-width: 661px) {
    ${(props) => setFormHeight(props, 445)}
  }

  // Iphone SE and Iphone 14 media-queries
  @media all and (max-height: 667px) and (max-width: 661px) {
    ${(props) => setFormHeight(props, 465)}
  }
`

export const FormFields = styled(Scrollbar)<SignUpProps>`
  ${(props) => !props.showPhoneField && 'flex: 1;'}
  > * {
    margin-top: ${(props) => (props.hasMultipleTncsForFederated ? '10px' : '20px')};
  }
  > div:nth-of-type(1) {
    margin-top: 0px;
  }
  padding-right: ${(props) => (props.hasScrollbar ? '9px' : '')};
  @media all and (max-width: 754px) {
    ${(props) => props.showPhoneField && `height: ${props.hasMultipleTncs ? '64%' : '74%'} !important;`}
  }
  ${(props) => props.showPhoneField && `height: ${props.hasMultipleTncs ? '72%' : '80%'} !important;`}
  ${(props) =>
    props.showPhoneField && props.isFederated && `height: ${props.hasMultipleTncs ? '60%' : '74%'} !important;`}

  ${(props) =>
    !props.isIE &&
    css`
      label {
        position: absolute;
      }
    `}
`

export const FormFooter = styled.div<SignUpProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-top: 20px;
  }
  > div:nth-of-type(1) {
    margin-top: 10px;
  }

  ${(props) =>
    props.isRTL &&
    `
    button {
      margin-top: 20px;
    }
  `}
  padding-right: ${(props) => (props.hasScrollbar ? '17px' : '')};
  ${(props) => props.showPhoneField && !props.isIE && 'height: 60px;'}
`

export const NamesContainer = styled.div<SignUpProps>`
  display: flex;
  > div:nth-child(1) {
    ${(props) => (props.isRTL ? 'margin-left' : 'margin-right')} : 16px;
  }
`

export const UsernameTypeContainer = styled.div`
  #switch-username:focus-visible {
    margin-right: 5px;
  }
`;

export const NameInput = styled.div<SignUpProps>`
  width: 100%;
  > div > div {
    min-width: 64px;
  }
`

export const CheckBoxWrapper = styled.div`
  position: relative;
`

export const CheckBoxConsentWorkaround = styled.div`
  label > span.label.vn-checkbox__span--text {
    word-break: normal !important;
  }
`
