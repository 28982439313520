import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@veneer/core/dist/esm/scripts/select'
import { Controller } from 'react-hook-form'
import { getSelectI18n } from '../../../util'
import { controlledOnChange } from '../utils'


interface UISelectProps  {
  id: string,
  options: any,
  control: any,
  rules?: any,
  filter?:  { (): void },
  onChange?:  { (): void },
}

const UISelect = (props: UISelectProps) => {
  const { id, options, control, rules, onChange, filter } = props
  const [optionsWithFilter, setOptionsWithFilter] = useState(options)
  const [isChanged, setIsChanged] = useState(false)
  const { t } = useTranslation()

  const onSearchHandle = (value) => {
    const containsInput = (option) => (
      option.value.toUpperCase().includes(value.toUpperCase()) ||
      option.label.toUpperCase().includes(value.toUpperCase())
    )
    setOptionsWithFilter(options.filter((option) => containsInput(option)))
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={(cProps) => {
        return (
          <Select
            {...cProps}
            {...props}
            options={optionsWithFilter}
            value={[cProps.field.value]}
            showSearch
            i18n={getSelectI18n(t)}
            onSearch={onSearchHandle}
            onChange={({ value }) => {
              if (!isChanged) setIsChanged(true)
              controlledOnChange({ cProps, value, filter, onChange })
            }}
          />
        )
      }}
    />
  )
}

export default UISelect
