import styled from '@emotion/styled'

export const PageContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const LoadingContainer = styled.div`
  margin-top: 22px;
  padding-bottom: 10px;
`
export const TextContainer = styled.div`
  margin-top: 22px;
  text-align: center;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`
