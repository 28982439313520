import styled from '@emotion/styled'

export const BodyContainer = styled.div`
  flex: 1;
`
export const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
`
export const BackLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
`
export const UsernameLabel = styled.p`
  display: flex;
  align-items: center;
  padding-top: 2px;
  font-weight: bold;
  direction: ltr;
`
export const ForgotPasswordContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

export const PasswordFormFieldContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`
