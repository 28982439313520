import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Wrapper = styled.div<{ iconCode?: string }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
  ${(props) =>
    props.iconCode === 'hp' &&
    css`
      img {
        width: 18.97px;
        height: 30px;
        margin-right: 4px;
      }
    `}
`
