import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultHeader, MainLayout, ManualRedirect, AutoRedirect } from '../../component'
import { nextUrlRetryBuilder, sleep } from '../../util'
import { BASE_URL, REDIRECT_LINK_TIMEOUT } from '../../constants'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

const Redirect = () => {
  const dispatch = useDispatch()
  const location: any = useLocation()
  const [timer, setTimer] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const nextUrl = location.state ? location.state.nextUrl : BASE_URL
  const nextUrlRetry = nextUrlRetryBuilder(nextUrl)
  const { autoredirect, expiredInteraction } = useSelector((state: State) => state.user)

  useEffect(() => {
    async function changeTime() {
      await sleep(REDIRECT_LINK_TIMEOUT)
      await setTimer(true)
    }
    if (autoredirect === 'false' && expiredInteraction) {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT24'))
    } else {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT25'))
      changeTime()
      window.location.assign(nextUrl)
    }
    // Omitting 'expiredInteraction' from the dependency array to prevent unintended re-triggering of this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoredirect, nextUrl, dispatch.udl])

  const redirectManually = async () => {
    setIsDisabled(true)
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT26'))
    await dispatch.session.sendUIEvents('continue-button-clicked-manual-redirect')
    window.location.assign(nextUrl)
  }

  const redirectWait = async () => {
    setIsDisabled(true)
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT28'))
    await dispatch.session.sendUIEvents('continue-button-clicked-auto-redirect-failed')
    window.location.assign(nextUrlRetry)
  }

  return (
    <MainLayout css={{ backgroundColor: 0xff }}>
      <DefaultHeader />
      {autoredirect === 'false' && expiredInteraction ? (
        <ManualRedirect
          isDisabled={isDisabled}
          autoredirect={autoredirect}
          expiredInteraction={expiredInteraction}
          redirectManually={redirectManually}
        />
      ) : (
        <AutoRedirect timer={timer} isDisabled={isDisabled} redirectWait={redirectWait} />
      )}
    </MainLayout>
  )
}

export default Redirect
