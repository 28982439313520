import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button'
import {
  ARKOSE_SELECTORS,
  PAGE_EMAIL_OR_PHONE_ASSOCIATED,
  IS_PRISTINE,
  PARAM_ALLOW_RETURN,
  SUPPORT_HPID
} from '../../constants'
import { UIPassword, UILink } from '../../component'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'

import * as S from './style'

const PasswordForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useSelector((state: State) => state)
  const { session } = useSelector((state: State) => state)
  const { login } = useSelector((state: State) => state.loading.effects.user)
  const [isLoading, setLoading] = useState(false)
  const previousPage = location.pathname

  const defaultValues = { password: '' }
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })
  const isButtonDisabled = errors.password && errors.password.type === 'weakPassword'
  const submitSelector = ARKOSE_SELECTORS.SIGN_IN.PASSWORD

  const handleChangePassword = async ({ recoveryInput }) => {
    setLoading(true)
    const options = { setLoading, setError, recoveryInput, previousPage }
    const arkoseParams = {
      options,
      onHide: () => setLoading(false),
      callback: dispatch.user.handlePasswordRecovery,
      submitSelector,
      formErrors: errors
    }
    await dispatch.user.handlePasswordRecovery({
      options: { ...options, arkoseParams }
    })
  }

  const setWeakPasswordError = () => (
    <Trans i18nKey="label.password_change">
      For security reasons, you must change your password.
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT181'))
          handleChangePassword({ recoveryInput: user.username })
        }}
        error
        id="select-here-to-continue"
      >
        Select here to continue.
      </UILink>
    </Trans>
  )

  const submitLogin = async ({ password }) => {
    setLoading(true)
    const options = { setLoading, setError, password, setWeakPasswordError }
    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.login,
      onHide: () => setLoading(false),
      formErrors: errors
    }
    await dispatch.user.login({ options: { ...options, arkoseParams } })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT73'))
    handleSubmit(submitLogin)()
  }

  useEffect(() => {
    if (errors.password && errors.password.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT76'))
    }
  }, [dispatch.udl, errors.password])

  const handleForgotPassword = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT74'))
    dispatch.user.update({ [IS_PRISTINE]: false })
    navigate(PAGE_EMAIL_OR_PHONE_ASSOCIATED)
  }

  return (
    <>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <S.PasswordFormFieldContainer>
          <UIPassword
            id="password"
            aria-label="password"
            label={t('label.placeholder_password')}
            error={!!errors.password}
            helperText={
              errors.password &&
              (errors.password.type === 'weakPassword' ? setWeakPasswordError() : t(errors.password.message))
            }
            autoFocus
            control={control}
            rules={{ required: 'form.err_password_empty' }}
          />
        </S.PasswordFormFieldContainer>
        <S.PasswordFormFieldContainer>
          <Button
            aria-label="submit-button"
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading || login}
            disabled={isButtonDisabled}
            expanded
          >
            {t('button.sign_in_2')}
          </Button>
        </S.PasswordFormFieldContainer>
      </form>
      {user[PARAM_ALLOW_RETURN] && (
        <div>
          <Button
            tabIndex={0}
            id="back-link"
            appearance="ghost"
            onClick={() => dispatch.user.redirectToAPP()}
            expanded
          >
            {t('link.not_now')}
          </Button>
        </div>
      )}
      {!session?.client?.[SUPPORT_HPID] ? null : (
        <S.ForgotPasswordContainer>
          <S.FooterWrapper>
            <Button
              id="forgot-password"
              aria-label="forgot-password"
              appearance="ghost"
              onClick={handleForgotPassword}
              expanded
              disabled={isButtonDisabled}
            >
              {t('link.forgot_password')}
            </Button>
          </S.FooterWrapper>
        </S.ForgotPasswordContainer>
      )}
    </>
  )
}

export default PasswordForm
