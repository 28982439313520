import styled from '@emotion/styled'

export const UsernameRecoveryText = styled.p`
  margin-bottom: 20px;
`
export const ButtonContainer = styled.div`
  margin-top: 40px;
`
export const BackLinkContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`
