import styled from '@emotion/styled'
import tokens from '@veneer/tokens/dist/tokens'

const { layout } = tokens
const { mdMin, lgMin, xlMin } = layout

type UIThemeProviderProps = {
  backgroundUrl?: string
  alignItens?: string
}

export const UIThemeProvider = styled.div`
  background: ${(props: UIThemeProviderProps) =>
    props.backgroundUrl ? `url(${props.backgroundUrl}) no-repeat center` : 'none'};
  display: flex;
  justify-content: center;
  align-items: 'center';
  min-height: 100%;
`

const height = 754;
const xMin = '661px';

export const AppWrapper = styled.div`
  @media all and (max-height: ${height}px) and (max-width: ${xMin}) {
    width: 100%;
  }
`

export const ThemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props: UIThemeProviderProps) => props.alignItens};
  box-sizing: border-box;
  width: 100%;

  @media (min-width: ${mdMin}) {
    max-width: 720px;
  }

  @media (min-width: ${lgMin}) {
    max-width: 960px;
  }

  @media (min-width: ${xlMin}) {
    max-width: 1140px;
  }
`
