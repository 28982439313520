import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { TncLink } from '../../../../common/types'
import udlEvents from '../../../../common/udlEvents'

interface SingleTnCProps {
  tncLink: {
    props: TncLink
  }
}

const SingleTnC = ({ tncLink }: SingleTnCProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addSpace = ' '
  const isDot = t('link.single_tnc_fix_3')
  const conditionalSpace = isDot !== undefined && isDot.length > 1 ? addSpace : ''

  const triggerUDLEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT22'))
  }

  return (
    <>
      <Trans i18nKey="link.single_tnc_fix_1">
        <a
          id="privacy_link"
          tabIndex={0}
          href={t('link.privacy_url')}
          onClick={triggerUDLEvent}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('link.privacy_url_label')}
        </a>
      </Trans>
      {addSpace}
      <Trans i18nKey="link.single_tnc_fix_2" />
      {addSpace}
      {tncLink}
      {conditionalSpace}
      <Trans i18nKey="link.single_tnc_fix_3" />
    </>
  )
}

export default SingleTnC
