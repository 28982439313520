import { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import Checkbox from '@veneer/core/dist/esm/scripts/checkbox'
import Button from '@veneer/core/dist/esm/scripts/button'
import { MainLayout, DefaultHeader, UISpinner } from '../../component'
import { State } from '../../common/types'
import { handleCheckboxOnKeyDown } from '../../util'
import udlEvents from '../../common/udlEvents'

import * as S from './Styles'

const ConsentManagement = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const session = useSelector((state: State) => state.session)
  const loading = useSelector((state: State) => state.loading.effects.session.getConsent)
  const [isLoading, setLoading] = useState(false)
  const initCheckboxes = () => {
    const list = []
    session &&
      session.scopes &&
      session.scopes.forEach((scope) => {
        if (scope.optional && list.indexOf(scope.name) === -1) {
          list.push(scope.name)
        }
      })
    return list
  }

  const [checkedItems, setCheckedItems] = useState(initCheckboxes())
  const APPNAME = session.client && session.client.displayName

  const handleScopeChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    // Tracking UDL events for set/unset consents checkboxes
    if (e.target.checked) {
      dispatch.udl.trackEvent(
        udlEvents.getEventWithObject('EVENT61', {
          linkPlacement: 'consent-management',
          linkID: `consent-${key}-checkbox-set`
        })
      )
    } else {
      dispatch.udl.trackEvent(
        udlEvents.getEventWithObject('EVENT61', {
          linkPlacement: 'consent-management',
          linkID: `consent-${key}-checkbox-unset`
        })
      )
    }
    if (checkedItems.includes(key)) {
      setCheckedItems(checkedItems.filter((arg) => arg !== key))
    } else {
      setCheckedItems([...checkedItems, key])
    }
  }

  const getLabel = (name: string) => {
    switch (name) {
      case 'email':
        return t('label.consent_email')
      case 'openid':
        return t('label.consent_openid')
      case 'profile':
        return t('label.consent_profile_basic')
      case 'user.profile.read':
        return t('label.consent_profile_read')
      case 'user.profile.write':
        return t('label.consent_profile_write')
      case 'offline_access':
        return t('label.consent_offline_access')
      default:
        return name
    }
  }

  const getSubTitle = () => {
    return (
      <Trans i18nKey="label.consent_sub_title" components={[<strong key={0}>{{ APPNAME: APPNAME }}</strong>]} />
    )
  }

  const approveConsentFlow = (approved: boolean) => async () => {
    // Tracking UDL events for accept/cancel buttons
    const linkID = approved ? 'accept-button-click' : 'cancel-button-click'
    dispatch.udl.trackEvent(
      udlEvents.getEventWithObject('EVENT61', {
        linkPlacement: 'consent-management',
        linkID
      })
    )
    setLoading(true)
    dispatch.session.approveConsentFlow({ approved, optionalScopes: checkedItems, options: { setLoading } })
  }

  const getContent = () => {
    const renderList = []
    const scopes = session && session.scopes
    session.scopes.sort((a: any, b: any) => a.optional - b.optional)
    scopes &&
      scopes.forEach((scope) => {
        renderList.push(
          <S.CheckboxContent>
            <div key={`listItem_${scope.name}`}>
              <Checkbox
                id={scope.name}
                name={scope.name}
                label={getLabel(scope.name)}
                onChange={(e) => handleScopeChange(scope.name, e)}
                disabled={!scope.optional}
                checked={checkedItems.includes(scope.name) || !scope.optional}
                onKeyDown={handleCheckboxOnKeyDown}
              />
            </div>
          </S.CheckboxContent>
        )
      })
    return renderList
  }

  useEffect(() => {
    dispatch.session.getConsent()
  }, [dispatch.session])

  useEffect(() => {
    setCheckedItems(initCheckboxes())
    // eslint-disable-next-line
  }, [session.scopes])

  if (loading) {
    return <UISpinner />
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.privacy_consent')} />
      <div>{getSubTitle()}</div>
      <form>
        <S.PageContent>
          <S.CheckboxContainer>{getContent()}</S.CheckboxContainer>
          <S.ConsentAccept>
            <div>
              <Trans i18nKey="label.consent_accept" components={{bold: <strong />}} values={{ APPNAME: `<bold>${APPNAME}</bold>`}} />
            </div>
          </S.ConsentAccept>
          <S.ButtonConsent>
            <Button
              id="accept"
              expanded={true}
              name="accept"
              onClick={approveConsentFlow(true)}
              loading={isLoading}
            >
              {t('button_capitalize.accept')}
            </Button>
          </S.ButtonConsent>
          <S.ButtonConsent>
            <Button
              id="cancel"
              expanded={true}
              name="cancel"
              onClick={approveConsentFlow(false)}
              appearance="ghost"
            >
              {t('button_capitalize.cancel')}
            </Button>
          </S.ButtonConsent>
        </S.PageContent>
      </form>
    </MainLayout>
  )
}

export default ConsentManagement
