/*
  We should have a definition of IDs for each event.
  For now, let's give them a generic name.
*/

const EVENTS = {
  EVENT00: { event: 'e_formSubmit' },
  EVENT01: { event: 'e_formSubmit', formName: 'reset-password-by-mobile-form-submit' },
  EVENT02: { event: 'e_formSubmit', formName: 'forgot-password-by-mobile-form-submit' },
  EVENT03: { event: 'e_userLogin' },
  EVENT04: { event: 'e_formSubmit', formName: 'sign-up-hpid-success-form-submit' },
  EVENT05: { event: 'e_formSubmit', formName: 'username-recovery-form-submit' },
  EVENT06: { event: 'e_formSubmit', formName: 'forgot-password-by-email-form-submit' },
  EVENT08: { event: 'e_linkClick', linkPlacement: 'forgot-password', linkID: 'request-new-code-button-click' },
  EVENT09: { event: 'e_formSubmit', formName: 'reset-password-form-submit' },
  EVENT11: { event: 'e_formSubmit', formName: 'phone-verification-by-email-sign-in-form-submit' },
  EVENT12: { event: 'e_formSubmit', formName: 'phone-verification-sign-in-form-submit' },
  EVENT13: { event: 'e_formSubmit', formName: 'verify-by-email-sign-up-form-submit' },
  EVENT14: { event: 'e_formSubmit', formName: 'verify-by-phone-sign-up-form-submit' },
  EVENT15: { event: 'e_formSubmit', formName: 'email-verification-sign-in-form-submit' },
  EVENT16: { event: 'e_internal', action: 'verified-email-link', label: 'automatic' },
  EVENT17: { event: 'e_formSubmit', formName: 'phone-verification-sms-form-submit' },
  EVENT18: { event: 'e_formError', formName: 'email-verification', formError: 'incorrect-expired-code-error' },
  EVENT19: { event: 'e_formError', formName: 'phone-verification', formError: 'incorrect-expired-code-error' },
  EVENT20: { event: 'e_formError', formName: 'forgot-password', formError: 'incorrect-expired-code-error' },
  EVENT21: { event: 'e_linkClick', linkPlacement: 'footer', linkID: 'privacy-link-click' },
  EVENT22: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'privacy-stat-link-click' },
  EVENT23: { event: 'e_internal', action: 'page-time-out', label: 'user-sign-out' },
  EVENT24: { event: 'e_internal', action: 'redirect', label: 'manual' },
  EVENT25: { event: 'e_internal', action: 'redirect', label: 'automatic' },
  EVENT26: { event: 'e_linkClick', linkPlacement: 'redirect', linkID: 'continue-button-before-redirect-click' },
  EVENT27: { event: 'e_internal', action: 'redirect', label: 'redirect-link-rendered' },
  EVENT28: { event: 'e_linkClick', linkPlacement: 'redirect', linkID: 'continue-button-after-redirect-click' },
  EVENT29: { event: 'e_linkClick', linkPlacement: 'phone-verification', linkID: 'resend-code-link-click' },
  EVENT30: { event: 'e_linkClick', linkPlacement: 'mobile-code-send', linkID: 'edit-mobile-number-link-click' },
  EVENT31: { event: 'e_formSubmit', formName: 'username-type-email-sign-in-with-email-form-submit' },
  EVENT32: { event: 'e_formSubmit', formName: 'username-type-email-sign-in-with-mobile-form-submit' },
  EVENT33: { event: 'e_formSubmit', formName: 'username-type-mobile-sign-in-with-mobile-form-submit' },
  EVENT34: { event: 'e_formSubmit', formName: 'username-type-mobile-sign-in-with-email-form-submit' },
  EVENT35: { event: 'e_linkClick', linkPlacement: 'email-code-send', linkID: 'send-email-button-click' },
  EVENT36: { event: 'e_linkClick', linkPlacement: 'email-code-send', linkID: 'edit-email-address-link-click' },
  EVENT37: { event: 'e_internal', action: 'redirect', label: 'verification-success' },
  EVENT38: { event: 'e_linkClick', linkPlacement: 'verification-success', linkID: 'select-here-link-click' },
  EVENT39: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'already-account-signin-link-click' },
  EVENT40: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'terms-and-conditions-link-click' },
  EVENT41: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'not-now-link-click' },
  EVENT42: { event: 'e_formSubmit', formName: 'username-type-email-sign-up-with-email-form-submit' },
  EVENT43: { event: 'e_formSubmit', formName: 'username-type-email-sign-up-with-mobile-form-submit' },
  EVENT44: { event: 'e_formSubmit', formName: 'username-type-mobile-sign-up-with-mobile-form-submit' },
  EVENT45: { event: 'e_formSubmit', formName: 'username-type-mobile-sign-up-with-email-form-submit' },
  EVENT46: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'create-account-button-click' },
  EVENT47: { event: 'e_formSubmit', formName: 'complete-account-not-changed-form-submit' },
  EVENT48: { event: 'e_formSubmit', formName: 'complete-account-email-added-form-submit' },
  EVENT49: { event: 'e_formSubmit', formName: 'complete-account-mobile-added-form-submit' },
  EVENT50: { event: 'e_formSubmit', formName: 'complete-account-accept-mark-consent-form-submit' },
  EVENT51: { event: 'e_formSubmit', formName: 'complete-account-reject-mark-consent-form-submit' },
  EVENT52: { event: 'e_linkClick', linkPlacement: 'phone-verification', linkID: 'change-phone-number-link-click' },
  EVENT53: { event: 'e_linkClick', linkPlacement: 'email-verification', linkID: 'edit-email-address-link-click' },
  EVENT54: { event: 'e_linkClick', linkPlacement: 'email-verification', linkID: 'submit-code-button-click' },
  EVENT56: { event: 'e_linkClick', linkPlacement: 'email-verification', linkID: 'resend-code-link-click' },
  EVENT57: { event: 'e_linkClick', linkPlacement: 'password', linkID: 'back-button-click' },
  EVENT58: { event: 'e_linkClick', linkPlacement: 'verification', linkID: 'verify-arkose-button-click' },
  EVENT59: { event: 'e_linkClick', linkPlacement: 'check-username', linkID: 'back-button-click' },
  EVENT60: { event: 'e_linkClick', linkPlacement: 'redirect-idp', linkID: 'back-button-click' },
  EVENT61: { event: 'e_linkClick' },
  EVENT62: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'use-mobile-number-link-click' },
  EVENT63: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'use-email-address-link-click' },
  EVENT64: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'next-button-click' },
  EVENT65: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'remember-me-checkbox-set' },
  EVENT66: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'remember-me-checkbox-unset' },
  EVENT67: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'signup-button-click' },
  EVENT68: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'forgot-username-link-click' },
  EVENT69: { event: 'e_formError', formName: 'username', formError: 'enter-username-error' },
  EVENT70: { event: 'e_formError', formName: 'username', formError: 'enter-mobile-number-error' },
  EVENT71: { event: 'e_formError', formName: 'username', formError: 'account-not-found-email-error' },
  EVENT72: {
    event: 'e_formError',
    formName: 'username',
    formError: 'account-not-found-try-username-or-email-error'
  },
  EVENT73: { event: 'e_linkClick', linkPlacement: 'password', linkID: 'signin-button-click' },
  EVENT74: { event: 'e_linkClick', linkPlacement: 'password', linkID: 'forgot-password-link-click' },
  EVENT75: { event: 'e_formError', formName: 'password', formError: 'invalid-username-password-error' },
  EVENT76: { event: 'e_formError', formName: 'password', formError: 'enter-password-error' },
  EVENT77: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'not-now-link-click' },
  EVENT78: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'use-mobile-number-link-click' },
  EVENT79: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'use-email-address-link-click' },
  EVENT80: { event: 'e_formError', formName: 'sign-up', formError: 'account-already-exist-error' },
  EVENT81: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'account-already-exist-signin-link-click' },
  EVENT82: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'consent-checkbox-set' },
  EVENT83: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'consent-checkbox-unset' },
  EVENT84: { event: 'e_linkClick', linkPlacement: 'complete-account', linkID: 'consent-checkbox-set' },
  EVENT85: { event: 'e_linkClick', linkPlacement: 'complete-account', linkID: 'consent-checkbox-unset' },
  EVENT86: { event: 'e_linkClick', linkPlacement: 'complete-account', linkID: 'continue-button-click' },
  EVENT87: { event: 'e_formError', formName: 'complete-account', formError: 'email-in-use-error' },
  EVENT88: { event: 'e_formError', formName: 'complete-account', formError: 'mobile-in-use-error' },
  EVENT89: { event: 'e_formError', formName: 'complete-account', formError: 'hp-domain-cannot-be-used-error' },
  EVENT90: { event: 'e_linkClick', linkPlacement: 'phone-verification', linkID: 'verify-button-click' },
  EVENT91: { event: 'e_linkClick', linkPlacement: 'phone-verification', linkID: 'verify-by-email-link-click' },
  EVENT92: { event: 'e_formError', formName: 'edit-email', formError: 'too-many-requests-error' },
  EVENT93: {
    event: 'e_linkClick',
    linkPlacement: 'forgot-password-confirm-username',
    linkID: 'cancel-button-click'
  },
  EVENT94: {
    event: 'e_linkClick',
    linkPlacement: 'forgot-password-confirm-username',
    linkID: 'continue-button-click'
  },
  EVENT95: {
    event: 'e_linkClick',
    linkPlacement: 'forgot-password-confirm-username',
    linkID: 'use-email-address-link-click'
  },
  EVENT96: {
    event: 'e_linkClick',
    linkPlacement: 'forgot-password-confirm-username',
    linkID: 'use-mobile-number-link-click'
  },
  EVENT97: {
    event: 'e_formError',
    formName: 'forgot-password-confirm-username',
    formError: 'enter-username-error'
  },
  EVENT98: {
    event: 'e_formError',
    formName: 'forgot-password-confirm-username',
    formError: 'hp-domain-cannot-be-used-error'
  },
  EVENT99: {
    event: 'e_formError',
    formName: 'forgot-password-confirm-username',
    formError: 'username-not-found-error'
  },
  EVENT100: {
    event: 'e_formError',
    formName: 'forgot-password-confirm-username',
    formError: 'phone-number-not-valid-error'
  },
  EVENT101: { event: 'e_linkClick', linkPlacement: 'username-recovery', linkID: 'next-button-click' },
  EVENT102: { event: 'e_linkClick', linkPlacement: 'username-recovery', linkID: 'back-button-click' },
  EVENT103: { event: 'e_formError', formName: 'username-recovery', formError: 'email-address-not-found-error' },
  EVENT104: { event: 'e_formError', formName: 'username-recovery', formError: 'enter-email-address-error' },
  EVENT105: { event: 'e_formError', formName: 'username-recovery', formError: 'hp-domain-cannot-be-used-error' },
  EVENT106: { event: 'e_linkClick', linkPlacement: 'mobile-code-send', linkID: 'send-sms-button-click' },
  EVENT107: { event: 'e_linkClick', linkPlacement: 'mobile-code-send', linkID: 'verify-by-email-link-click' },
  EVENT108: { event: 'e_formError', formName: 'mobile-code-send', formError: 'too-many-requests-error' },
  EVENT109: { event: 'e_formError', formName: 'forgot-password', formError: 're-enter-pass-error' },
  EVENT110: { event: 'e_formError', formName: 'forgot-password', formError: 'pass-not-match-error' },
  EVENT111: { event: 'e_linkClick', linkPlacement: 'forgot-password', linkID: 'confirm-button-click' },
  EVENT112: { event: 'e_formError', formName: 'forgot-password', formError: 'enter-verification-code-error' },
  EVENT113: { event: 'e_formError', formName: 'forgot-password', formError: 'check-pass-requirements-error' },
  EVENT114: { event: 'e_linkClick', linkPlacement: 'edit-mobile', linkID: 'back-button-click' },
  EVENT115: { event: 'e_linkClick', linkPlacement: 'edit-mobile', linkID: 'next-button-click' },
  EVENT116: { event: 'e_formError', formName: 'edit-mobile', formError: 'mobile-number-not-available-error' },
  EVENT117: { event: 'e_formError', formName: 'edit-mobile', formError: 'too-many-requests-error' },
  EVENT118: { event: 'e_linkClick', linkPlacement: 'change-password', linkID: 'submit-button-click' },
  EVENT119: { event: 'e_formError', formName: 'change-password', formError: 'check-pass-requirements-error' },
  EVENT120: { event: 'e_formError', formName: 'change-password', formError: 're-enter-pass-error' },
  EVENT121: { event: 'e_formError', formName: 'change-password', formError: 'pass-not-match-error' },
  EVENT122: { event: 'e_linkClick', linkPlacement: 'edit-email', linkID: 'next-button-click' },
  EVENT123: { event: 'e_linkClick', linkPlacement: 'edit-email', linkID: 'back-button-click' },
  EVENT124: { event: 'e_formError', formName: 'edit-email', formError: 'username-not-available-error' },
  EVENT125: { event: 'e_formSubmit', formName: 'sign-up-accept-mark-consent-form-submit' },
  EVENT126: { event: 'e_formSubmit', formName: 'sign-up-reject-mark-consent-form-submit' },
  EVENT127: { event: 'e_linkClick', linkPlacement: 'reset-success', linkID: 'manual-success-redirect-click' },
  EVENT128: { event: 'e_formError', formName: 'sign-up', formError: 'leaked-password-error' },
  EVENT129: { event: 'e_formError', formName: 'forgot-password', formError: 'pass-cannot-be-used-error' },
  EVENT130: { event: 'e_formError', formName: 'change-password', formError: 'pass-cannot-be-used-error' },
  EVENT131: { event: 'e_formError', formName: 'password', formError: 'leaked-password-error' },
  EVENT132: { event: 'e_linkClick', linkPlacement: 'password', linkID: 'change-leaked-password-link-click' },
  EVENT133: { event: 'e_linkClick', linkPlacement: 'add-email', linkID: 'continue-button-click' },
  EVENT134: { event: 'e_linkClick', linkPlacement: 'add-email', linkID: 'back-button-click' },
  EVENT135: { event: 'e_formError', formName: 'add-email', formError: 'username-not-available-error' },
  EVENT136: { event: 'e_linkClick', linkPlacement: 'username-recovery-success', linkID: 'go-signin-button-click' },
  EVENT137: { event: 'e_linkClick', linkPlacement: 'error', linkID: 'copy-ids-link-click' },
  EVENT138: { event: 'e_formError', formName: 'mfa-auth-code-send', formError: 'too-many-requests-error' },
  EVENT139: {
    event: 'e_formError',
    formName: 'mfa-auth-recovery-verification',
    formError: 'too-many-requests-error'
  },
  EVENT140: { event: 'e_internal', action: 'show-warning-message', label: 'ie-update-warning-message' },
  EVENT141: { event: 'e_internal', action: 'get-query-parameter', label: 'otp-code' },
  EVENT142: { event: 'e_internal', action: 'redirect', label: 'login-hint-target-password' },
  EVENT143: { event: 'e_linkClick', linkPlacement: 'oobe-sign-up', linkID: 'create-account-button-click' },
  EVENT144: { event: 'e_formSubmit', formName: 'oobe-sign-up-form-submit' },
  EVENT145: { event: 'e_formError', formName: 'oobe-sign-up', formError: 'account-already-exist-error' },
  EVENT146: { event: 'e_formError', formName: 'oobe-sign-up', formError: 'hp-domain-cannot-be-used-error' },
  EVENT147: { event: 'e_formError', formName: 'oobe-sign-up', formError: 'enter-email-address-error' },
  EVENT148: { event: 'e_formError', formName: 'oobe-sign-up', formError: 'check-pass-requirements-error' },
  EVENT149: { event: 'e_formError', formName: 'oobe-sign-up', formError: 'enter-password-error' },
  EVENT150: { event: 'e_linkClick', linkPlacement: 'oobe-sign-up', linkID: 'consent-checkbox-set' },
  EVENT151: { event: 'e_linkClick', linkPlacement: 'oobe-sign-up', linkID: 'consent-checkbox-unset' },
  EVENT152: {
    event: 'e_linkClick',
    linkPlacement: 'oobe-sign-up',
    linkID: 'account-already-exist-signin-link-click'
  },
  EVENT153: { event: 'e_linkClick', linkPlacement: 'full-tnc-view', linkID: 'accept-button-click' },
  EVENT154: { event: 'e_linkClick', linkPlacement: 'full-tnc-view', linkID: 'decline-button-click' },
  EVENT155: {
    event: 'e_formError',
    formName: 'forgot-password-confirm-username',
    formError: 'too-many-requests-error'
  },
  EVENT156: { event: 'e_formError', formName: 'code-send', formError: 'too-many-requests-error' },
  EVENT157: { event: 'e_formError', formName: 'phone-verification', formError: 'too-many-requests-error' },
  EVENT158: { event: 'e_linkClick', linkPlacement: 'mfa-auth-code-send', linkID: 'email-option-click' },
  EVENT159: { event: 'e_linkClick', linkPlacement: 'mfa-auth-code-send', linkID: 'phone-option-click' },
  EVENT160: { event: 'e_linkClick', linkPlacement: 'mfa-auth-code-send', linkID: 'back-button-click' },
  EVENT161: { event: 'e_linkClick', linkPlacement: 'mfa-auth-code-send', linkID: 'send-code-button-click' },
  EVENT162: { event: 'e_formError', formName: 'email-verification', formError: 'too-many-requests-error' },
  EVENT163: {
    event: 'e_linkClick',
    linkPlacement: 'mfa-auth-recovery-verification',
    linkID: 'resend-recovery-code-link-click'
  },
  EVENT164: {
    event: 'e_formError',
    formName: 'mfa-auth-recovery-verification',
    formError: 'incorrect-recovery-code-error'
  },
  EVENT165: { event: 'e_linkClick', linkPlacement: 'mfa-auth-recovery-verification', linkID: 'next-button-click' },
  EVENT166: { event: 'e_linkClick', linkPlacement: 'mfa-auth-unverified', linkID: 'back-button-click' },
  EVENT167: { event: 'e_formError', formName: 'username-recovery', formError: 'too-many-requests-error' },
  EVENT168: { event: 'e_linkClick', linkPlacement: 'mfa-auth', linkID: 'verify-button-click' },
  EVENT169: { event: 'e_linkClick', linkPlacement: 'mfa-auth', linkID: 'back-button-click' },
  EVENT170: { event: 'e_linkClick', linkPlacement: 'mfa-auth', linkID: 'dont-have-code-button-click' },
  EVENT171: { event: 'e_formError', formName: 'mfa-auth', formError: 'incorrect-expired-code-error' },
  EVENT172: { event: 'e_linkClick', linkPlacement: 'tnc-management', linkID: 'accept-button-click' },
  EVENT173: { event: 'e_linkClick', linkPlacement: 'tnc-management', linkID: 'decline-button-click' },
  EVENT174: { event: 'e_linkClick', linkPlacement: 'tnc-management', linkID: 'view-full-terms-link-click' },
  EVENT175: { event: 'e_formSubmit' },
  EVENT176: {
    event: 'e_formError',
    formName: 'phone-verification',
    formError: 'phone-verification-send-code-failed'
  },
  EVENT177: { event: 'e_linkClick', linkPlacement: 'learn-more', linkID: 'back-button-click' },
  EVENT178: { event: 'e_linkClick', linkPlacement: 'learn-more', linkID: 'security-tab-link-click' },
  EVENT179: { event: 'e_linkClick', linkPlacement: 'oobe-sign-up', linkID: 'learn-more-link-click' },
  EVENT180: { event: 'e_linkClick', linkPlacement: 'sign-up', linkID: 'learn-more-link-click' },
  EVENT181: { event: 'e_linkClick', linkPlacement: 'password', linkID: 'select-here-to-continue-link-click' },
  EVENT182: {
    event: 'e_linkClick',
    linkPlacement: 'leaked-creds-change-password',
    linkID: 'learn-more-link-click'
  },
  EVENT183: { event: 'e_formError', formName: 'leaked-creds-change-password', formError: 'pass-not-match-error' },
  EVENT184: {
    event: 'e_formError',
    formName: 'leaked-creds-change-password',
    formError: 'check-pass-requirements-error'
  },
  EVENT185: {
    event: 'e_linkClick',
    linkPlacement: 'leaked-creds-change-password',
    linkID: 'request-new-code-button-click'
  },
  EVENT186: {
    event: 'e_linkClick',
    linkPlacement: 'leaked-creds-change-password',
    linkID: 'confirm-button-click'
  },
  EVENT187: {
    event: 'e_formError',
    formName: 'leaked-creds-change-password',
    formError: 'enter-verification-code-error'
  },
  EVENT188: { event: 'e_formError', formName: 'leaked-creds-change-password', formError: 're-enter-pass-error' },
  EVENT189: {
    event: 'e_formError',
    formName: 'leaked-creds-change-password',
    formError: 'incorrect-expired-code-error'
  },
  EVENT190: {
    event: 'e_formError',
    formName: 'leaked-creds-change-password',
    formError: 'pass-cannot-be-used-error'
  },
  EVENT191: { event: 'e_linkClick', linkPlacement: 'forgot-password', linkID: 'learn-more-link-click' },
  EVENT192: { event: 'e_formError', formName: 'password', formError: 'too-many-requests-error' },
  EVENT193: { event: 'e_linkClick', linkPlacement: 'forgot-password', linkID: 'cancel-button-click' },
  EVENT194: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'use-otp-button-click' },
  EVENT195: { event: 'e_linkClick', linkPlacement: 'otp-sign-in', linkID: 'request-new-message-button-click' },
  EVENT196: { event: 'e_formSubmit', formName: 'otp-sign-in-verify-code' },
  EVENT197: { event: 'e_linkClick', linkPlacement: 'otp-sign-in', linkID: 'use-otp-signin-button-click' },
  EVENT198: { event: 'e_formSubmit', formName: 'use-otp-signin-completed' },
  EVENT199: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'send-signin-code-button-click' },
  EVENT200: { event: 'e_linkClick', linkPlacement: 'otp-sign-in', linkID: 'send-signin-code-signin-button-click' },
  EVENT201: { event: 'e_formSubmit', formName: 'send-signin-code-completed' },
  EVENT202: { event: 'e_linkClick', linkPlacement: 'sign-in', linkID: 'use-password-button-click' }
}

const udlEvents = {
  getEventByID: (id) => EVENTS[id],
  getFederatedEvent: (id, linkPlacement, linkID) => ({ ...EVENTS[id], linkPlacement, linkID }),
  getEventWithObject: (id, object) => ({ ...EVENTS[id], ...object })
}

export default udlEvents
