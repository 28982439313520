import styled from '@emotion/styled'

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  height: 0;
`

export const LabelContainer = styled.h5`
  margin-top: 42px;
`
