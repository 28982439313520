import styled from '@emotion/styled'

export const ChangePasswordText = styled.p`
  margin-bottom: 20px;
`
export const UsernameLabel = styled.p`
  margin-bottom: 20px;
`
export const ConfirmPasswordInput = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`