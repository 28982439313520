import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MainLayout, DefaultHeader } from '../../component'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'
import Button from '@veneer/core/dist/esm/scripts/button'
import udlEvents from '../../common/udlEvents'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const LearnMore = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const reqsCategories = [
    {
      id: 1,
      label: t('label.learn_more_uppercase')
    },
    {
      id: 2,
      label: t('label.learn_more_lowecase')
    },
    {
      id: 3,
      label: t('label.learn_more_numbers')
    },
    {
      id: 4,
      label: t('label.learn_more_special')
    }
  ]

  const triggerUDLEvent = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT178'))
  }

  const securityTabLink = () => {
    return (
      <Trans i18nKey="label.learn_more_enable_mfa">
        We also recommend that you enable two-factor authentication (where a one-time code is sent to your email
        address or mobile number as an additional verification step during sign-in). Two-factor authentication is
        one of the best ways to ensure the safety of your online accounts. You can enable it for your HP account on
        the
        <a
          id="security-tab-link"
          aria-label="security-tab-link"
          tabIndex={0}
          href="https://account.id.hp.com/security"
          onClick={triggerUDLEvent}
          target="_blank"
          rel="noopener noreferrer"
        >
          Security tab of the HP My Account portal
        </a>
      </Trans>
    )
  }
  const { isRTL } = useDirection()

  return (
    <MainLayout>
      <DefaultHeader preventLineBreak={isRTL} isRTL={isRTL} title={t('label.learn_more_title')} />
      <Scrollbar>
        <S.TextWrapper>
          <S.NormalWrapper>{t('label.learn_more_sec_practices')}</S.NormalWrapper>
          <S.BoldWrapper>{t('label.learn_more_why_change_pass')}</S.BoldWrapper>
          <S.NormalWrapper>{t('label.learn_more_password_detected')}</S.NormalWrapper>
          <S.BoldWrapper>{t('label.learn_more_whats_wrong')}</S.BoldWrapper>
          <S.NormalWrapper>{t('label.learn_more_pass_detected')}</S.NormalWrapper>
          <S.BoldWrapper>{t('label.learn_more_how_protect')}</S.BoldWrapper>
          <S.NormalWrapper>{t('label.learn_more_strong_unique')}</S.NormalWrapper>
          <S.NormalWrapper>{securityTabLink()}</S.NormalWrapper>
          <S.BoldWrapper>{t('label.learn_more_pass_rules')}</S.BoldWrapper>
          <S.NormalWrapper>{t('label.learn_more_pass_criteria')}</S.NormalWrapper>
          <S.ReqsWrapper>{t('label.learn_more_at_least_categories')}</S.ReqsWrapper>
          {reqsCategories.map((item) => (
            <>
              <S.CategoriesWrapper key={item.id}>{item.label}</S.CategoriesWrapper>
            </>
          ))}
          <S.ReqsWrapper>{t('label.learn_more_different_pass')}</S.ReqsWrapper>
          <S.BoldWrapper>{t('label.learn_more_how_hp')}</S.BoldWrapper>
          <S.NormalWrapper>{t('label.learn_more_hp_encryption')}</S.NormalWrapper>
        </S.TextWrapper>
      </Scrollbar>
      <S.ButtonWrapper isRTL={isRTL}>
        <Button
          aria-label="back-btn"
          name="back-btn"
          id="back-btn"
          onClick={() => {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT177'))
            navigate(-1)
          }}
          expanded
        >
          {t('link.back_link')}
        </Button>
      </S.ButtonWrapper>
    </MainLayout>
  )
}

export default LearnMore
