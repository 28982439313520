import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tokens from '@veneer/tokens/dist/tokens'

const { color, typography } = tokens
const { size1, lineHeight2 } = typography
const { gray5 } = color

export const TnCs = styled.div`
  width: 100%; // IE Fix
  line-height: ${lineHeight2};
  font-size: ${size1};
  ${(props) =>
    props.theme !== 'contrast' &&
    css`
      color: ${gray5};
    `}
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
