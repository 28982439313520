import Button from '@veneer/core/dist/esm/scripts/button'
import TextArea from '@veneer/core/dist/esm/scripts/text_area'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../common/types'
import config from '../../config'
import { apiExternalUrl } from '../../services/axiosConfig'
import * as S from './style'

const SessionInfo = () => {
  const dispatch = useDispatch()
  const [response, setResponse] = useState('No info yet...')
  const state = useSelector((state: State) => state)

  useEffect(() => {
    handleFetchSessionInfo()
    // eslint-disable-next-line
  }, [])

  const getUserInfo = async () => {
    const geoLookup = await apiExternalUrl.get(config.geoURL)
    let countryOfResidence = {
      currentCountry: state.user.country,
      queryParameter: state.user.queryCountryParam,
      geolookupCountry: geoLookup.data.country
    }
    const locale = {
      currentLocale: state.user.locale,
      queryParameter: state.user.queryLocaleParam,
      browserLanguage: navigator.language
    }
    const lastArkoseSession = state.arkose.lastArkoseSession
    return { lastArkoseSession, countryOfResidence, locale }
  }

  const handleFetchSessionInfo = async () => {
    setResponse('Fetching info...')
    try {
      const sessionInfo = await dispatch.session.fetchSessionCustomInfo()
      const userInfo = await getUserInfo()

      /* Delete unnecessary data from response */
      delete sessionInfo.config
      delete sessionInfo.request
      delete sessionInfo.data.idp
      delete sessionInfo.data.captchaEngines

      setResponse(JSON.stringify({ sessionInfo, ...userInfo }, null, 2))
    } catch (error) {
      setResponse(JSON.stringify(error, null, 2))
    }
  }

  return (
    <S.SessionInfoWrapper>
      <Button type="button" id="fetch-button" onClick={handleFetchSessionInfo} expanded>
        Click to fetch info
      </Button>
      <TextArea id="text-info-id" value={response} readOnly height="900px" />
    </S.SessionInfoWrapper>
  )
}

export default SessionInfo
