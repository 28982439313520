import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Button from '@veneer/core/dist/esm/scripts/button'
import { DefaultHeader, UITextBox, MainLayout } from '../../component'
import { ARKOSE_SELECTORS, PAGE_AUTH_CODE_SEND, PAGE_AUTH_UNVERIFIED } from '../../constants'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import * as S from './styles'

const MultiFactorAuthentication = () => {
  const location: any = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state: State) => state.user)
  const allowBack = location.state ? location.state.allowBack : false
  const [isLoading, setLoading] = useState(false)
  const defaultValues = { code: '' }
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm({ defaultValues })

  const submitSelector = ARKOSE_SELECTORS.MULTIFACTOR_AUTHENTICATION.SUBMIT_AUTH_CODE

  const decideNextAction = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT170'))
    const mfaMethods = user.availableMfaMethods
    const hasVerificationMethod = mfaMethods.includes('email') || mfaMethods.includes('phone')
    if (!hasVerificationMethod) {
      return navigate(PAGE_AUTH_UNVERIFIED)
    }
    navigate(PAGE_AUTH_CODE_SEND)
  }

  const formSubmit = async ({ code }) => {
    setLoading(true)
    const options = { setLoading, setError, code }
    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.mfaVerifyCode,
      onHide: () => setLoading(false),
      formErrors: errors
    }
    await dispatch.user.mfaVerifyCode({
      options: { ...options, arkoseParams }
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT168'))
    handleSubmit(formSubmit)()
  }

  useEffect(() => {
    if (errors.code && errors.code.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT171'))
    }
  }, [dispatch.udl, errors.code])

  return (
    <MainLayout>
      <DefaultHeader title={t('label.two_factor_authentication')} />
      <p>{t('label.two_factor_desc')}</p>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <S.FieldWrapper>
          <UITextBox
            id="code"
            aria-label="code"
            label={t('label.placeholder_mfa')}
            error={!!errors.code}
            helperText={errors.code && t('form.err_verification_code')}
            autoFocus
            maxLength={6}
            control={control}
            rules={{ required: 'form.err_verification_code' }}
          />
        </S.FieldWrapper>
        <S.ButtonWrapper>
          <Button
            appearance="primary"
            aria-label={submitSelector}
            id={submitSelector}
            name={submitSelector}
            type="submit"
            loading={isLoading}
            expanded
          >
            {t('button_capitalize.verify')}
          </Button>
        </S.ButtonWrapper>
      </form>
      {allowBack && (
        <S.ButtonWrapper>
          <Button
            tabIndex={0}
            id="back-link"
            appearance="ghost"
            onClick={() => {
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT169'))
              navigate(-1)
            }}
            expanded
          >
            {t('button_capitalize.cancel')}
          </Button>
        </S.ButtonWrapper>
      )}
      <S.ButtonWrapper>
        <Button appearance="ghost" aria-label="code_mfa" id="code_mfa" onClick={decideNextAction} expanded>
          {t('link.code_mfa')}
        </Button>
      </S.ButtonWrapper>
    </MainLayout>
  )
}

export default MultiFactorAuthentication
