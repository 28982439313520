import Button from '@veneer/core/dist/esm/scripts/button'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  ARKOSE_SELECTORS,
  LINK_SELECTORS,
  PAGE_EDIT_MOBILE,
  BFF_IDENTITY_PROVIDER,
  IDENTITY_PROVIDER
} from '../../constants'
import { useNavigate } from 'react-router-dom'
import { DefaultHeader, MainLayout } from '../../component'
import { AppDispatch, State } from '../../common/types'
import { editMobileForWechat } from '../../features'
import udlEvents from '../../common/udlEvents'
import * as S from './styles'
import useDirection from '../../customHooks/useDirection'

const MobileCodeSend = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { user, codeVerification, session } = useSelector((state: State) => state)
  const retryDelay = useSelector((state: State) => state.session.retryDelay)
  const APPNAME = session.client && session.client.displayName
  const { isRTL } = useDirection()
  const formattedNumber= isRTL ? `<bold dir="ltr">${user.phoneNumber}</bold>` : `<bold>${user.phoneNumber}</bold>`

  const verifyByEmailId = ARKOSE_SELECTORS.SEND_CODE.VERIFY_BY_EMAIL
  const verifyByEmail = async () => {
    const data = {
      submitSelector: verifyByEmailId,
      type: 'email'
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT107'))
    await dispatch.codeVerification.sendCode(data)
  }

  const editPhoneSelector = LINK_SELECTORS.EDIT_PHONE
  const values = {
    type: 'phone',
    id: ARKOSE_SELECTORS.SEND_CODE.SEND_PHONE,
    title: 'label.email_send_title',
    getSecondOption: () => (
      <>
        {user.email && (
          <Button
            id={verifyByEmailId}
            name={verifyByEmailId}
            appearance="secondary"
            loading={codeVerification.isLoadingSendEmail}
            disabled={codeVerification.isLoadingSendSMS || retryDelay}
            onClick={verifyByEmail}
            expanded
          >
            {t('button.verify_by_email')}
          </Button>
        )}
        {(!user.identityProvider ||
          user.identityProvider === BFF_IDENTITY_PROVIDER.HPID ||
          (user.identityProvider &&
            user.identityProvider.toLowerCase() === IDENTITY_PROVIDER.WE_CHAT &&
            editMobileForWechat)) && (
          <S.ButtonWrapper>
            <Button
              appearance="ghost"
              id={editPhoneSelector}
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT30'))
                navigate(PAGE_EDIT_MOBILE)
              }}
              expanded
              disabled={retryDelay}
            >
              {t('label.edit_mobile_number')}
            </Button>
          </S.ButtonWrapper>
        )}
      </>
    )
  }

  const isLoading = codeVerification.isLoadingSendSMS
  const isDisabled = codeVerification.isLoadingSendEmail

  const handleClick = async () => {
    const data = {
      submitSelector: values.id,
      type: values.type
    }
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT106'))
    await dispatch.codeVerification.sendCode(data)
  }

  return (
    <MainLayout>
      <DefaultHeader title={t('label.verify_mobile_number')} />
      <S.TextWrapper>
        <p>{t('label.verify_mobile_app_name', { APPNAME: APPNAME })}</p>
        <p>
          <Trans i18nKey="label.select_send_sms_mobile_code" components={{bold: <strong />}} values={{MOBILE: formattedNumber}} />
        </p>
      </S.TextWrapper>

      <S.ButtonWrapper>
        <Button
          id={values.id}
          name={values.id}
          onClick={handleClick}
          loading={isLoading}
          disabled={isDisabled || retryDelay}
          expanded
        >
          {t('button_capitalize.send_sms')}
        </Button>
      </S.ButtonWrapper>

      <div>{values.getSecondOption()}</div>
      {codeVerification.error && (
        <InlineNotification closeButton={false} title={t(codeVerification.error)} type="negative" />
      )}

      {retryDelay && (
        <InlineNotification
          hideIcon={false}
          closeButton={false}
          title={t('label.code_in_moment_mobile')}
          type="informative"
        />
      )}
    </MainLayout>
  )
}

export default MobileCodeSend
