import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Footer from '@veneer/core/dist/esm/scripts/footer'
import tokens from '@veneer/tokens/dist/tokens'

const { color, typography } = tokens
const { size1, lineHeight2 } = typography
const { gray5, gray7 } = color

export const UIFooter = styled(Footer)`
  align-self: flex-end;
  > * {
    padding: 0;
    ${(props) =>
      props.theme === 'contrast' &&
      css`
        border-style: none;
      `}
  }
`

export const PrivacyLink = styled.a`
  line-height: ${lineHeight2};
  font-size: ${size1};
  ${(props) =>
    props.theme !== 'contrast' &&
    css`
      color: ${gray5};
      :hover {
        color: ${gray7} !important;
      }
    `}

  :focus {
    margin-bottom: 2px;
  }
`
