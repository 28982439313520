import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import Button from '@veneer/core/dist/esm/scripts/button'
import udlEvents from '../../../common/udlEvents'

import * as S from './styles'

interface AutoRedirectProps {
  isDisabled: boolean
  timer: boolean
  redirectWait: () => void
}

const AutoRedirect = ({ isDisabled, redirectWait, timer }: AutoRedirectProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (timer) {
      dispatch.session.sendUIEvents('continue-button-displayed-auto-redirect-failed')
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT27'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])

  return (
    <>
      {!timer ? (
        <S.Wrapper>
          <S.ProgressIndicator>
            <ProgressIndicator />
          </S.ProgressIndicator>
          <p>{t('label.redirect_wait')}</p>
        </S.Wrapper>
      ) : (
        <S.Wrapper>
          <p>{t('label.redirect_were_sorry')}</p>
          <Button id="redirect-link" appearance="ghost" disabled={isDisabled} onClick={redirectWait} expanded>
            {t('link.select_here')}
          </Button>
        </S.Wrapper>
      )}
    </>
  )
}

export default AutoRedirect
