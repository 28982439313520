import { useEffect, useState } from 'react'
import TextBox from '@veneer/core/dist/esm/scripts/text_box'
import { Controller } from 'react-hook-form'
import { controlledOnBlur, controlledOnChange } from '../utils'

interface UITextBoxProps {
  id: string
  ariaLabel: string
  control: any
  rules?: any
  filter?: { (): void }
  onChange?: { (): void }
  onBlur?: { (): void }
  refs?: any
  maxLength?: number
  className?: string
  error?: boolean
  clearErrors?: { (): void }
}

const UITextBox = (props: UITextBoxProps) => {
  const { id, control, rules, refs, filter, onChange, onBlur, maxLength = 256, ariaLabel } = props
  const [isChanged, setIsChanged] = useState(false)
  const handleRef = (el) => refs && (refs[id] = el)

  useEffect(() => {
    setIsChanged(false)
    props.clearErrors && props.clearErrors()
    // eslint-disable-next-line
  }, [])

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={(cProps) => (
        <TextBox
          aria-label={ariaLabel}
          {...cProps}
          {...props}
          value={cProps.field.value}
          onChange={(value) => {
            if (!isChanged) setIsChanged(true)
            controlledOnChange({ cProps, value, filter, onChange })
          }}
          onBlur={() => controlledOnBlur({ cProps, isChanged, onBlur })}
          ref={handleRef}
          maxLength={maxLength}
        />
      )}
    />
  )
}

export default UITextBox
