import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-top: 20px;
`

export const LinkWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
