import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from '../../component'
import DefaultHeader from '../../component/DefaultHeader'
import UILink from '../../component/UILink/UILink'
import { PAGE_CHANGE_PASSWORD, PAGE_USERNAME } from '../../constants'
import * as S from './style'
import udlEvents from '../../common/udlEvents'

const PasswordResetSuccess = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [skip, setSkip] = useState(false)
  const { page }: any = (location && location.state) || {}

  useEffect(() => {
    const timer = window.setTimeout(() => handleRedirect(page), 5000)
    return () => {
      timer && clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [page])

  const handleRedirect = (page) => {
    if (!skip) {
      if (page === PAGE_CHANGE_PASSWORD) {
        dispatch.user.redirectToAPP()
      } else {
        navigate(PAGE_USERNAME)
      }
      setSkip(true)
    }
  }

  const handleManualRedirect = (page) => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT127'))
    handleRedirect(page)
  }

  return (
    <MainLayout>
      <DefaultHeader />
      <S.SuccessWrapper>
        <S.ProgressWrapper>
          <ProgressIndicator />
        </S.ProgressWrapper>
        <S.TextWrapper>{`${t('label.pass_changed')} ${t('label.wait_redirect')}`}</S.TextWrapper>
        <S.SkipWrapper>
          <UILink onClick={() => handleManualRedirect(page)} ariaLabel="redirectButton" id="redirectButton">
            {t('link.select_redirect_retry')}
          </UILink>
        </S.SkipWrapper>
      </S.SuccessWrapper>
    </MainLayout>
  )
}

export default PasswordResetSuccess
