import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { PARAM_LOGOUT_REDIRECT_URI } from '../../constants'
import Button from '@veneer/core/dist/esm/scripts/button'
import { MainLayout } from '../../component'
import useQueryObject from '../../customHooks/queryObject'
import DefaultHeader from '../../component/DefaultHeader'
import * as S from './styles'

const SignOut = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const payload = useQueryObject()
  const postRedirectUrl = payload && payload[PARAM_LOGOUT_REDIRECT_URI]
  const [skip, setSkip] = useState(false)

  const handleClick = useCallback(
    (redirectUrl) => {
      if (!skip) {
        dispatch.user.signOut(redirectUrl)
        setSkip(true)
      }
    },
    [dispatch.user, skip]
  )

  useEffect(() => {
    if (postRedirectUrl) {
      const timer = setTimeout(() => handleClick(postRedirectUrl), 5000)
      return () => {
        // clear timer after unmount
        timer && clearTimeout(timer)
      }
    } else {
      dispatch.error.goError({ message: t('errors.err_logout_redirect') })
    }
  }, [postRedirectUrl, dispatch.error, handleClick, t])

  return (
    <MainLayout>
      <DefaultHeader />
      <S.PageContainter>
        <S.LoadingContainer>
          <ProgressIndicator />
        </S.LoadingContainer>
        <S.TextContainer>
          <p>{t('label.logout_msg')}</p>
          <p>{t('label.recovery_password_success_two')}</p>
        </S.TextContainer>
        <S.ButtonContainer>
          <Button
            aria-label="btn-skip"
            name="btn-skip"
            id="btn-skip"
            appearance="ghost"
            onClick={() => handleClick(postRedirectUrl)}
            expanded
          >
            {t('label.recovery_skip_wait')}
          </Button>
        </S.ButtonContainer>
      </S.PageContainter>
    </MainLayout>
  )
}

export default SignOut
