import styled from '@emotion/styled'

type WrapperProps = {
  marginTop?: boolean
  reduceLabel?: string
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
`

export const FormWrapper = styled.div`
  margin-top: ${(props: WrapperProps) => (props.marginTop ? '20px' : '')};
  margin-bottom: 20px;

  label {
    font-size: ${(props: WrapperProps) => (props.reduceLabel ? props.reduceLabel : '')};
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 40px;
`

export const CheckBoxConsentWorkaround = styled.div`
  label > span.label.vn-checkbox__span--text {
    word-break: normal !important;
  }
`
