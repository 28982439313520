import {
  ARKOSE_KEYS,
  BFF_STATUS,
  PAGE_EMAIL_VERIFICATION,
  PAGE_PHONE_VERIFICATION,
  EMAIL_VERIFICATION,
  BFF_ENDPOINTS
} from '../constants'
import { history } from '../store'
import bff from '../services'
import udlEvents from '../common/udlEvents'

const codeVerification = {
  state: {
    error: '',
    isLoadingSendSMS: false,
    isLoadingSendEmail: false,
    isSendEmailSuccess: false
  },
  reducers: {
    update(state, data) {
      return { ...state, ...data }
    }
  },
  effects: (dispatch) => ({
    async requestCode(data) {
      const { type, captcha } = data
      dispatch.codeVerification.update({ error: '', isLoadingSendEmail: true })
      const body = {}
      if (captcha) {
        body.captcha = captcha
      }
      const res = await bff.postEmailOrPhoneSendCode(body, type)
      dispatch.codeVerification.update({ error: '', isLoadingSendEmail: false })
      return res.data
    },

    async sendCodeCallback(params) {
      const { token, options } = params
      const type = options && options.type
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const body = { captcha, type }
      const arkoseParams = options && options.arkoseParams
      const endpoint =
        type === 'email' ? BFF_ENDPOINTS.SESSION_EMAIL_SEND_CODE : BFF_ENDPOINTS.SESSION_PHONE_SEND_CODE
      const updateArkoseParams = { ...arkoseParams, prepareArkoseBody: { endpoint } }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      try {
        await dispatch.codeVerification.requestCode(body)
        dispatch.session.update({ retryDelay: true })

        if (type === 'email') {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT11'))
          dispatch.codeVerification.update({ isLoadingSendEmail: false })
          history.push(PAGE_EMAIL_VERIFICATION, { context: EMAIL_VERIFICATION })
        } else {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT12'))
          dispatch.codeVerification.update({ isLoadingSendSMS: false })
          history.push(PAGE_PHONE_VERIFICATION)
        }
      } catch (errorData) {
        // Normal error handling
        if (errorData.error === BFF_STATUS.CAPTCHA_REQUIRED) {
          await dispatch.arkose.handleArkose(updateArkoseParams)
        } else if (errorData.response && errorData.response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          type === 'email'
            ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT156'))
            : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT108'))

          dispatch.codeVerification.update({ isLoadingSendSMS: false, isLoadingSendEmail: false })
          dispatch.session.update({ retryDelay: true })
        } else {
          dispatch.codeVerification.update({
            error: 'form.err_send_email_code',
            isLoadingSendSMS: false,
            isLoadingSendEmail: false
          })
        }
      }
    },

    async sendCode(data) {
      const { submitSelector, type } = data
      if (type === 'email') {
        dispatch.codeVerification.update({ error: '', isLoadingSendEmail: true })
      } else {
        dispatch.codeVerification.update({ error: '', isLoadingSendSMS: true })
      }

      const options = { type }
      const arkoseParams = {
        submitSelector,
        callback: dispatch.codeVerification.sendCodeCallback,
        options,
        onHide: () => dispatch.codeVerification.update({ isLoadingSendEmail: false, isLoadingSendSMS: false })
      }
      await dispatch.codeVerification.sendCodeCallback({ options: { type, arkoseParams } })
    },

    async resendEmailCode(data) {
      const { options, token } = data
      const { setResendEmail, setResendLoading, setError, arkoseParams } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const updateArkoseParams = {
        ...arkoseParams,
        prepareArkoseBody: { endpoint: BFF_ENDPOINTS.SESSION_EMAIL_SEND_CODE }
      }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const params = { type: 'email', captcha }

      try {
        const result = await dispatch.codeVerification.requestCode(params)
        dispatch.session.update({ retryDelay: true })
        setResendEmail(result.email)
        setResendLoading(false)
      } catch (errorData) {
        // Workaround to handle the error in cases when the user tries to reach the endpoint and needs to wait for some seconds before trying again
        const { response } = errorData
        if (response && response.data && response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT162'))
          return dispatch.session.update({ retryDelay: true })
        }
        // Normal error handling
        if (errorData.error === BFF_STATUS.CAPTCHA_REQUIRED) {
          dispatch.arkose.handleArkose(updateArkoseParams)
        } else {
          setError('code', { type: 'invalidCode', message: 'form.err_send_email_code' })
        }
      }
    },

    async resendSMSCode(data) {
      const { options, token } = data
      const { setResendCode, setError, arkoseParams } = options
      const captcha = token && { data: token, type: ARKOSE_KEYS.DEFAULT }
      const updateArkoseParams = {
        ...arkoseParams,
        prepareArkoseBody: { endpoint: BFF_ENDPOINTS.SESSION_PHONE_SEND_CODE }
      }

      if (!captcha) return dispatch.arkose.handleArkose(updateArkoseParams)

      const params = { type: 'phone', captcha }

      try {
        dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT29'))
        const result = await dispatch.codeVerification.requestCode(params)
        dispatch.session.update({ retryDelay: true })
        setResendCode(result.phoneNumber)
      } catch (errorData) {
        // Workaround to handle the error in cases when the user tries to reach the endpoint and needs to wait for some seconds before trying again
        const { response } = errorData
        if (response && response.data && response.data.error === BFF_STATUS.TOO_MANY_REQUESTS) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT157'))
          return dispatch.session.update({ retryDelay: true })
        }
        // Normal error handling
        if (errorData.error === BFF_STATUS.CAPTCHA_REQUIRED) {
          dispatch.arkose.handleArkose(updateArkoseParams)
        } else {
          setError('code', { type: 'invalidCode', message: 'form.err_send_email_code' })
        }
      }
    }
  })
}

export default codeVerification
