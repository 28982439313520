import { UDL_PAGE_NAME } from '../constants'

const udl = {
  state: {
    userTypeSession: 'guest',
    appInstanceID: '',
    clientID: ''
  },
  reducers: {
    update(state, data) {
      return { ...state, ...data }
    }
  },
  effects: (_dispatch) => ({
    trackPageView({ pageName, pathname, clientID }, rootState) {
      if (clientID) rootState.udl.clientID = clientID

      const event = Object.assign({
        event: 'e_pageView',
        pageNameL5: pageName || UDL_PAGE_NAME[pathname] || UDL_PAGE_NAME.UNDEFINED
      }, rootState.udl)
    
      try {
        window.dataLayer.push(event)
      } catch (error) {
        console.warn('page view event not tracked!', error)
      }
    },
    trackEvent(event, rootState) {
      const fullEvent = Object.assign(event, rootState.udl)
      if (fullEvent.appInstanceID === '') delete fullEvent.appInstanceID
      try {
        window.dataLayer.push(fullEvent)
      } catch (error) {
        console.warn('event not tracked!', error)
      }
    }
  })
}

export default udl
