import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { State } from '../../../common/types'
import Button from '@veneer/core/dist/esm/scripts/button'
import { DefaultHeader, MainLayout, UISpinner, UILink } from '../../../component'
import { PAGE_TERMS_AND_CONDITIONS } from '../../../constants'
import udlEvents from '../../../common/udlEvents'

import * as S from './styles'

const TnCManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { locale, country } = useSelector((state: State) => state.user)
  const { displayName } = useSelector((state: State) => state.session.client)
  const { tncs } = useSelector((state: State) => state.session)
  const { loading } = useSelector((state: State) => state)

  const buttonStatus = {
    accept: false,
    decline: false
  }

  const [isLoading, setLoading] = useState(buttonStatus)
  const [isDisabled, setIsDisabled] = useState(buttonStatus)

  const acceptOrDeclineTerms = async (decision: string) => {
    if (decision === 'accepted') {
      setLoading({ ...isLoading, accept: true })
      setIsDisabled({ ...isDisabled, decline: true })
    } else {
      setLoading({ ...isLoading, decline: true })
      setIsDisabled({ ...isDisabled, accept: true })
    }
    const data = { termsAndConditions: decision, options: { setLoading } }
    dispatch.session.acceptOrDeclineTermsAndConditions(data)
  }

  useEffect(() => {
    dispatch.session.fetchTermsAndConditions({ locale, country })
  }, [locale, country, dispatch.session])

  const TnCName = () =>
    tncs.map(({ displayTitle, tncId }, index) => (
      <S.TnCTitle key={`tnc-title-${index}`}>{displayTitle || tncId}</S.TnCTitle>
    ))

  return (
    <MainLayout>
      <DefaultHeader title={t('label.terms_service')} />
      {loading.effects.session.fetchTermsAndConditions ? (
        <UISpinner />
      ) : (
        <S.BodyWrapper>
          <S.TextLabel>{t('label.access_requires', { APPNAME: displayName })}</S.TextLabel>
          <S.TnCWrapper>{TnCName()}</S.TnCWrapper>
          <UILink
            id="view-full-tncs"
            onClick={() => {
              dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT174'))
              navigate(PAGE_TERMS_AND_CONDITIONS)
            }}
          >
            {t('label.view_full_terms')}
          </UILink>
          <S.ButtonWrapper>
            <Button
              aria-label="btn-accept-tnc-management"
              name="btn-accept-tnc-management"
              id="btn-accept-tnc-management"
              loading={isLoading.accept}
              disabled={isDisabled.accept}
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT172'))
                acceptOrDeclineTerms('accepted')
              }}
              expanded
            >
              {t('button_capitalize.accept')}
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              aria-label="btn-decline-tnc-management"
              name="btn-decline-tnc-management"
              id="btn-decline-tnc-management"
              appearance="ghost"
              loading={isLoading.decline}
              disabled={isDisabled.decline}
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT173'))
                acceptOrDeclineTerms('rejected')
              }}
              expanded
            >
              {t('button_capitalize.decline')}
            </Button>
          </S.ButtonWrapper>
        </S.BodyWrapper>
      )}
    </MainLayout>
  )
}

export default TnCManagement
