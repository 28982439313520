import styled from '@emotion/styled'

export const ContainerRecoveryAction = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`

export const RecoveryText = styled.p`
  margin-bottom: 20px;
`

export const ContainerActionLink = styled.div`
  margin-top: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
`