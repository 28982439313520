import { useSelector } from 'react-redux'
import { Control, Controller } from 'react-hook-form'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { isValidMobileNumber } from '../../../util'
import UIPhoneNumber from '../../UIPhoneNumber'
import { State } from '../../../common/types'
import useDirection from '../../../customHooks/useDirection'

interface UIMobileNumberProps {
  id: string
  ariaLabel: string
  control: Control
  rules: Object
  overwriteValue: string
}

const UIMobileNumber = (props: UIMobileNumberProps) => {
  const { id, control, rules, overwriteValue, ariaLabel } = props
  const { username, country, locale, phoneNumber } = useSelector((state: State) => state.user)
  const smsSupportedCountryNumbers = useSelector((state: State) => state.session.smsSupportedCountryNumbers)
  const { isRTL } = useDirection()

  let defaultNumber
  if (isValidMobileNumber(overwriteValue)) {
    defaultNumber = parsePhoneNumberFromString(overwriteValue)
  } else {
    const isUsernameValid = isValidMobileNumber(username)
    defaultNumber = parsePhoneNumberFromString((isUsernameValid ? username : phoneNumber) || '')
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={(cProps) => (
        <UIPhoneNumber
          {...cProps}
          {...props}
          value={cProps.field.value}
          aria-label={ariaLabel}
          onChange={cProps.field.onChange}
          onBlur={cProps.field.onBlur}
          countryNumbers={smsSupportedCountryNumbers}
          defaultIsoCode={defaultNumber?.country || country}
          defaultValue={defaultNumber?.nationalNumber || ''}
          locale={locale}
          isRTL={isRTL}
        />
      )}
    />
  )
}

export default UIMobileNumber
