import { useTranslation } from 'react-i18next'
import IconWarning from '@veneer/core/dist/esm/scripts/icons/icon_warning'
import { MainLayout } from '../../component'
import { useLocation } from 'react-router-dom'
import { BFF_STATUS } from '../../constants'
import DefaultHeader from '../../component/DefaultHeader'
import * as S from './styles'

const VerificationError = () => {
  const { t } = useTranslation()
  const location: any = useLocation()

  const getIconColor = location?.state?.context === BFF_STATUS.INVALID_CODE ? 'colorOrange6' : 'colorRed6'

  return (
    <MainLayout>
      <DefaultHeader />
      <S.BodyContainer>
        <S.WarningIconContainer>
          <IconWarning aria-label={getIconColor} color={getIconColor} size={50} />
        </S.WarningIconContainer>
        <S.TextContainer>
          <S.VerificationErrorText>{t('form.err_invalid_expired_link')}</S.VerificationErrorText>
          <S.VerificationErrorText>{t('label.close_wind')}</S.VerificationErrorText>
        </S.TextContainer>
      </S.BodyContainer>
    </MainLayout>
  )
}

export default VerificationError
