import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface UIUsernameProps {
  reduceLabel?: boolean
  marginBottom?: number
}

const defaultMarginBottom = 35

export const SwitchWrapper = styled.div<{
  preventLineBreak?: boolean
}>`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.preventLineBreak &&
    css`
      a {
        font-size: 12px;
      }
    `}
`

export const UsernameWrapper = styled.div<UIUsernameProps>`
  margin-bottom: ${(props) => props.marginBottom || defaultMarginBottom}px;

  label {
    font-size: ${(props) => props.reduceLabel && '12.7px'};
  }
`
