import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Outlet } from 'react-router-dom'
import { history } from '../../store'
import { UISpinner, SessionController, DelayController } from '../../component'
import { parseParam, setCaptchaBypassKey } from '../../util'
import { handleTarget } from './utils'
import {
  PARAM_LOGIN_HINT,
  PARAM_EMAIL,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  PARAM_ALLOW_RETURN,
  PARAM_TARGET,
  PARAM_COUNTRY,
  PARAM_HIDE_CREATE,
  PARAM_FLOW_TOKEN,
  PARAM_REMEMBER_ME,
  PARAM_CSRF_TOKEN,
  PARAM_CAPTCHA_BYPASS_KEY,
  PARAM_AUTOREDIRECT,
  PAGE_COOKIE_ENABLEMENT,
  PARAM_USERNAME_TYPE,
  USER_INTERACTION_TIMEOUT,
  TRACKED_ROUTES,
  PARAM_OTP,
  PARAM_LOCALE,
  PARAM_FLOW_ID
} from '../../constants'

function useQuery() {
  const a = useLocation().search
  return new URLSearchParams(a)
}

function getFieldParams(query) {
  return [
    PARAM_LOGIN_HINT,
    PARAM_EMAIL,
    PARAM_FIRSTNAME,
    PARAM_LASTNAME,
    PARAM_ALLOW_RETURN,
    PARAM_TARGET,
    PARAM_COUNTRY,
    PARAM_HIDE_CREATE,
    PARAM_FLOW_TOKEN,
    PARAM_CSRF_TOKEN,
    PARAM_REMEMBER_ME,
    PARAM_AUTOREDIRECT,
    PARAM_USERNAME_TYPE,
    PARAM_LOCALE
  ].reduce((params, key) => {
    // Don't remove the checker here anymore.
    if (query.get(key) !== null) {
      params[key] = parseParam(key, query.get(key))
    }
    return params
  }, {})
}

const Main = ({ children }) => {
  const query = useQuery()
  const location = useLocation()
  let timer
  const runExpiredInteractionTimer = () => {
    if (TRACKED_ROUTES.includes(location.pathname)) {
      dispatch.user.update({ expiredInteraction: false })
      timer = window.setTimeout(() => {
        dispatch.user.update({ expiredInteraction: true })
      }, USER_INTERACTION_TIMEOUT)
    }
  }

  const handleListenerEvent = () => {
    clearTimeout(timer)
    runExpiredInteractionTimer()
  }

  useEffect(() => {
    window.addEventListener('click', handleListenerEvent)
    return () => {
      window.removeEventListener('click', handleListenerEvent)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const flowToken = useSelector((state) => state.user[PARAM_FLOW_TOKEN])
  useEffect(() => {
    async function init() {
      const hasFlowParam = query.get(PARAM_FLOW_TOKEN) || query.get(PARAM_FLOW_ID)
      const isNewSession = hasFlowParam && hasFlowParam !== flowToken
      const arkoseBypassKey =
        isNewSession && query.has(PARAM_CAPTCHA_BYPASS_KEY) && query.get(PARAM_CAPTCHA_BYPASS_KEY)
      if (arkoseBypassKey) setCaptchaBypassKey(arkoseBypassKey)

      dispatch.user.updateParams(getFieldParams(query))
      try {
        let data
        const initSessionParams = {}

        if (query.has(PARAM_FLOW_ID)) initSessionParams.flowId = query.get(PARAM_FLOW_ID)
        if (query.has(PARAM_FLOW_TOKEN)) initSessionParams.flow = query.get(PARAM_FLOW_TOKEN)
        if (query.has(PARAM_OTP)) initSessionParams.otp = query.get(PARAM_OTP)

        isNewSession
          ? (data = await dispatch.session.initSessionWithFlow(initSessionParams))
          : (data = await dispatch.session.fetchSessionInfo())
        await dispatch.user.setCountry()
        await handleTarget({ data, query, dispatch })
      } catch (errorData) {
        // Workaround to fix cases when cookies are disabled and localStorage/sessionStorage is unavailable
        // The message content isn't verified since we have different messages for every browser
        if (errorData.message) {
          history.push(PAGE_COOKIE_ENABLEMENT)
        } else {
          dispatch.error.goError(errorData)
        }
      } finally {
        setLoading(false)
      }
    }
    init()
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <UISpinner />

  return (
    <>
      <SessionController />
      <DelayController />
      <Outlet />
    </>
  )
}

export default Main
