import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import tokens from '@veneer/tokens/dist/tokens';
import useWindowSize from '../../../customHooks/useWindowSize'
import { UIFooter } from '../../'

import * as S from './styles'

const { layout } = tokens
const { smMin } = layout

interface MainLayoutProps {
  children: React.ReactNode
  isFooterOn?: boolean
  disableScrollbar?: boolean
  adjustHeight?: string
}

function MainLayout(props: MainLayoutProps) {
  const { children, isFooterOn = true, disableScrollbar, adjustHeight } = props
  const scrollRef = useRef(null)
  const [hasScrollbar, setHasScrollbar] = useState(false)
  const [showScrollbar, setShowScrollbar] = useState(false)
  const [appearance, setAppearance] = useState(undefined)
  const [width] = useWindowSize()
  
  useEffect(() => {
    const el = scrollRef.current
    setHasScrollbar(el?.scrollHeight > el?.clientHeight)
  }, [scrollRef])
  
  useLayoutEffect(() => {
    setShowScrollbar(!disableScrollbar && hasScrollbar)
  }, [hasScrollbar, disableScrollbar])

  useLayoutEffect(() => {
    const minSize = parseInt(smMin.substring(0,3))
    if (width >= minSize) {
    setAppearance('dropShadow')
    } else {
    setAppearance(undefined)
    }
  }, [width])

  return (
    <S.MainLayout
      adjustHeight={adjustHeight}
      className="layout"
      appearance={appearance}
      hasScrollbar={showScrollbar}
      content={
        <>
          <S.Content ref={scrollRef} hasScrollbar={showScrollbar}>
            <S.PageContent>{children}</S.PageContent>
          </S.Content>
          {isFooterOn && <UIFooter />}
        </>
      }
    ></S.MainLayout>
  )
}

export default MainLayout
