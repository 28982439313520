import styled from '@emotion/styled'

export const FieldWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  padding-top: 20px;
`
