import styled from '@emotion/styled'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 41px;
`

export const BackButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  align-self: center;
`

export const ButtonFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const ButtonItem = styled.div`
  margin-right: 10px;
`

export const TNCTitleWrapper = styled.div`
  text-align: left;
  margin-bottom: 20px;
`

export const TNCTitleItem = styled.div`
  margin-bottom: 5px;
`

export const LinkWrapper = styled.div`
  margin-top: 20px;
`
