import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'

import * as S from './styles'

interface ManualRedirectProps {
  isDisabled: boolean
  autoredirect: string
  expiredInteraction: boolean
  redirectManually: () => void
}

const ManualRedirect = ({ isDisabled, redirectManually, autoredirect }: ManualRedirectProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.session.sendUIEvents('continue-button-displayed-manual-redirect')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoredirect])

  return (
    <S.Wrapper>
      <p>{t('label.redirect_manual_button')}</p>
      <Button
        id="manual-redirect-button"
        appearance="ghost"
        disabled={isDisabled}
        onClick={redirectManually}
        expanded
      >
        {t('link.select_here')}
      </Button>
    </S.Wrapper>
  )
}

export default ManualRedirect
